import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Grid, Box, Card, LinearProgress, Menu, FormControl, TextField, MenuItem, Button, Autocomplete } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

// Data
import API from "apiConfig";

function ViewTournament() {
  let currentUrl = window.location.href.split("?");
  let origin = currentUrl[currentUrl.length - 1];
  const resultPerPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [prevCounter, setPrevCounter] = useState(0);
  const [newCounter, setNewCounter] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [tournamentName, setTournamentName] = useState("");
  const [isEditStarts, setIsEditStarts] = useState(false);
  const [deletedOrigins, setDeletedOrigins] = useState([]);
  const [orders, setOrders] = useState({
    stadium: "", city: "", category: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    stadium: null, city: null, category: null
  });

  const [forms, setForms] = useState([{ id: 0, origin: "", matchDate: "", team: "", venue: "" }]);
  const [formsError, setFormsError] = useState([{ id: 0, matchDate: null, team: null, venue: null }]);


  useEffect(() => {
    getTournamentDetails();
  }, []);
  //console.log("ordersDetails",ordersDetails)
  //function to get brand details
  const getTournamentDetails = async () => {
    setIsLoading(true);
    let eachOrigin = origin ? origin : "";

    const response = await API.get(`inventory/get_match_schedules_tournament?origin=${eachOrigin}`)
     console.log("Response",response?.data?.data?.data)
    if (response?.data?.status && response?.data?.data?.data) {
      setTournamentName(response.data.data.data[0].name);
      const newArray = response.data.data.data?.map((item,index)=> {return(
        {
          id: index,
          origin: item.origin,
          matchDate: item.match_date,
          team: item?.teams,
          venue: item?.venue
        }
      )})
      setForms(newArray);
      setPrevCounter(response.data.data.data?.length);
      setIsLoading(false);
      setErrorMsg("")
    }
    // else if (response?.data?.message === 'No orders found') {
    //   setIsLoading(false);
    //   setErrorMsg(response.data.message);
    // }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  const checkValidation = () => {

  //   let stadiumErr = orders.stadium === "" ? "Please select stadium" : null;
  //   let cityErr = orders.city === "" ? "Please select city" : null;
  //   let categoryErr = orders.category === "" ? "Please select category" : null;
  //  // let emailErr = emailValidator(email);
  //   if (stadiumErr || cityErr || categoryErr) {
  //       setErrorDetails({...errorDetails,
  //                         stadium: stadiumErr,
  //                         city: cityErr,
  //                         category: categoryErr});
  //       return;
  //   }
  //   else {
  //     if(isUpdate){
  //       onUpdateTicketCategory();
  //     }
  //     else{
  //       onCreateTicketCategory();
  //     } 
  //   }
  let prevData = forms.slice(0,prevCounter).map(x=> {return(
   {[x.origin]: {
      match_date: x.matchDate,
      teams: x.team,
      venue: x.venue
    }}
  )})
  let newData = forms.slice(prevCounter, forms.length).map(x=> {return(
    {
      match_date: x.matchDate,
      teams: x.team,
      venue: x.venue
    }
  )})
  onUpdateTournament(prevData, newData);
  }

  const onUpdateTournament = (prevData, newData) => {
    let dataToSend = {
      "data": prevData,
      "new_data": newData,
      "deleted_data": deletedOrigins
    }
    console.log("dataToSend",dataToSend);
    // console.log("selectedOrigin",selectedOrigin)
    setIsLoading(true);
    API.post(`inventory/update_tournament_match_schedules/${origin}`, dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                window.location.reload();
                setIsLoading(false);
                setIsUpdate(false);
                setSelectedOrigin(null);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  const onFormAddition = () => {
    setForms([...forms, { id: forms.length, origin: "", matchDate: "", team: "", venue: "" }]);
    setFormsError([...formsError, { id: forms.length, matchDate: null, team: null, venue: null }])
  }
  const onFormDelete = (index) => {
    //  let form = forms;
    let newForm = forms.filter(item => item.id !== index);
    let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
    // console.log(oneMore,"oneMore")
    setForms(oneMore);

    let newFormError = formsError.filter(item => item.id !== index);
    let oneMoreError = newFormError.map((x, index) => { return ({ ...x, id: index }) })
    // console.log(oneMore,"oneMore")
    setFormsError(oneMoreError);
    if(forms[index]?.origin){
      let deletedArr = deletedOrigins;
      deletedArr.push(forms[index].origin);
      setDeletedOrigins(deletedArr);
    }
  }

  const handleMatchDate = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], matchDate: null }
    setFormsError(newArrayError);
    newArray[index] = { ...newArray[index], matchDate: e.target.value }
    setForms(newArray);
    setIsEditStarts(true);
  }

  const handleTeams = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArray[index] = { ...newArray[index], team: e.target.value }
    setForms(newArray);
    newArrayError[index] = { ...newArrayError[index], team: null }
    setFormsError(newArrayError);
    setIsEditStarts(true);
  }

  const handleVenue = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArray[index] = { ...newArray[index], venue: e.target.value }
    setForms(newArray);
    newArrayError[index] = { ...newArrayError[index], venue: null }
    setFormsError(newArrayError);
    setIsEditStarts(true);
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} py={3} sx={{ width: '100%' }}>
          <Box>
            
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="my-3"><h5>Tournament</h5></Grid>
                <Grid item xs={12} md={1} className="mx-1" >
                  <label className="text-xs">Tournament Name </label>
                </Grid>
                <Grid item xs={12} md={4} className="mx-1">
                  {/* <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={matchList}
                   // value={orders.matches}
                    getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control"
                        placeholder="Tournaments"
                      />
                    )}
                    onChange={(event, newValue) => {
                       setOrders({...orders, matches: newValue.origin});
                     // getTicketCategoryList1(newValue.venue, index, newValue);
                    }}
                  /> */}
                   <TextField
                    className="inputFieldLocation"
                    id="outlined-basic"
                    placeholder="Tournaments"
                    variant="outlined"
                    name="tournamentName"
                    value={tournamentName}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container className="mt-2">
                {/* <Grid item xs={12} md={1} className={index === 0 ? "px-1" : "px-1 mt-3"}> */}
                <Grid item xs={12} md={1} className="mx-1">
                   
                </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                  <label className="text-xs">Match Date </label>
                </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                  <label className="text-xs">Teams</label>
                  </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                  <label className="text-xs">Venue</label>
                  </Grid>
                <Grid item xs={12} md={3} className='filter-search'>
                 </Grid>
              </Grid>
              {forms.map((item, index) =>
               <Grid container className="mb-3">
                {/* <Grid item xs={12} md={1} className={index === 0 ? "px-1" : "px-1 mt-3"}> */}
                <Grid item xs={12} md={1} className="mx-1">
                    <label>Match {index + 1}</label>
                    <span className="text-danger" style={{ fontSize: 15 }}> *</span>
                </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                 <TextField
                    className="form-control"
                    id="outlined-basic"
                    placeholder="Select Match Date"
                    variant="outlined"
                    name="match_date"
                    type={'date'}
                    value={item.matchDate}
                    onChange={(e) => handleMatchDate(e, item.id)}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                 <TextField
                    className="form-control"
                    id="outlined-basic"
                    placeholder="Teams"
                    variant="outlined"
                    name="team"
                    value={item.team}
                    onChange={(e) => handleTeams(e, item.id)}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="mx-1">
                 <TextField
                    className="form-control"
                    id="outlined-basic"
                    placeholder="Venue"
                    variant="outlined"
                    name="venue"
                    value={item.venue}
                    onChange={(e) => handleVenue(e, item.id)}
                  />
                </Grid>
                {/* {(item.id !== 0 && origin === undefined) && ( */}
                    <Grid item xs={12} md={1} className="px-1">
                      <Button size="small" className='btn-danger' variant="contained" color="error" onClick={() => onFormDelete(index)}>Delete</Button>
                    </Grid>
                  {/* )} */}
                  {/* {(item.id === 0 && origin === undefined) && ( */}
                    <Grid item xs={12} md={1} className="px-1">
                      <Button size="small" variant="contained" color="primary"  onClick={() => onFormAddition()}>Add</Button>
                    </Grid>
                  {/* )} */}
              </Grid>

              )}
              <Grid container >
                <Grid item xs={12} md={12} className="px-1 mb-3 mt-3">
                  <Button variant="contained" color="secondary" onClick={() => checkValidation()}>{origin ? "Update" : "Submit"}</Button>
                </Grid>
              </Grid>
          </Box>
          
        </Box>
      </Card>

      

      <Footer />
    </DashboardLayout>
  );
}

export default ViewTournament;
