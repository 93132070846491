import React, { useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom";
import { Grid, Box, Card, LinearProgress, Menu, FormControl, TextField, MenuItem, Button, Autocomplete, CircularProgress } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

// Data
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import Tooltip from '@mui/material/Tooltip';
import exportFromJSON from "export-from-json";

function Orders() {
  const resultPerPage = 20;
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBox, setSearchBox] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [selectedRef, setSelectedRef] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [tournament, setTournament] = useState("");
  const [tournamentList, setTournamentList] = useState([]);
  const [orders, setOrders] = useState({
    orderRef: "", tournament: 1, matches: "", client: null, clientOrigin: "", salesPoc: "", ticketCategory: "", requirement: "", status: "", selectedMatch: null, invoiceNumber: "", proformaInvoiceNumber: ""
  });
  const [initialOrders, setInitialOrders] = useState({
    orderRef: "", tournament: 1, matches: "", client: null, clientOrigin: "", salesPoc: "", ticketCategory: "", requirement: "", status: "", selectedMatch: null, invoiceNumber: "", proformaInvoiceNumber: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    orderRef: null, tournament: null, matches: null, client: null, salesPoc: null, ticketCategory: null, requirement: null, status: null, selectedMatch: null, invoiceNumber: "", proformaInvoiceNumber: ""
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, order_ref, invoice_no) => {
    setAnchorEl(event.currentTarget);
    setSelectedRef(order_ref);
    setInvoiceNumber(invoice_no);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setSearchBox(!searchBox)
  }

  const handleInputChange = (e) => {
    setOrders({ ...orders, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  useEffect(() => {
    getTournamentDetails();
    getOrdersDetails();
    getMatchList();
    getTicketCategoryList();
    getCustomerList();
  }, []);
  //console.log("ordersDetails",ordersDetails)

  const getTournamentDetails = async () => {
    setIsLoading(true);

    const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
    if (response?.data?.status && response?.data?.data?.data) {
      setTournamentList(response.data.data.data);
      setIsLoading(false);
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      alert("Something went wrong! Please try again");
    }
  }

  const getCustomerList = async (venue) => {
    setIsLoading(true);
    API.get(`inventory/get_vendors/customer`)
        .then(response => {
            // const response = await API.get(`inventory/get_ticket_category_list/${origin}`);
            if (response.data.status) {
                // let newData = response.data.data.map(x => { return ({ ...x, label: `${x.first_name} ${x.last_name === null ? "" : x.last_name}` }) })
                // let newCompanyData = response.data.data.map(x => { return ({ ...x, label: x.company_name }) })
                // let newEmailData = response.data.data.map(x => { return ({ ...x, label: x.email }) })
                 setCustomerList(response.data.data);
                // setCompanyList(newCompanyData);
                // setEmailList(newEmailData);
                // setInitialCustomerList(newData);
                // setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
}

  const getOrdersDetails = async (pageNo) => {
    setIsLoading(true);
    let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
    let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
    let orderRef = orders.orderRef;
   // let tournament = orders.tournament;
    let matches = orders.matches;
    let client = orders.clientOrigin;
    let salesPoc = orders.salesPoc;
    let ticketCategory = orders.ticketCategory;
    let status = orders.status;
    let requirement = orders.requirement;
    let invoiceNumber = orders.invoiceNumber;
    let proformaInvoiceNumber = orders.proformaInvoiceNumber;

    const response = await API.get(`orders/get_orders_list/${pageCount}?order_ref_no=${orderRef}&tournament=${tournament}&matches=${matches}&client=${client}&sales_poc=${salesPoc}&ticket_category=${ticketCategory}&type_of_requirement=${requirement}&invoice_no=${invoiceNumber}&profoma_invoice_no=${proformaInvoiceNumber}&origin=`)
    // console.log("response?.data?.message",response?.data?.message)
    if (response?.data?.status && response?.data?.data?.data) {
      let total_records = response.data.data.total_records;
      setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
      setCurrentPage(activePageIndex);
      setOrdersDetails(response.data.data.data);
      if (isInitial) {
        setInitialData(response.data.data.data);
        setIsInitial(false);
      }
      setIsLoading(false);
      setErrorMsg("")
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
      setOrdersDetails([]);
      setErrorMsg(response.data.message);
    }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

  const getMatchList = async () => {
    setIsLoading(true);
    const response = await API.get('inventory/get_match_list');
    if (response.data.status) {
      // let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
      setMatchList(response.data.data);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  const getTicketCategoryList = async () => {
    setIsLoading(true);
    const response = await API.get('inventory/get_ticket_category_list');
    if (response.data.status) {
      setTicketCategoryList(response.data.data);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  const exportDetails = async () => {
    setIsExportLoading(true);
    const reportData = []
    let newOrderDetails;
    let orderRef = orders.orderRef;
    let matches = orders.matches;
    let client = orders.clientOrigin;
    let salesPoc = orders.salesPoc;
    let ticketCategory = orders.ticketCategory;
    let requirement = orders.requirement;
    let invoiceNumber = orders.invoiceNumber;
    let proformaInvoiceNumber = orders.proformaInvoiceNumber;

    const response = await API.get(`orders/get_orders_list/0?order_ref_no=${orderRef}&tournament=${tournament}&matches=${matches}&client=${client}&sales_poc=${salesPoc}&ticket_category=${ticketCategory}&type_of_requirement=${requirement}&invoice_no=${invoiceNumber}&profoma_invoice_no=${proformaInvoiceNumber}&origin=&export=1`)
    if (response.data.status && response.data.data) {
      newOrderDetails = response.data.data.data;
      setIsExportLoading(false);
      setErrorMsg("")
    }
    else {
      setIsExportLoading(false);
      alert("Something went wrong! Please try again");
    }
    newOrderDetails?.map((s, i) => {
      const reportDataObj = {}
      reportDataObj['Order Ref No'] = s.order_ref_no
      reportDataObj['Invoice Status'] = s.invoice_status
      reportDataObj['Invoice No.'] = s.invoice_status === 'invoice' ? s.invoice_no : s.profoma_invoice_no
      reportDataObj['Invoice Date'] = formatDate(s.invoice_date)
      reportDataObj['Tournament'] = s.tournament_name
      reportDataObj['Client'] = s.client
      reportDataObj['Sales POC'] = s.sales_poc
      reportDataObj['Type of Requirement'] = s.type_of_requirement
      reportDataObj['Matches'] = s.teams + ', ' + formatDate(s.match_date) + ', ' + s.venue
      reportDataObj['Ticket Category'] = s.category_name
      // reportDataObj['Inventory'] = s.inventory_origin
      reportDataObj['Purchase price'] = s.purchase_price
      reportDataObj['Count'] = s.count
      reportDataObj['Sub Total'] = Number(s.purchase_price) * Number(s.count)
      reportDataObj['Service Charge'] = s.service_charge
      reportDataObj['GST'] = Number(s.service_charge) * 0.18
      reportDataObj['Total'] = (Number(s.purchase_price) * Number(s.count)) + (Number(s.service_charge) + Number(s.service_charge) * 0.18)
      reportDataObj['Status'] = s.status
      reportDataObj['Payment Status'] = s.payment_status
      reportDataObj['Remarks'] = s.remarks === null ? "" : s.remarks
      reportData.push(reportDataObj)
    })

    const data = reportData
    const fileName = "Orders"
    const exportType = 'xls'

    if (data) {
      exportFromJSON({ data, fileName, exportType })
    }
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
          <Box>
            <Box className="text-right" px={2}>
            <NavLink to="/sales"><Button variant="contained" color="secondary" onClick={toggleSearch}>New Invoice</Button></NavLink> &nbsp; 
            <Button mx={2} variant="contained" color="primary" disabled={isExportLoading ? true : false} onClick={()=> exportDetails()}>{isExportLoading ? <CircularProgress color="inherit"/> : "Export to excel"}</Button> &nbsp; 
              <Button variant="contained" color="primary" onClick={toggleSearch}>Advanced Search</Button>
            </Box>
            {searchBox ? (
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="mt-3"><h5>Search By</h5></Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Order Ref No </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Order Ref No"
                    variant="outlined"
                    name="orderRef"
                    value={orders.orderRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Tournament </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Tournament"
                    variant="outlined"  
                    name="tournament"
                    select
                    value={tournament}
                    onChange={(e)=>setTournament(e.target.value)}
                  >
                   {tournamentList.map((item,index)=>
                      <MenuItem key={index} value={item.origin}>{item.name}</MenuItem>
                      )}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Matches </label>
                  <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={matchList}
                   // value={orders.selectedMatch === null ? {} : orders.selectedMatch}
                    getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control mt-1"
                        placeholder="Matches"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setOrders({ ...orders, 
                                  matches: newValue?.origin ? newValue.origin : "", 
                                  selectedMatch: newValue ? newValue : null });
                      // setCustomerOrigin(newValue.origin)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Client</label>
                  {/* <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Client"
                    variant="outlined"
                    name="client"
                    value={orders.client}
                    onChange={handleInputChange}
                  /> */}
                  <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={customerList}
                   // value={orders.client === null ? {} : orders.client}
                    getOptionLabel={(option) => option.first_name + " " + `${option.last_name === null ? "" : option.last_name}` + `${option.description !== null ? " (" + option.description + ")" : ""}`}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.first_name + " " + `${option.last_name === null ? "" : option.last_name}` + `${option.description !== null ? " (" + option.description + ")" : ""}`}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control mt-1"
                        placeholder="Client"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setOrders({ ...orders, 
                                  clientOrigin: newValue?.origin ? newValue.origin : "", 
                                  client: newValue ? newValue : null });
                      // setCustomerOrigin(newValue.origin)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.25} >
                  <label className="text-xs">Sales POC</label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Sales POC"
                    variant="outlined"
                    name="salesPoc"
                    value={orders.salesPoc}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={2.25} >
                  <label className="text-xs">Ticket Category</label>
                  {/* <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Ticket Category"
                    variant="outlined"
                    name="ticketCategory"
                    select
                    value={orders.ticketCategory}
                    onChange={handleInputChange}
                  >
                    {ticketCategoryList?.map((x, i) =>
                      <MenuItem key={i} value={Number(x.origin)}>{x.category_name}</MenuItem>
                    )}
                  </TextField> */}
                  <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={ticketCategoryList}
                    //value={orders.selectedMatch === null ? {} : orders.selectedMatch}
                    getOptionLabel={(option) => option.category_name}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.category_name}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control mt-1"
                        placeholder="Ticket category"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setOrders({ ...orders, ticketCategory: newValue.origin });
                      // setCustomerOrigin(newValue.origin)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.25} >
                  <label className="text-xs">Invoice Number</label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Invoice Number"
                    variant="outlined"
                    name="invoiceNumber"
                    value={orders.invoiceNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={2.25} >
                  <label className="text-xs">Proforma Invoice Number</label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Proforma Invoice Number"
                    variant="outlined"
                    name="proformaInvoiceNumber"
                    value={orders.proformaInvoiceNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
                {/* <Grid item xs={12} md={3} >
                  <label className="text-xs">Type of Requirement</label>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      className="form-control mt-1"
                      id="demo-simple-select"
                      placeholder="select Type of Requirement"
                      variant="outlined"
                      name="requirement"
                      select
                      value={orders.requirement}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="sale">Sale</MenuItem>
                      <MenuItem value="complimentary">Complimentary</MenuItem>
                     </TextField>
                  </FormControl>
                </Grid> */}


                <Grid item xs={12} md={3} className='filter-search'>
                  <Button variant="contained" color="primary" onClick={() => getOrdersDetails()}>Search</Button>
                  <Button variant="contained" color="secondary" onClick={() => {setOrders(initialOrders)}} className="clear-btn">Clear Filter</Button>
                </Grid>
              </Grid>
            ) : " "}
          </Box>
          <Box pt={6} pb={3} className="order-table">

            <Grid container spacing={6}>
              <Grid item xs={12}>

                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Orders
                    </MDTypography>
                  </MDBox>
                  {(!isLoading && ordersDetails.length > 0) ?
                    <Box>
                      <DataTable
                        entriesPerPage={false}
                        pagination={false}
                        showTotalEntries={false}
                        table={{
                          columns: [
                            { Header: "action", accessor: "action", align: "center" },
                            { Header: "Order Ref No", accessor: "order_ref_no" },
                            { Header: "Tournament", accessor: "tournament" },
                            { Header: "Matches", accessor: "matches" },
                            { Header: "Client", accessor: "client" },
                            { Header: "Sales POC", accessor: "sales_poc" },
                            { Header: "Ticket Category", accessor: "ticket_category" },
                            { Header: "Count", accessor: "count" },
                            // { Header: "Type of Requirement", accessor: "type_of_requirement" },
                            { Header: "Invoice Number", accessor: "invoice_number" },
                            { Header: "Invoice Status", accessor: "invoice_status" },
                            { Header: "Status", accessor: "status" },
                          ],
                          rows:
                            ordersDetails.map(item => (
                              {

                                action: (
                                  <>
                                    <Button
                                      className="text-primary"
                                      id="demo-positioned-button"
                                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                      onClick={(e) => handleClick(e, item.order_ref_no, item.invoice_no)}
                                    >
                                      <MoreVertIcon />
                                    </Button>
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <Link to={'/view-orders?' + selectedRef} target="_blank"><MenuItem>Edit</MenuItem></Link>
                                      <Link to={'/invoice3?' + selectedRef + "/p"} target="_blank"><MenuItem>Proforma 3</MenuItem></Link>
                                      {(invoiceNumber !== null && invoiceNumber !== "") && (
                                        <Link to={'/invoice3?' + selectedRef + "/i"} target="_blank"><MenuItem>Invoice 3</MenuItem></Link>
                                      )}
                                    </Menu>
                                  </>
                                  //   <Link to={'/view-orders?'+ item.order_ref_no}>
                                  //     <Tooltip title="Edit" arrow>
                                  //         <Button size="small" variant="contained" color="primary">
                                  //             <ModeOutlinedIcon />
                                  //         </Button>
                                  //     </Tooltip>
                                  // </Link>
                                ),
                                order_ref_no: item.order_ref_no,
                                tournament: item.tournament_name,
                                matches: item.teams + ' ' + item.match_date,
                                client: (
                                  <Tooltip title={item.client != '' ? item.client : item.member_name} placement="top">
                                    <Box className="client-text">
                                      {item.client != '' ? item.client : item.member_name}
                                    </Box></Tooltip>
                                ),
                                sales_poc: (
                                  <Tooltip title={item.sales_poc} placement="top">
                                    <Box className="poc-text">
                                      {item.sales_poc}
                                    </Box>
                                  </Tooltip>
                                ),
                                ticket_category: (
                                  <Tooltip title={item.category_name} placement="top">
                                    <Box className="poc-text">
                                      {item.category_name}
                                    </Box>
                                  </Tooltip>
                                ),
                                count: item.count,
                                invoice_number: (item.invoice_no !== null && item.invoice_no !== "") ? item.invoice_no : item.profoma_invoice_no,
                                invoice_status: item.invoice_status,
                                status: item.status,
                              }
                            ))
                        }}
                      />

                      {totalRecords > 1 && (
                        <Box className="py-3 mb-3" >
                          <Stack spacing={2} alignItems="end">
                            <Pagination className="pagination" page={currentPage} count={totalRecords} onChange={(e, index) => getOrdersDetails(index)} color="primary" />
                          </Stack>
                        </Box>
                      )}
                    </Box>
                    : !isLoading && ordersDetails.length === 0 ?
                      (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <h5>{errorMsg ? errorMsg : "No orders found..."}</h5>
                      </Stack>)
                      :
                      <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress color="success" />
                      </Stack>
                  }
                </Card>

              </Grid>

            </Grid>
          </Box>
        </Box>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Orders;
