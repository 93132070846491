import React, { useState, useEffect } from "react"
import { Container, Card, Box, TextField, Grid, CardContent, Typography, MenuItem, FormControl, Button, FormHelperText, LinearProgress, Autocomplete } from "@mui/material";
import 'pages/common.css'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {  Row, Col, Stack } from 'react-bootstrap';
import API from "apiConfig";
import RichTextEditor from 'react-rte';
import Modal from 'react-bootstrap/Modal';

function ViewOrders() {
    const location = useLocation();
    let currentUrl = window.location.href.split("?");
    let origin = currentUrl[currentUrl.length - 1];
    let orderRefNo = currentUrl[currentUrl.length - 1];

    const resultPerPage = 20;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState();
    const [remarks, setRemarks] = useState("");
    const [isInitial, setIsInitial] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [initialData, setInitialData] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [matchList, setMatchList] = useState([]);
    const [customerList, setcustomerList] = useState([]);
    const [customerOrigin, setCustomerOrigin] = useState(null);
    const [ticketCategoryList, setTicketCategoryList] = useState([]);
    const [selectedMatchOrigin, setSelectedMatchOrigin] = useState(null);
    const [isEditStarts, setIsEditStarts] = useState(false);
    const [show, setShow] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [totalServiceCharge, setTotalServiceCharge] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [CGST, setCGST] = useState(0);
    const [SGST, setSGST] = useState(0);
    const [IGST, setIGST] = useState(0);
    const [tournamentList, setTournamentList] = useState([]);

    const [orders, setOrders] = useState({
        orderRef: "", invoiceStatus: "", invoiceNo: "", proformaInvoiceNo: "", invoiceDate: new Date(), tournament: "", matches: "", matchOrigin: "",
        client: "", salesPoc: "", ticketCategory: "", ticketCategoryOrigin: "", requirement: "", status: "",
        count: "", email: "", markup: "", serviceCharge: "", gst: "", purchase_price: "",
        payment_status: "", advance_received: "", payment_details: "", poNumber: ""
    });
    const [errorDetails, setErrorDetails] = useState({
        orderRef: null, invoiceStatus: null, invoiceNo: null, proformaInvoiceNo: null, invoiceDate: null, tournament: null, matches: null,
        client: null, salesPoc: null, ticketCategory: null, requirement: null, status: null,
        count: null, email: null, markup: null, serviceCharge: null, gst: null, purchase_price: null,
        payment_status: null
    });
    const [ordersDetails, setOrdersDetails] = useState([]);
    const [formsError, setFormsError] = useState([
        {
            id: 0, matches: null, ticket_category: null, purchase_price: null,
            count: null, first_total: null, service_charge: null, gst: null,
            second_total: null
        }]);
    const [supportFiles, setSupportFiles] = useState([{id: 0, name: '', docType: '', file: ''}]);
    const [paymentText, setPaymentText] = useState(RichTextEditor.createEmptyValue());

    useEffect(() => {
        getTournamentDetails();
        getOrdersDetails();
        getMatchList();
        getCustomerList();
        getAccountEntryDetails();
    }, []);

    const getTournamentDetails = async () => {
        setIsLoading(true);
    
        const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
        if (response?.data?.status && response?.data?.data?.data) {
          setTournamentList(response.data.data.data);
          setIsLoading(false);
        }
        else if (response?.data?.message === 'No orders found') {
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
          alert("Something went wrong! Please try again");
        }
      }
    

    const getAccountEntryDetails = async (pageNo) => {
        setIsLoading(true);       
        try {
            await API.get(`orders/get_orders_details_list/0?order_ref_no=${orderRefNo}&tournament&matches=&client=&sales_poc&ticket_category&type_of_requirement=&origin=`)
                .then(async (response) => {
                    // console.log("Response123456789", response.data.data.data[0])
                    if (response.data.status && response.data.data.data) {        
                        setOrderDetails(response.data.data.data);
                        let total_service_charge = response.data.data.data.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0);
                        let total = response.data.data.data.map(x=>(Number(x.count) * Number(x.purchase_price))).reduce((partialSum, a) => partialSum + a, 0);
                        let sub_total = total_service_charge + total
                        setSubTotal(sub_total)
                        setTotalServiceCharge(total_service_charge);
                        if(response.data.data.data[0]?.other_terms_conditions !== null){
                        setPaymentText(RichTextEditor.createValueFromString(response.data.data.data[0]?.other_terms_conditions, 'html'));
                        }
                        if(response.data.data.data[0].registration_type === "SEZ"){
                            setCGST(0);
                            setSGST(0);
                            setIGST((total_service_charge * 18/100));
                            setTotalPrice(sub_total + (total_service_charge * 18/100)); 
                        }
                        else{
                        if(response.data.data.data[0].state === "Karnataka" || response.data.data.data[0].state === null ||
                            response.data.data.data[0].state === undefined || response.data.data.data[0].state === ""){
                            setCGST((total_service_charge * 9/100));
                            setSGST((total_service_charge * 9/100));
                            setIGST(0);
                            setTotalPrice(sub_total + (total_service_charge * 9/100) + (total_service_charge * 9/100))
                        }
                        else if(response.data.data.data[0].state !== "Karnataka"){
                            setCGST(0);
                            setSGST(0);
                            setIGST((total_service_charge * 18/100));
                            setTotalPrice(sub_total + (total_service_charge * 18/100))
                        }
                        }
                        setIsLoading(false);
                        }
                    else {
                        setIsLoading(false);
                        alert("Something went wrong! Please try again");
                       // window.location.reload();
                    }
                })
                .catch((error) => {
                    alert("Something went wrong! Please try again");
                   // window.location.reload();
                })
        } catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

    const getOrdersDetails = async (pageNo) => {
        setIsLoading(true);
        let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
        let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
        let origin = currentUrl[currentUrl.length - 1]

        try {
            await API.get(`orders/get_orders_details_list/${pageCount}?order_ref_no=${orderRefNo}&tournament&matches=&client=&sales_poc&ticket_category&type_of_requirement=&origin=`)
                .then(async (response) => {
                    // console.log("Response", response.data.data.data)
                    if (response.data.status && response.data.data.data) {
                        let total_records = response.data.data.total_records;
                        setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
                        let data = response.data.data.data[0];
                        setRemarks(data.remarks);
                        let matchObj = {
                            match_date: data.match_date,
                            name: data.tournament_name,
                            origin: data.origin,
                            teams: data.teams,
                            tournament: data.tournament,
                            venue: data.venue
                        }
                        let ticketCategoryObj = {
                            category_name: data.category_name,
                            city: data.city,
                            origin: data.ticket_category,
                            city: data.venue
                        }
                        let customer_obj ={
                            "origin": data.client,
                            "last_name": data.member_name + `${(data.description !== null && data.description !== undefined && data.description !== "") ? " (" + data.description + ")" : ""}`,
                            "company_name": data.company_name + `${(data.description !== null && data.description !== undefined && data.description !== "") ? " (" + data.description + ")" : ""}`
                        }
                        setOrders({
                            ...orders,
                            orderRef: data.order_ref_no,
                            matches: matchObj,
                            matchOrigin: Number(data.matches),
                            client: customer_obj,
                            salesPoc: data.sales_poc,
                            ticketCategory: ticketCategoryObj,
                            ticketCategoryOrigin: data.ticket_category,
                            count: data.count,
                            requirement: data.type_of_requirement,
                            status: data.status,
                            email: data.email,
                            purchase_price: data.purchase_price,
                            markup: data.markup,
                            serviceCharge: data.service_charge,
                            gst: data.gst,
                            total: data.sale_price,
                            invoiceStatus: data.invoice_status,
                            invoiceNo: data.invoice_no,
                            proformaInvoiceNo: data.profoma_invoice_no,
                            payment_status: data.payment_status,
                            invoiceDate: data.invoice_date,
                            tournament: data.tournament,
                            advance_received: data?.advance_received,
                            payment_details: data?.payment_details,
                            poNumber: data?.po_number
                        })
                        setCurrentPage(activePageIndex);
                        setCustomerOrigin(data.client);
                        let array_data = await Promise.all(response.data.data.data.map(async (x, i) => {
                            let match_obj = {
                                match_date: x.match_date,
                                name: x.tournament_name,
                                origin: x.origin,
                                teams: x.teams,
                                tournament: x.tournament,
                                venue: x.venue
                            }
                            let ticket_category_obj = {
                                category_name: x.category_name,
                                city: x.city,
                                origin: x.ticket_category,
                                city: x.venue
                            }
                            let dat = await getTicketCategoryList(x.venue, i, match_obj)
                            //console.log("Data",dat)
                            return (
                                {
                                    id: i,
                                    matches: match_obj,
                                    origin: x.origin,
                                    matches_origin: x.matches,
                                    selected_match: `${x.tournament_name}, ${x.match_date}, ${x.venue}`,
                                    ticket_category: ticket_category_obj,
                                    ticket_category_origin: x.ticket_category,
                                    ticket_category_list: dat,
                                    purchase_price: (Number(x.purchase_price)).toFixed(2),
                                    count: x.count,
                                    first_total: (Number(x.purchase_price) * Number(x.count)).toFixed(2),
                                    service_charge: (Number(x.service_charge)).toFixed(2),
                                    gst: (Number(x.gst)).toFixed(2),
                                    second_total: (Number(x.service_charge) + Number(x.gst)).toFixed(2),
                                    inventory_data: x.inventory_data,
                                    selectedInventoryOrigin: x.inventory_origin,
                                    newOrderDetails: [],
                                    discount: x?.discount !== undefined ? x?.discount : 0
                                }
                            )
                        }))
                        // console.log("Data",array_data)
                        setOrdersDetails(array_data);
                        if (isInitial) {
                            setInitialData(response.data.data.data);
                            setIsInitial(false);
                        }
                        setIsLoading(false);
                        setErrorMsg("");
                        //  getTicketCategoryList(data.venue);
                    }
                    if(response.data.status && response.data?.data?.voucher_attachments){
                        let arr = response.data?.data?.voucher_attachments?.map((x,i)=>
                        {return({id: i, name: x?.file_name, docType: x?.doc_type, file: x?.file_name})});
                        setSupportFiles(arr);
                    }
                    // else {
                    //     setIsLoading(false);
                    //     setCurrentPage(1);
                    //     setErrorMsg(response.data.message)
                    //     alert("Something went wrong! Please try again");
                    //     window.location.reload();
                    // }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setCurrentPage(1);
                    setErrorMsg("No results found...")
                    alert("Something went wrong! Please try again");
                    window.location.reload();
                })
        } catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    function formatDate2(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    const getCustomerList = async () => {
        setIsLoading(true);
        const response = await API.get('inventory/get_vendors/customer');
        if (response.data.status) {
            //   let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
            setcustomerList(response.data.data);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }

    const getMatchList = async () => {
        setIsLoading(true);
        const response = await API.get('inventory/get_match_list');
        if (response.data.status) {
            //   let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
            setMatchList(response.data.data);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }

    const onUpdate = async (item) => {
        setIsLoading(true);
        // let dataToSend = {
        //     "matches": ordersDetails.map(x=> x.matches_origin),
        //     "client": orders.client,
        //     "sales_poc": orders.salesPoc,
        //     "email": orders.email,
        //     "ticket_category": ordersDetails.map(x=> x.ticket_category_origin),
        //     "count": ordersDetails.map(x=> x.count),
        //     "type_of_requirement": orders.requirement,
        //     "status": orders.status,
        //     "remarks": remarks,
        //     "invoice_status" : orders.invoiceStatus,
        //     "invoice_no": Number(orders.invoiceNo),
        //     "purchase_price": ordersDetails.map(x=> Number(x.purchase_price)),
        //    // "markup": Number(orders.markup),
        //     "service_charge": ordersDetails.map(x=> Number(x.service_charge)),
        //     "gst": ordersDetails.map(x=> Number(x.gst)),
        //     "match_total": ordersDetails.map(x=> Number(x.first_total)),
        //     "service_charge_total": ordersDetails.map(x=> Number(x.second_total)),
        //     "payment_status": orders.payment_status
        // }
        let dataToSend = {
            "order_ref_no": orders.orderRef,
            "invoice_status": orders.invoiceStatus,
            "invoice_no": orders.invoiceNo,
            "invoice_date": orders.invoiceDate,
            "profoma_invoice_no": orders.proformaInvoiceNo,
            "tournament": orders.tournament,
            "tournament_name": orders.matches.name,
            "advance_received": orders.advance_received,
            "payment_details": orders.payment_details,
            "doc": supportFiles.map(item=> {return({ name: item.name, file: item.file, docType: item.docType})}),
            "other_terms_conditions": paymentText.toString('html'),
            "po_number": orders.poNumber,
            "matches": ordersDetails.map(x => {
                return (
                    {
                        "origin": x.origin,
                        "matches": x.matches_origin,
                        "client": customerOrigin,
                        "sales_poc": orders.salesPoc,
                        "email": orders.email,
                        "ticket_category": x.ticket_category_origin,
                        "count": x.count,
                        "type_of_requirement": orders.requirement,
                        "status": orders.status,
                        "payment_status": orders.payment_status,
                        "sale_price": x.first_total,
                        "purchase_price": x.purchase_price,
                        "service_charge": x.service_charge,
                        "service_charge_total": x.second_total,
                        "gst": x.gst,
                        "invoice": "0",
                        "remarks": remarks,
                        "match_date": x.matches.match_date,
                        "teams": x.matches.teams,
                        "venue": x.matches.venue,
                        "inventory_origin": x.selectedInventoryOrigin,
                        "discount": x.discount
                    }
                )
            }),
            "new_order_details": ordersDetails.map(y => y.newOrderDetails).flat().map(x => {
                return (
                    {
                        "origin": x.origin,
                        "matches": x.matches_origin,
                        "client": customerOrigin,
                        "sales_poc": orders.salesPoc,
                        "email": orders.email,
                        "ticket_category": x.ticket_category_origin,
                        "count": x.count,
                        "type_of_requirement": orders.requirement,
                        "status": orders.status,
                        "payment_status": orders.payment_status,
                        "sale_price": x.first_total,
                        "purchase_price": x.purchase_price,
                        "service_charge": x.service_charge,
                        "service_charge_total": x.second_total,
                        "gst": x.gst,
                        "invoice": "0",
                        "remarks": remarks,
                        "match_date": x.matches.match_date,
                        "teams": x.matches.teams,
                        "venue": x.matches.venue,
                        "inventory_origin": x.selectedInventoryOrigin,
                        "discount": x.discount
                    }
                )
            })
        }
       //   console.log("dataToSend",dataToSend)
        const response = await API.post(`orders/update_orders/${orderRefNo}`, dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
            setIsLoading(false);
        }
        else if (!response.data.status) {
            alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }

    const handleInputChange = (e) => {
        setOrders({ ...orders, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
        if (e.target.name === "serviceCharge") {
            setOrders({ ...orders, gst: Number(e.target.value) * 0.18, serviceCharge: e.target.value });
        }
    }

    const handleChange = (event, index) => {
        // console.log("Value",event.target.value, "name", event.target.name)
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        if (event.target.name === "purchase_price") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].count)).toFixed(2)
            }
        }
        else if (event.target.name === "count") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].purchase_price)).toFixed(2)
            }
        }
        else if (event.target.name === "service_charge") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: (Number(event.target.value) * 0.18).toFixed(2),
                second_total: (Number(event.target.value) + Number(event.target.value) * 0.18).toFixed(2)
            }
            if(IGST !== 0){
                setIGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 18/100);
            }
            else{
                setCGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9/100);
                setSGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9/100);
            }
        }
        else if (event.target.name === "second_total") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: ((Number(event.target.value) / 118) * 18).toFixed(2),
                service_charge: ((Number(event.target.value) / 118) * 100).toFixed(2)
            }
            if(IGST !== 0){
                setIGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 18/100);
            }
            else{
                setCGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9/100);
                setSGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9/100);
            }
        }

        newArray[index] = { ...newArray[index], [event.target.name]: event.target.value }
        newArrayError[index] = { ...newArrayError[index], [event.target.name]: null }

        // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const handleNewOrderDetails = (event, index, i) => {
        const newArray = [...ordersDetails[i].newOrderDetails];
       // const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        if (event.target.name === "purchase_price") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].count)).toFixed(2)
            }
        }
        else if (event.target.name === "count") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].purchase_price)).toFixed(2)
            }
        }
        else if (event.target.name === "service_charge") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: (Number(event.target.value) * 0.18).toFixed(2),
                second_total: (Number(event.target.value) + Number(event.target.value) * 0.18).toFixed(2)
            }
            if(IGST !== 0){
                setIGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 18/100);
            }
            else{
                setCGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9/100);
                setSGST(Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9/100);
            }
        }
        else if (event.target.name === "second_total") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: ((Number(event.target.value) / 118) * 18).toFixed(2),
                service_charge: ((Number(event.target.value) / 118) * 100).toFixed(2)
            }
            if(IGST !== 0){
                setIGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 18/100);
            }
            else{
                setCGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9/100);
                setSGST((Number(newArray.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9/100);
            }
        }
        
        let dataForms = [...ordersDetails]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].newOrderDetails = newArray
        setOrdersDetails(dataForms);
    }

    const handleInventory = (event, index, invData, prevObj) => {
        // console.log("Value",event.target.value, "name", event.target.name)
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        let required_data = invData?.filter(x=> x.origin === event.target.value)[0]
        let count = Number(prevObj.count) <= Number(required_data.stock_left) ? Number(prevObj.count) : Number(required_data.stock_left)
        newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                purchase_price: Number(required_data.purchase_price),
                count: count,
                first_total: (Number(required_data.purchase_price) * Number(count)).toFixed(2),
                newOrderDetails: Number(required_data.stock_left) < Number(prevObj.count) ? [{
                    id: prevObj.newOrderDetails.length,
                    matches: prevObj.matches,
                    origin: prevObj.origin,
                    matches_origin: prevObj.matches_origin,
                    selected_match: prevObj.selected_match,
                    ticket_category: prevObj.ticket_category,
                    ticket_category_origin: prevObj.ticket_category_origin,
                    ticket_category_list: prevObj.ticket_category_list,
                    purchase_price: 0,
                    count: Number(prevObj.count - required_data.stock_left),
                    first_total: 0,
                    service_charge: (Number(prevObj.service_charge)).toFixed(2),
                    gst: (Number(prevObj.gst)).toFixed(2),
                    second_total: (Number(prevObj.service_charge) + Number(prevObj.gst)).toFixed(2),
                    inventory_data: prevObj.inventory_data?.filter(x => x.origin !== event.target.value),
                    selectedInventoryOrigin: "0",
                }] : []
            }

      // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const handleNewOrderDetailsInventory = (event, index, invData, prevObj, i) => {
        // console.log("Value",event.target.value, "name", event.target.name)
        let newArray = [...ordersDetails[i].newOrderDetails];
        const newArrayError = [...formsError];

        let required_data = invData?.filter(x=> x.origin === event.target.value)[0]
        let count = Number(prevObj.count) <= Number(required_data.stock_left) ? Number(prevObj.count) : Number(required_data.stock_left)
        newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                purchase_price: Number(required_data.purchase_price),
                count: count,
                first_total: (Number(required_data.purchase_price) * Number(count)).toFixed(2)
            }

            if(Number(required_data.stock_left) < Number(prevObj.count)){
                newArray = [...newArray, {
                    id: ordersDetails[i].newOrderDetails.length,
                    matches: prevObj.matches,
                    origin: prevObj.origin,
                    matches_origin: prevObj.matches_origin,
                    selected_match: prevObj.selected_match,
                    ticket_category: prevObj.ticket_category,
                    ticket_category_origin: prevObj.ticket_category_origin,
                    ticket_category_list: prevObj.ticket_category_list,
                    purchase_price: 0,
                    count: Number(prevObj.count - required_data.stock_left),
                    first_total: 0,
                    service_charge: (Number(prevObj.service_charge)).toFixed(2),
                    gst: (Number(prevObj.gst)).toFixed(2),
                    second_total: (Number(prevObj.service_charge) + Number(prevObj.gst)).toFixed(2),
                    inventory_data: prevObj.inventory_data?.filter(x => x.origin !== event.target.value),
                    selectedInventoryOrigin: "0",
                }]
            }

      // Call setData to update.
        // setOrdersDetails(newArray);
        // setFormsError(newArrayError);
        // setIsEditStarts(true);

        let dataForms = [...ordersDetails]
        let objIndex = dataForms.findIndex((obj => obj.id == i));
        dataForms[objIndex].newOrderDetails = newArray
        setOrdersDetails(dataForms);
    }

    const getTicketCategoryList = async (venue, index, newValue) => {
        // const newArray = [...ordersDetails];
        // const newArrayError = [...formsError];
        let res_data;

        setIsLoading(true);
        const response = await API.get(`inventory/get_ticket_category_list/${venue}`);
        if (response.data.status) {
            // setTicketCategoryList(response.data.data);
            res_data = response.data.data
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
        return res_data
    }

    const getTicketCategoryList1 = async (venue, index, newValue) => {
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        let new_dat
        setIsLoading(true);
        const response = await API.get(`inventory/get_ticket_category_list/${venue}`);
        if (response.data.status) {
            // setTicketCategoryList(response.data.data);
            new_dat = response.data.data
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
        // console.log("newVal", newValue)
        // console.log("new_data", new_dat)
        newArray[index] = {
            ...newArray[index],
            selected_match: `${newValue.teams}, ${newValue.match_date}, ${newValue.venue}`,
            ticket_category_list: new_dat,
            matches: newValue,
            matches_origin: newValue.origin
        }

        // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const onTicketcategoryChange = (newValue, index) => {
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        newArray[index] = {
            ...newArray[index],
            ticket_category: newValue,
            ticket_category_origin: newValue.origin
        }

        // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const uploadFile = async (event, type) => {
        const file = event.target.files[0]
    
        console.log(event.target.files[0]);
        var fileSize = parseFloat(file.size / 1000).toFixed(2);
        console.log("Size", fileSize);
        console.log("file.type", file.type)
        console.log("file.name", file)
        if (fileSize > 2028) {
         alert("Please upload a file smaller than 2mb" );
          return false;
        }
    
        //  if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "application/pdf" && file.type != "image/png") {
        //  // setErrorDetails({ ...errorDetails, gstFile: "Please select a JPG/JPEG/PNG/PDF file for upload" });
        //   return;
        // }
        // else {
          let img = new Image()
          img.src = window.URL.createObjectURL(file)
          var reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = function () {
            let covertedData = btoa(reader.result);
            console.log("base64", `data:${file.type};base64,${covertedData}`);
            const newArray = [...supportFiles];
            let arrLength = supportFiles.filter(item=> item.name !== '' && item.file !== '').length
            newArray[arrLength] = { ...newArray[arrLength], id: arrLength, docType: '', name: file.name, size: fileSize, file: `data:${file.type};base64,${covertedData}` }
            setSupportFiles(newArray);
            // setMembers({...members, gstFile: `data:${file.type};base64,${covertedData}`});
            //   setErrorDetails({ ...errorDetails, gstFile: null});
          };
          reader.onerror = function () {
            console.log('there are some problems');
          };
        // }
      }

      const handleSupportFilesDocType= (e, index) => {
        const newArray = [...supportFiles];
            newArray[index] = { ...newArray[index], docType: e.target.value }
            setSupportFiles(newArray);
      }

      const onSupportFileDelete = (index) => {
        if(supportFiles.length > 1){
        let newForm = supportFiles.filter(item => item.id !== index);
        let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
        setSupportFiles(oneMore);
        }
        else{
            setSupportFiles([{id: 0, name: '', size: '', docType: '', file: ''}]);   
        }
    }

    const onEditorChange = (value) => {
        setPaymentText(value);
    };
 //console.log("ordersDetails", ordersDetails)    
    return (

        <DashboardLayout>
                <DashboardNavbar />
                {!isLoading ? (
                    <Card >
                        <Grid pt={2} container>
                            <Grid item xs={12} md={12} xl={12}>

                                <Box>
                                    <CardContent>
                                        <Typography variant="h4" component="div" className="primary-text order-heading">
                                            Order Details 
                                            <span className="proforma-links">
                                            {/* <Link to={'/invoice?'+ orderRefNo + "/p"} target="_blank">Proforma 1</Link> 
                                            <Link to={'/invoice2?'+ orderRefNo + "/p"} target="_blank">Proforma 2</Link> */}
                                            <Link to={'/invoice3?'+ orderRefNo + "/p"} target="_blank">Proforma 3</Link>
                                            {/* <Button onClick={()=> handleShowModal()}>Clone</Button> */}
                                            {orders.invoiceStatus === "invoice" && (
                                                <>
                                            {/* <Link to={'/invoice?'+ orderRefNo + "/i"} target="_blank">Invoice 1</Link> 
                                            <Link to={'/invoice2?'+ orderRefNo + "/i"} target="_blank">Invoice 2</Link> */}
                                            <Link to={'/invoice3?'+ orderRefNo + "/i"} target="_blank">Invoice 3</Link>
                                            {/* <Button onClick={()=> handleShowModal()}>Clone</Button> */}
                                                </>
                                            )}
                                            </span>
                                        </Typography>

                                        <Box className="mt-2">
                                            <Box component="form" noValidate autoComplete="off" >
                                                <Box className="box-container">
                                                    <Grid container spacing={2} className="filter-search-box ">
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Order Ref No </label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Order Ref No"
                                                                variant="outlined"
                                                                name="orderRef"
                                                                value={orders.orderRef}
                                                                disabled
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Invoice Status </label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Invoice Status"
                                                                variant="outlined"
                                                                name="invoiceStatus"
                                                                select
                                                                value={orders.invoiceStatus}
                                                                onChange={handleInputChange}
                                                            >
                                                                <MenuItem value="proforma">Proforma</MenuItem>
                                                                <MenuItem value="invoice">Invoice</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        {orders.invoiceStatus === "invoice" ? (
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Invoice No. </label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                //type="number"
                                                                placeholder="Invoice No."
                                                                variant="outlined"
                                                                name="invoiceNo"
                                                                value={orders.invoiceNo}
                                                                onChange={handleInputChange}
                                                               // inputProps={{ min: 0 }}
                                                            />
                                                        </Grid>
                                                        ) : (
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Proforma Invoice No.</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                //type="number"
                                                                placeholder="Proforma Invoice No."
                                                                variant="outlined"
                                                                name="proformaInvoiceNo"
                                                                value={orders.proformaInvoiceNo}
                                                                onChange={handleInputChange}
                                                               // inputProps={{ min: 0 }}
                                                            />
                                                        </Grid>
                                                        )}
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Invoice Date</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                type={"date"}
                                                                placeholder="Invoice Date"
                                                                variant="outlined"
                                                                name="invoiceDate"
                                                                value={orders.invoiceDate}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                    <Grid item xs={12} md={3} >
                                                        <label className="text-xs">Tournament </label>
                                                        <TextField
                                                            className="form-control mt-1"
                                                            id="outlined-basic"
                                                            placeholder="Tournament"
                                                            variant="outlined"
                                                            name="tournament"
                                                            select
                                                            value={orders.tournament}
                                                            onChange={handleInputChange}
                                                        >
                                                            {tournamentList.map((item, index) =>
                                                                <MenuItem key={index} value={item.origin}>{item.name}</MenuItem>
                                                            )}
                                                        </TextField>
                                                    </Grid>
                                                    
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Client</label>
                                                            {/* <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Client"
                                                                variant="outlined"
                                                                name="client"
                                                                value={orders.client}
                                                                onChange={handleInputChange}
                                                            /> */}
                                                            <Autocomplete
                                                                className="inputFieldLocation"
                                                                size="small"
                                                                id="outlined-basic"
                                                                freeSolo
                                                                autoComplete
                                                                includeInputInList
                                                                filterSelectedOptions
                                                                options={customerList}
                                                                value={orders.client}
                                                                getOptionLabel={(option) => option.company_name + `${(option.description !== null && option.description !== undefined && option.description !== "") ? " (" + option.description + ")" : ""}`}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} key={option.origin}>
                                                                        {option.company_name + `${(option.description !== null && option.description !== undefined && option.description !== "") ? " (" + option.description + ")" : ""}`}
                                                                    </li>
                                                                )}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        {...params}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        className="form-control mt-1"
                                                                        placeholder="Matches"
                                                                    />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                     setOrders({...orders, client: newValue});
                                                                     setCustomerOrigin(newValue.origin)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Sales POC</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Sales POC"
                                                                variant="outlined"
                                                                name="salesPoc"
                                                                value={orders.salesPoc}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">PO Number</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="PO Number"
                                                                variant="outlined"
                                                                name="poNumber"
                                                                value={orders.poNumber}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Type of Requirement</label>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    type="text"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="select Type of Requirement"
                                                                    variant="outlined"
                                                                    name="requirement"
                                                                    select
                                                                    value={orders.requirement}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <MenuItem value="sale">Sale</MenuItem>
                                                                    <MenuItem value="complimentary">Complimentary</MenuItem>
                                                                </TextField>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                {ordersDetails.map((item, index) =>
                                                <>
                                                <Box className="box-container my-3">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} md={6} >
                                                            <label className="text-xs">Matches </label>
                                                            <Autocomplete
                                                                className="inputFieldLocation"
                                                                size="small"
                                                                id="outlined-basic"
                                                                freeSolo
                                                                autoComplete
                                                                includeInputInList
                                                                filterSelectedOptions
                                                                options={matchList}
                                                                value={item.matches}
                                                                getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} key={option.origin}>
                                                                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                                                                    </li>
                                                                )}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        {...params}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        className="form-control mt-1"
                                                                        placeholder="Matches"
                                                                    />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    // setOrders({...orders, matches: newValue, matchOrigin: newValue.origin});
                                                                    getTicketCategoryList1(newValue.venue, index, newValue);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Ticket Category</label>
                                                            
                                                            <Autocomplete
                                                                className="inputFieldLocation mt-1"
                                                                size="small"
                                                                id="free-solo-demo"
                                                                freeSolo
                                                                autoComplete
                                                                includeInputInList
                                                                filterSelectedOptions
                                                                value={item.ticket_category}
                                                                options={item.ticket_category_list}
                                                                getOptionLabel={(option) => option.category_name}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} key={option.origin}>
                                                                        {option.category_name}
                                                                    </li>
                                                                )}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        id="outlined-basic"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        className="inputboxremark"
                                                                        placeholder="Choose a category..."
                                                                    />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    //  console.log(newValue);
                                                                    onTicketcategoryChange(newValue, index)
                                                                    // setOrders({...orders, ticketCategory: newValue, ticketCategoryOrigin: newValue.origin})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Inventory</label>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    type="text"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="select Inventory"
                                                                    variant="outlined"
                                                                    name="selectedInventoryOrigin"
                                                                    select
                                                                    value={item.selectedInventoryOrigin}
                                                                    onChange={(e)=> handleInventory(e, index, item.inventory_data, item)}
                                                                >
                                                                    {item.inventory_data?.map((x,i)=> 
                                                                    <MenuItem value={x.origin}>{"INR " + x.purchase_price + " (" + x.stock_left + ")"}</MenuItem>
                                                                    )}
                                                                </TextField>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={12} md={1.7}  >
                                                            <label className="text-xs">Purchase price </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Purchase price"
                                                                    variant="outlined"
                                                                    name="purchase_price"
                                                                    inputProps={{ min: 0 }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={item.purchase_price}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={1.7} >
                                                            <label className="text-xs">Count</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Count"
                                                                variant="outlined"
                                                                name="count"
                                                                value={item.count}
                                                                onChange={(e) => handleChange(e, index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={1.7}>
                                                            <label className="text-xs">Total </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Sale price"
                                                                    variant="outlined"
                                                                    // name="sale_price"
                                                                    // value={Number(orders.purchase_price) + Number(orders.markup) + Number(orders.serviceCharge) + Number(orders.gst)}
                                                                    name="first_total"
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={item.first_total}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} md={1.7}>
                                                            <label className="text-xs">Service Charge </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Service Charge"
                                                                    variant="outlined"
                                                                    name="service_charge"
                                                                    inputProps={{ min: 0 }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={item.service_charge}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={1.7} >
                                                            <label className="text-xs">GST </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="GST"
                                                                    variant="outlined"
                                                                    name="gst"
                                                                    inputProps={{ min: 0 }}
                                                                    value={item.gst}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={1.7} >
                                                            <label className="text-xs">Total </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Sale price"
                                                                    variant="outlined"
                                                                    name="second_total"
                                                                    value={item.second_total}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={1.8} >
                                                            <label className="text-xs">Discount </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Discount"
                                                                    variant="outlined"
                                                                    name="discount"
                                                                    inputProps={{ min: 0 }}
                                                                    value={item.discount}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                    </Box>
                                                    {item.newOrderDetails.length > 0 && item.newOrderDetails.map((data, idx) =>
                                                <Box className="box-container my-3" style={{backgroundColor:"#e4eaf5"}}>
                                                    <Grid container spacing={1}>
                                                                <Grid item xs={12} md={6} >
                                                                    <label className="text-xs">Matches </label>
                                                                    <TextField
                                                                        type="text"
                                                                        className="form-control mt-1"
                                                                        id="demo-simple-select"
                                                                        placeholder="select Inventory"
                                                                        variant="outlined"
                                                                        name="selectedInventoryOrigin"
                                                                        disabled
                                                                        value={item.matches.teams + ", " + formatDate(item.matches.match_date) + ", " + item.matches.venue}
                                                                    />
                                                                </Grid>
                                                        <Grid item xs={12} md={3} >
                                                           <Grid item xs={12} md={6} >
                                                                    <label className="text-xs">Ticket Category </label>
                                                                    <TextField
                                                                        type="text"
                                                                        className="form-control mt-1"
                                                                        id="demo-simple-select"
                                                                        placeholder="select Inventory"
                                                                        variant="outlined"
                                                                        name="selectedInventoryOrigin"
                                                                        disabled
                                                                        value={item.ticket_category.category_name}
                                                                    />
                                                                </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} >
                                                            <label className="text-xs">Inventory</label>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    type="text"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="select Inventory"
                                                                    variant="outlined"
                                                                    name="selectedInventoryOrigin"
                                                                    select
                                                                    value={data.selectedInventoryOrigin}
                                                                    onChange={(e)=> handleNewOrderDetailsInventory(e, idx, data.inventory_data, data, index)}
                                                                >
                                                                    {data.inventory_data?.map((x,i)=> 
                                                                    <MenuItem value={x.origin}>{"INR " + x.purchase_price + " (" + x.stock_left + ")"}</MenuItem>
                                                                    )}
                                                                </TextField>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={12} md={2}  >
                                                            <label className="text-xs">Purchase price </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Purchase price"
                                                                    variant="outlined"
                                                                    name="purchase_price"
                                                                    inputProps={{ min: 0 }}
                                                                    value={data.purchase_price}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => handleNewOrderDetails(e, idx, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={2} >
                                                            <label className="text-xs">Count</label>
                                                            <TextField
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Count"
                                                                variant="outlined"
                                                                name="count"
                                                                value={data.count}
                                                                onChange={(e) => handleNewOrderDetails(e, idx, index)}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            <label className="text-xs">Total </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Sale price"
                                                                    variant="outlined"
                                                                    // name="sale_price"
                                                                    // value={Number(orders.purchase_price) + Number(orders.markup) + Number(orders.serviceCharge) + Number(orders.gst)}
                                                                    name="first_total"
                                                                    value={data.first_total}
                                                                    onChange={(e) => handleNewOrderDetails(e, idx, index)}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} md={2}>
                                                            <label className="text-xs">Service Charge </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Service Charge"
                                                                    variant="outlined"
                                                                    name="service_charge"
                                                                    inputProps={{ min: 0 }}
                                                                    value={data.service_charge}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => handleNewOrderDetails(e, idx, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={2} >
                                                            <label className="text-xs">GST </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="GST"
                                                                    variant="outlined"
                                                                    name="gst"
                                                                    inputProps={{ min: 0 }}
                                                                    value={data.gst}
                                                                    onChange={(e) => handleNewOrderDetails(e, idx, index)}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={2} >
                                                            <label className="text-xs">Total </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="number"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Sale price"
                                                                    variant="outlined"
                                                                    name="second_total"
                                                                    value={data.second_total}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => handleNewOrderDetails(e, idx, index)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                    </Box>
                                                )}
                                                    </>
                                                )}

                                                <Box className="box-container">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6} >
                                                            <label className="text-xs">Status </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="text"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Choose Status"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    select
                                                                    value={orders.status}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <MenuItem value="new">New</MenuItem>
                                                                    <MenuItem value="delivered">delivered</MenuItem>
                                                                    <MenuItem value="rejected">rejected</MenuItem>
                                                                    <MenuItem value="aborted">aborted</MenuItem>
                                                                    <MenuItem value="need_clarity">need clarity</MenuItem>
                                                                    <MenuItem value="duplicate">duplicate</MenuItem>
                                                                    <MenuItem value="on_hold">On hold</MenuItem>

                                                                </TextField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} >
                                                            <label className="text-xs">Payment Status </label>
                                                            <FormControl fullWidth mt={1}>
                                                                <TextField
                                                                    type="text"
                                                                    className="form-control mt-1"
                                                                    id="demo-simple-select"
                                                                    placeholder="Choose Payment Status"
                                                                    variant="outlined"
                                                                    name="payment_status"
                                                                    select
                                                                    value={orders.payment_status}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <MenuItem value="paid">Paid</MenuItem>
                                                                    <MenuItem value="given_credit">Given credit</MenuItem>
                                                                    <MenuItem value="pending">Pending</MenuItem>

                                                                </TextField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6} md={3} >
                                    <label className="text-xs">Advance Received</label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="number"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Advance Received"
                                            variant="outlined"
                                            name="advance_received"
                                            InputProps={{ inputProps: { min: 0} }}
                                            value={orders.advance_received}
                                            onChange={(e) => setOrders({ ...orders, advance_received: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3} >
                                    <label className="text-xs">Payment Details</label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Payment details"
                                            variant="outlined"
                                            name="payment_details"
                                            value={orders.payment_details}
                                            inputProps={{ maxLength: 200 }}
                                            onChange={(e) => setOrders({ ...orders, payment_details: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>

                                                        <Grid item xs={16} md={6} >
                                                            <label className="text-xs">Remarks</label>
                                                            <textarea
                                                                className="form-control mt-1"
                                                                id="outlined-basic"
                                                                placeholder="Remarks"
                                                                variant="outlined"
                                                                name="remarks"
                                                                multiline
                                                                rows={4}
                                                                value={remarks}
                                                                onChange={(e) => setRemarks(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={6}>
                                                            <label className="text-xs">Payment and other T&C's</label>
                                                            <RichTextEditor
                                                                value={paymentText}
                                                                onChange={onEditorChange}
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid item xs={12}>
                                                            <div className="invoice3-row">
                                                                {/* <h6>ACCOUNTING ENTRY</h6> */}
                                                                <Typography variant="h5" component="div" className="primary-text order-heading mb-2 mt-2">
                                                                Accounting Entry</Typography>
                                                                <div className="col-12">
                                                                    <table className="width-100 account-table-details-right" >
                                                                        <tr>
                                                                            <td><b>Ledger Name</b></td>
                                                                            <td align="right"><b>Debit </b></td>
                                                                            <td align="right"> <b>Credit </b></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{orderDetails[0]?.company_name} </td>
                                                                            <td align="right">
                                                                                        {IGST !== 0 ? Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            IGST).toFixed(2) - Math.round((ordersDetails.map(x => (Number(x.discount))).reduce((partialSum, a) => partialSum + a, 0))).toFixed(2)).toFixed(2) :
                                                                                            Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                SGST + CGST).toFixed(2) - Math.round((ordersDetails.map(x => (Number(x.discount))).reduce((partialSum, a) => partialSum + a, 0))).toFixed(2)).toFixed(2)}
                                                                                    </td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Discount </td>
                                                                            <td align="right">
                                                                                        {Math.round((ordersDetails.map(x => (Number(x.discount))).reduce((partialSum, a) => partialSum + a, 0))).toFixed(2)}
                                                                                    </td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                        {ordersDetails.map((x, index) => {
                                                                            return (
                                                                                <tr className="no-border">
                                                                                    <td>{x.matches.teams + " (" + x.ticket_category.category_name + ") " + " - " + formatDate2(x.matches.match_date)}</td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td align="right">{(Number(x.count) * Number(x.purchase_price)).toFixed(2)}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                        <tr>
                                                                            <td>Service Charge </td>
                                                                            <td>&nbsp;</td>
                                                                            <td align="right">{ordersDetails.map(x=>(Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                                                                        </tr>
                                                                        {IGST !== 0 ? (
                                                                            <tr>
                                                                                <td>IGST</td>
                                                                                <td>&nbsp;</td>
                                                                                <td align="right">{ordersDetails.map(x=>(Number(x.service_charge) * 18/100)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <>
                                                                                <tr>
                                                                                    <td>SGST</td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td align="right">{ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>CGST</td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td align="right">{ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                                                                                </tr>
                                                                            </>
                                                                        )}
                                                                        {/* Round off */}
                                                                            {(Math.round(Number(IGST !== 0 ? ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                IGST)) :
                                                                                ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                    ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                    SGST + CGST)))) - Number(Number(IGST !== 0 ? ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                        ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                        IGST)) :
                                                                                        ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            SGST + CGST)))).toFixed(2)) !== 0 && (
                                                                                    <tr>
                                                                                        <td>Round Off </td>
                                                                                        <td>&nbsp;</td>
                                                                                        <td align="right">{(Math.round(Number(IGST !== 0 ? ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            IGST)) :
                                                                                            ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                SGST + CGST)))) - Number(Number(IGST !== 0 ? ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                    ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                    IGST)) :
                                                                                                    ((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                        ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                        SGST + CGST)))).toFixed(2)).toFixed(2)}
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                <tr>
                                                                                    <td><b>Total </b></td>
                                                                                    <td align="right"><b>
                                                                                        {ordersDetails.map(x=>(Number(x.service_charge) * 18/100)).reduce((partialSum, a) => partialSum + a, 0) !== 0 ?
                                                                                        Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x=>(Number(x.service_charge) * 18/100)).reduce((partialSum, a) => partialSum + a, 0))).toFixed(2) :
                                                                                            Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toFixed(2)}
                                                                                    </b></td>
                                                                                    <td align="right"><b>
                                                                                        {ordersDetails.map(x=>(Number(x.service_charge) * 18/100)).reduce((partialSum, a) => partialSum + a, 0) !== 0 ?
                                                                                        Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                            ordersDetails.map(x=>(Number(x.service_charge) * 18/100)).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toFixed(2) :
                                                                                            Math.round((ordersDetails.map(x => (Number(x.service_charge))).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x => Number(x.count) * Number(x.purchase_price)).reduce((partialSum, a) => partialSum + a, 0) +
                                                                                                ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0) + 
                                                                                                ordersDetails.map(x=>(Number(x.service_charge) * 9/100)).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)).toFixed(2)}
                                                                                    </b></td>
                                                                                </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} ><Typography variant="h5" component="div" className="primary-text order-heading mt-2">
                                                        Upload support file</Typography></Grid>
                                                        <Grid item xs={12} md={4} >
                                                            <FormControl fullWidth >
                                                            <TextField
                                                                type="file"
                                                                className="form-control"
                                                                id="demo-simple-select"
                                                                placeholder="Upload file"
                                                                variant="outlined"
                                                                name="upload_file"
                                                                onChange={uploadFile}
                                                                // accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                inputProps={{ accept: "image/png, image/jpeg, image/jpeg, application/pdf, .doc, .docx" }}
                                                            />
                                                            </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={2} mx={2}>
                                                                <Button variant="contained" color="error" size="small" >Reset</Button>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <table border="1" width="100%" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Doc Type</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                    {supportFiles?.map((item,index)=>
                                                                    <tr>
                                                                       <td>{item.name}</td>
                                                                        <td>
                                                                            <FormControl fullWidth className='table-dropdown'>
                                                                                <TextField
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="demo-simple-select"
                                                                                    placeholder="Select"
                                                                                    variant="outlined"
                                                                                    name="doc_type"
                                                                                    select
                                                                                    value={item.docType}
                                                                                    onChange={(e)=> handleSupportFilesDocType(e,index)}
                                                                                >
                                                                                   <MenuItem value="">Select</MenuItem>
                                                                                    <MenuItem value="invoice">Invoice</MenuItem>
                                                                                    <MenuItem value="receipt">Receipt</MenuItem>
                                                                                    <MenuItem value="agreement">Agreement</MenuItem>
                                                                                    <MenuItem value="credit_note">Creditnote</MenuItem>

                                                                                </TextField>
                                                                            </FormControl>
                                                                        </td>
                                                                        <td>
                                                                        <ButtonGroup aria-label="Basic example">
                                                                            <Button variant="contained" color="primary" size="small"><DownloadIcon /></Button>
                                                                            <Button className='mx-3' variant="contained" color="secondary" size="small"><VisibilityIcon /></Button>
                                                                            <Button variant="contained" color="error" size="small" onClick={()=> onSupportFileDelete(index)}><DeleteIcon /></Button>
                                                                        </ButtonGroup>
                                                                        </td>
                                                                    </tr>
                                                                    )}
                                                                </thead>
                                                                </table>
                                                            </Grid>

                                                        <Grid item xs={12} md={12}>
                                                            <Box mt={3}>
                                                                <Button variant="contained" color="primary" onClick={() => onUpdate()}>Update</Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {/* <hr /> */}
                                    </CardContent>

                                </Box>


                            </Grid>
                        </Grid>
                        <Box pt={2} px={2} lineHeight={1.25}>
                        </Box>
                    </Card>
                ) :
                    (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress color="success" />
                    </Stack>)}

            {/* <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Clone Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={12} >
                            <label className="text-xs">Select Client</label>
                           <Autocomplete
                                className="inputFieldLocation"
                                size="small"
                                id="outlined-basic"
                                fullWidth
                                freeSolo
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                options={customerList}
                                value={orders.client}
                                getOptionLabel={(option) => option.company_name + `${(option.description !== null && option.description !== undefined && option.description !== "") ? " (" + option.description + ")" : ""}`}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.origin}>
                                        {option.company_name + `${(option.description !== null && option.description !== undefined && option.description !== "") ? " (" + option.description + ")" : ""}`}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        id="outlined-basic"
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        className="form-control mt-1"
                                        placeholder="Matches"
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    setOrders({ ...orders, client: newValue });
                                    setCustomerOrigin(newValue.origin)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="primary" className="btn-danger mx-3" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="contained" color="secondary" >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal> */}
                <Footer />
        </DashboardLayout>
    );
}

export default ViewOrders;
