export const countrylist = [
    { origin: '2', api_continent_list_fk: '3', name: 'Albania', country_code: '+355', iso_country_code: 'AL' },
    { origin: '3', api_continent_list_fk: '1', name: 'Algeria', country_code: '+213', iso_country_code: 'DZ' },
    { origin: '4', api_continent_list_fk: '6', name: 'American Samoa', country_code: '+1684', iso_country_code: 'AS' },
    { origin: '5', api_continent_list_fk: '3', name: 'Andorra', country_code: '+376', iso_country_code: 'AD' },
    { origin: '6', api_continent_list_fk: '1', name: 'Angola', country_code: '+244', iso_country_code: 'AO' },
    { origin: '7', api_continent_list_fk: '4', name: 'Anguilla', country_code: '+1264', iso_country_code: 'AI' },
    { origin: '8', api_continent_list_fk: '4', name: 'Antigua And Barbuda', country_code: '+1268', iso_country_code: 'AG' },
    { origin: '9', api_continent_list_fk: '5', name: 'Argentina', country_code: '+54', iso_country_code: 'AR' },
    { origin: '10', api_continent_list_fk: '2', name: 'Armenia', country_code: '+374', iso_country_code: 'AM' },
    { origin: '11', api_continent_list_fk: '4', name: 'Aruba', country_code: '+297', iso_country_code: 'AW' },
    { origin: '12', api_continent_list_fk: '6', name: 'Australia', country_code: '+61', iso_country_code: 'AU' },
    { origin: '13', api_continent_list_fk: '3', name: 'Austria', country_code: '+43', iso_country_code: 'AT' },
    { origin: '14', api_continent_list_fk: '2', name: 'Azerbaijan', country_code: '+994', iso_country_code: 'AZ' },
    { origin: '15', api_continent_list_fk: '4', name: 'Bahamas', country_code: '+1242', iso_country_code: 'BS' },
    { origin: '16', api_continent_list_fk: '2', name: 'Bahrain', country_code: '+973', iso_country_code: 'BH' },
    { origin: '17', api_continent_list_fk: '2', name: 'Bangladesh', country_code: '+880', iso_country_code: 'BD' },
    { origin: '18', api_continent_list_fk: '4', name: 'Barbados', country_code: '+1246', iso_country_code: 'BB' },
    { origin: '19', api_continent_list_fk: '3', name: 'Belarus', country_code: '+375', iso_country_code: 'BY' },
    { origin: '20', api_continent_list_fk: '3', name: 'Belgium', country_code: '+32', iso_country_code: 'BE' },
    { origin: '21', api_continent_list_fk: '4', name: 'Belize', country_code: '+501', iso_country_code: 'BZ' },
    { origin: '22', api_continent_list_fk: '1', name: 'Benin', country_code: '+229', iso_country_code: 'BJ' },
    { origin: '23', api_continent_list_fk: '4', name: 'Bermuda', country_code: '+1441', iso_country_code: 'BM' },
    { origin: '24', api_continent_list_fk: '2', name: 'Bhutan', country_code: '+975', iso_country_code: 'BT' },
    { origin: '25', api_continent_list_fk: '5', name: 'Bolivia', country_code: '+591', iso_country_code: 'BO' },
    { origin: '26', api_continent_list_fk: '3', name: 'Bosnia And Herzegovina', country_code: '+387', iso_country_code: 'BA' },
    { origin: '27', api_continent_list_fk: '1', name: 'Botswana', country_code: '+267', iso_country_code: 'BW' },
    { origin: '28', api_continent_list_fk: '5', name: 'Brazil', country_code: '+55', iso_country_code: 'BR' },
    { origin: '29', api_continent_list_fk: '2', name: 'Brunei Darussalam', country_code: '+673', iso_country_code: 'BN' },
    { origin: '30', api_continent_list_fk: '3', name: 'Bulgaria', country_code: '+359', iso_country_code: 'BG' },
    { origin: '31', api_continent_list_fk: '1', name: 'Burkina Faso', country_code: '+226', iso_country_code: 'BF' },
    { origin: '32', api_continent_list_fk: '1', name: 'Burundi', country_code: '+257', iso_country_code: 'BI' },
    { origin: '33', api_continent_list_fk: '2', name: 'Cambodia', country_code: '+855', iso_country_code: 'KH' },
    { origin: '34', api_continent_list_fk: '1', name: 'Cameroon', country_code: '+237', iso_country_code: 'CM' },
    { origin: '35', api_continent_list_fk: '4', name: 'Canada', country_code: '+1', iso_country_code: 'CA' },
    { origin: '36', api_continent_list_fk: '1', name: 'Cape Verde', country_code: '+238', iso_country_code: 'CV' },
    { origin: '37', api_continent_list_fk: '4', name: 'Cayman Islands', country_code: '+1345', iso_country_code: 'KY' },
    { origin: '38', api_continent_list_fk: '1', name: 'Central African Republic', country_code: '+236', iso_country_code: 'CF' },
    { origin: '39', api_continent_list_fk: '1', name: 'Chad', country_code: '+235', iso_country_code: 'TD' },
    { origin: '40', api_continent_list_fk: '5', name: 'Chile', country_code: '+56', iso_country_code: 'CL' },
    { origin: '41', api_continent_list_fk: '2', name: 'China', country_code: '+86', iso_country_code: 'CN' },
    { origin: '42', api_continent_list_fk: '2', name: 'Christmas Island', country_code: '+61', iso_country_code: 'CX' },
    { origin: '43', api_continent_list_fk: '2', name: 'Cocos (Keeling) Islands', country_code: '+891', iso_country_code: 'CC' },
    { origin: '44', api_continent_list_fk: '5', name: 'Colombia', country_code: '+57', iso_country_code: 'CO' },
    { origin: '45', api_continent_list_fk: '1', name: 'Comoros', country_code: '+269', iso_country_code: 'KM' },
    { origin: '46', api_continent_list_fk: '1', name: 'Congo', country_code: '+242', iso_country_code: 'CG' },
    { origin: '47', api_continent_list_fk: '1', name: 'Congo, DR Of The', country_code: '+243', iso_country_code: 'CO' },
    { origin: '48', api_continent_list_fk: '6', name: 'Cook Islands', country_code: '+682', iso_country_code: 'CK' },
    { origin: '49', api_continent_list_fk: '4', name: 'Costa Rica', country_code: '+506', iso_country_code: 'CR' },
    { origin: '50', api_continent_list_fk: '8', name: '', country_code: '0', iso_country_code: '' },
    { origin: '51', api_continent_list_fk: '3', name: 'Croatia', country_code: '+385', iso_country_code: 'HR' },
    { origin: '52', api_continent_list_fk: '2', name: 'Cyprus', country_code: '+357', iso_country_code: 'CY' },
    { origin: '53', api_continent_list_fk: '3', name: 'Czech Republic', country_code: '+420', iso_country_code: 'CZ' },
    { origin: '54', api_continent_list_fk: '3', name: 'Denmark', country_code: '+45', iso_country_code: 'DK' },
    { origin: '55', api_continent_list_fk: '1', name: 'Djibouti', country_code: '+253', iso_country_code: 'DJ' },
    { origin: '56', api_continent_list_fk: '4', name: 'Dominica', country_code: '+1767', iso_country_code: 'DM' },
    { origin: '57', api_continent_list_fk: '4', name: 'Dominican Republic', country_code: '+1809', iso_country_code: 'DO' },
    { origin: '58', api_continent_list_fk: '5', name: 'Ecuador', country_code: '+593', iso_country_code: 'EC' },
    { origin: '59', api_continent_list_fk: '1', name: 'Egypt', country_code: '+20', iso_country_code: 'EG' },
    { origin: '60', api_continent_list_fk: '4', name: 'El Salvador', country_code: '+503', iso_country_code: 'SV' },
    { origin: '61', api_continent_list_fk: '1', name: 'Equatorial Guinea', country_code: '+240', iso_country_code: 'GQ' },
    { origin: '62', api_continent_list_fk: '1', name: 'Eritrea', country_code: '+291', iso_country_code: 'ER' },
    { origin: '63', api_continent_list_fk: '3', name: 'Estonia', country_code: '+372', iso_country_code: 'EE' },
    { origin: '64', api_continent_list_fk: '1', name: 'Ethiopia', country_code: '+251', iso_country_code: 'ET' },
    { origin: '65', api_continent_list_fk: '5', name: 'Falkland Islands', country_code: '+500', iso_country_code: 'FK' },
    { origin: '66', api_continent_list_fk: '3', name: 'Faroe Islands', country_code: '+298', iso_country_code: 'FO' },
    { origin: '67', api_continent_list_fk: '6', name: 'Fiji', country_code: '+679', iso_country_code: 'FJ' },
    { origin: '68', api_continent_list_fk: '3', name: 'Finland', country_code: '+358', iso_country_code: 'FI' },
    { origin: '69', api_continent_list_fk: '3', name: 'France', country_code: '+33', iso_country_code: 'FR' },
    { origin: '70', api_continent_list_fk: '5', name: 'French Guiana', country_code: '+594', iso_country_code: 'GF' },
    { origin: '71', api_continent_list_fk: '6', name: 'French Polynesia', country_code: '+689', iso_country_code: 'PF' },
    { origin: '72', api_continent_list_fk: '1', name: 'Gabon', country_code: '+241', iso_country_code: 'GA' },
    { origin: '73', api_continent_list_fk: '1', name: 'Gambia', country_code: '+220', iso_country_code: 'GM' },
    { origin: '74', api_continent_list_fk: '2', name: 'Georgia', country_code: '+995', iso_country_code: 'GE' },
    { origin: '75', api_continent_list_fk: '3', name: 'Germany', country_code: '+49', iso_country_code: 'DE' },
    { origin: '76', api_continent_list_fk: '1', name: 'Ghana', country_code: '+233', iso_country_code: 'GH' },
    { origin: '77', api_continent_list_fk: '3', name: 'Gibralter', country_code: '+350', iso_country_code: 'GI' },
    { origin: '78', api_continent_list_fk: '3', name: 'Greece', country_code: '+30', iso_country_code: 'GR' },
    { origin: '79', api_continent_list_fk: '4', name: 'Greenland', country_code: '+299', iso_country_code: 'GL' },
    { origin: '80', api_continent_list_fk: '4', name: 'Grenada', country_code: '+1473', iso_country_code: 'GD' },
    { origin: '81', api_continent_list_fk: '4', name: 'Guadeloupe', country_code: '+590', iso_country_code: 'GP' },
    { origin: '82', api_continent_list_fk: '6', name: 'Guam', country_code: '+1671', iso_country_code: 'GU' },
    { origin: '83', api_continent_list_fk: '4', name: 'Guatemala', country_code: '+502', iso_country_code: 'GT' },
    { origin: '84', api_continent_list_fk: '1', name: 'Guinea', country_code: '+224', iso_country_code: 'GN' },
    { origin: '85', api_continent_list_fk: '1', name: 'Guinea-Bissau', country_code: '+245', iso_country_code: 'GW' },
    { origin: '86', api_continent_list_fk: '5', name: 'Guyana', country_code: '+592', iso_country_code: 'GY' },
    { origin: '87', api_continent_list_fk: '4', name: 'Haiti', country_code: '+509', iso_country_code: 'HT' },
    { origin: '88', api_continent_list_fk: '4', name: 'Honduras', country_code: '+504', iso_country_code: 'HN' },
    { origin: '89', api_continent_list_fk: '2', name: 'Hong Kong', country_code: '+852', iso_country_code: 'HK' },
    { origin: '90', api_continent_list_fk: '3', name: 'Hungary', country_code: '+36', iso_country_code: 'HU' },
    { origin: '91', api_continent_list_fk: '3', name: 'Iceland', country_code: '+354', iso_country_code: 'IS' },
    { origin: '92', api_continent_list_fk: '2', name: 'India', country_code: '+91', iso_country_code: 'IN' },
    { origin: '93', api_continent_list_fk: '2', name: 'Indonesia', country_code: '+62', iso_country_code: 'ID' },
    { origin: '94', api_continent_list_fk: '3', name: 'Ireland', country_code: '+353', iso_country_code: 'IE' },
    { origin: '95', api_continent_list_fk: '2', name: 'Israel', country_code: '+972', iso_country_code: 'IL' },
    { origin: '96', api_continent_list_fk: '3', name: 'Italy', country_code: '+39', iso_country_code: 'IT' },
    { origin: '97', api_continent_list_fk: '4', name: 'Jamaica', country_code: '+1876', iso_country_code: 'JM' },
    { origin: '98', api_continent_list_fk: '2', name: 'Japan', country_code: '+81', iso_country_code: 'JP' },
    { origin: '99', api_continent_list_fk: '2', name: 'Jordan', country_code: '+962', iso_country_code: 'JO' },
    { origin: '100', api_continent_list_fk: '2', name: 'Kazakhstan', country_code: '+7', iso_country_code: 'KZ' },
    { origin: '101', api_continent_list_fk: '1', name: 'Kenya', country_code: '+254', iso_country_code: 'KE' },
    { origin: '102', api_continent_list_fk: '6', name: 'Kiribati', country_code: '+686', iso_country_code: 'KI' },
    { origin: '103', api_continent_list_fk: '2', name: 'Korea, DPR Of', country_code: '+850', iso_country_code: '' },
    { origin: '104', api_continent_list_fk: '2', name: 'Korea, Republic Of', country_code: '+82', iso_country_code: '' },
    { origin: '105', api_continent_list_fk: '2', name: 'Kuwait', country_code: '+965', iso_country_code: 'KW' },
    { origin: '106', api_continent_list_fk: '2', name: 'Kyrgyzstan', country_code: '+996', iso_country_code: 'KG' },
    { origin: '107', api_continent_list_fk: '2', name: 'Laos', country_code: '+856', iso_country_code: '' },
    { origin: '108', api_continent_list_fk: '3', name: 'Latvia', country_code: '+371', iso_country_code: 'LV' },
    { origin: '109', api_continent_list_fk: '2', name: 'Lebanon', country_code: '+961', iso_country_code: 'LB' },
    { origin: '110', api_continent_list_fk: '1', name: 'Lesotho', country_code: '+266', iso_country_code: 'LS' },
    { origin: '111', api_continent_list_fk: '1', name: 'Libyan Arab Jamahiriya', country_code: '+218', iso_country_code: 'LB' },
    { origin: '112', api_continent_list_fk: '3', name: 'Liechtenstein', country_code: '+423', iso_country_code: 'LI' },
    { origin: '113', api_continent_list_fk: '3', name: 'Lithuania', country_code: '+370', iso_country_code: 'LT' },
    { origin: '114', api_continent_list_fk: '3', name: 'Luxembourg', country_code: '+352', iso_country_code: 'LU' },
    { origin: '115', api_continent_list_fk: '2', name: 'Macao', country_code: '+853', iso_country_code: 'MO' },
    { origin: '116', api_continent_list_fk: '3', name: 'Macedonia, FYR Of', country_code: '+389', iso_country_code: 'MK' },
    { origin: '117', api_continent_list_fk: '1', name: 'Madagascar', country_code: '+261', iso_country_code: 'MG' },
    { origin: '118', api_continent_list_fk: '1', name: 'Malawi', country_code: '+265', iso_country_code: 'MW' },
    { origin: '119', api_continent_list_fk: '2', name: 'Malaysia', country_code: '+60', iso_country_code: 'MY' },
    { origin: '120', api_continent_list_fk: '2', name: 'Maldives', country_code: '+960', iso_country_code: 'MV' },
    { origin: '121', api_continent_list_fk: '1', name: 'Mali', country_code: '+223', iso_country_code: 'ML' },
    { origin: '122', api_continent_list_fk: '3', name: 'Malta', country_code: '+356', iso_country_code: 'MT' },
    { origin: '123', api_continent_list_fk: '6', name: 'Marshall Islands', country_code: '+692', iso_country_code: 'MH' },
    { origin: '124', api_continent_list_fk: '4', name: 'Martinique', country_code: '+596', iso_country_code: 'MQ' },
    { origin: '125', api_continent_list_fk: '1', name: 'Mauritania', country_code: '+222', iso_country_code: 'MR' },
    { origin: '126', api_continent_list_fk: '1', name: 'Mauritius', country_code: '+230', iso_country_code: 'MU' },
    { origin: '127', api_continent_list_fk: '1', name: 'Mayotte', country_code: '+262', iso_country_code: 'YT' },
    { origin: '128', api_continent_list_fk: '4', name: 'Mexico', country_code: '+52', iso_country_code: 'MX' },
    { origin: '129', api_continent_list_fk: '6', name: 'Micronesia, FS Of', country_code: '+691', iso_country_code: 'FS' },
    { origin: '130', api_continent_list_fk: '3', name: 'Moldova, Republic Of', country_code: '+373', iso_country_code: '' },
    { origin: '131', api_continent_list_fk: '3', name: 'Monaco', country_code: '+377', iso_country_code: 'MC' },
    { origin: '132', api_continent_list_fk: '2', name: 'Mongolia', country_code: '+976', iso_country_code: 'MN' },
    { origin: '133', api_continent_list_fk: '3', name: 'Montenegro', country_code: '+382', iso_country_code: 'ME' },
    { origin: '134', api_continent_list_fk: '4', name: 'Montserrat', country_code: '+1664', iso_country_code: 'MS' },
    { origin: '135', api_continent_list_fk: '1', name: 'Morocco', country_code: '+212', iso_country_code: 'MA' },
    { origin: '136', api_continent_list_fk: '1', name: 'Mozambique', country_code: '+258', iso_country_code: 'MZ' },
    { origin: '137', api_continent_list_fk: '2', name: 'Myanmar', country_code: '+95', iso_country_code: 'MM' },
    { origin: '138', api_continent_list_fk: '1', name: 'Namibia', country_code: '+264', iso_country_code: 'NA' },
    { origin: '139', api_continent_list_fk: '6', name: 'Nauru', country_code: '+674', iso_country_code: 'NR' },
    { origin: '140', api_continent_list_fk: '2', name: 'Nepal', country_code: '+977', iso_country_code: 'NP' },
    { origin: '141', api_continent_list_fk: '3', name: 'Netherlands', country_code: '+31', iso_country_code: 'NL' },
    { origin: '142', api_continent_list_fk: '6', name: 'New Caledonia', country_code: '+687', iso_country_code: 'NC' },
    { origin: '143', api_continent_list_fk: '6', name: 'New Zealand', country_code: '+64', iso_country_code: 'NZ' },
    { origin: '144', api_continent_list_fk: '4', name: 'Nicaragua', country_code: '+505', iso_country_code: 'NI' },
    { origin: '145', api_continent_list_fk: '1', name: 'Niger', country_code: '+227', iso_country_code: 'NE' },
    { origin: '146', api_continent_list_fk: '1', name: 'Nigeria', country_code: '+234', iso_country_code: 'NG' },
    { origin: '147', api_continent_list_fk: '6', name: 'Niue', country_code: '+683', iso_country_code: 'NU' },
    { origin: '148', api_continent_list_fk: '6', name: 'Norfolk Island', country_code: '+672', iso_country_code: 'NF' },
    { origin: '149', api_continent_list_fk: '6', name: 'Northern Mariana Islands', country_code: '+1670', iso_country_code: 'MP' },
    { origin: '150', api_continent_list_fk: '3', name: 'Norway', country_code: '+47', iso_country_code: 'NO' },
    { origin: '151', api_continent_list_fk: '2', name: 'Oman', country_code: '+968', iso_country_code: 'OM' },
    { origin: '152', api_continent_list_fk: '2', name: 'Pakistan', country_code: '+92', iso_country_code: 'PK' },
    { origin: '153', api_continent_list_fk: '6', name: 'Palau', country_code: '+680', iso_country_code: 'PW' },
    { origin: '154', api_continent_list_fk: '4', name: 'Panama', country_code: '+507', iso_country_code: 'PA' },
    { origin: '155', api_continent_list_fk: '6', name: 'Papua New Guinea', country_code: '+675', iso_country_code: 'PG' },
    { origin: '156', api_continent_list_fk: '5', name: 'Paragua', country_code: '+595 ', iso_country_code: 'PY' },
    { origin: '157', api_continent_list_fk: '5', name: 'Paraguay', country_code: '+595', iso_country_code: 'PY' },
    { origin: '158', api_continent_list_fk: '5', name: 'Peru', country_code: '+51', iso_country_code: 'PE' },
    { origin: '159', api_continent_list_fk: '2', name: 'Philippines', country_code: '+63', iso_country_code: 'PH' },
    { origin: '160', api_continent_list_fk: '6', name: 'Pitcairn', country_code: '+870', iso_country_code: 'PN' },
    { origin: '161', api_continent_list_fk: '3', name: 'Polan', country_code: '+48', iso_country_code: 'PL' },
    { origin: '162', api_continent_list_fk: '3', name: 'Poland', country_code: '+48', iso_country_code: 'PL' },
    { origin: '163', api_continent_list_fk: '3', name: 'Portugal', country_code: '+351', iso_country_code: 'PT' },
    { origin: '164', api_continent_list_fk: '4', name: 'Puerto Rico', country_code: '+1787', iso_country_code: 'PR' },
    { origin: '165', api_continent_list_fk: '2', name: 'Qatar', country_code: '+974', iso_country_code: 'QA' },
    { origin: '166', api_continent_list_fk: '1', name: 'Reunion', country_code: '+262 ', iso_country_code: 'RE' },
    { origin: '167', api_continent_list_fk: '3', name: 'Romania', country_code: '+40', iso_country_code: 'RO' },
    { origin: '168', api_continent_list_fk: '3', name: 'Russian Federation', country_code: '+7', iso_country_code: 'RU' },
    { origin: '169', api_continent_list_fk: '1', name: 'Rwanda', country_code: '+250', iso_country_code: 'RW' },
    { origin: '170', api_continent_list_fk: '4', name: 'Saint Barthelemy', country_code: '+590', iso_country_code: 'BL' },
    { origin: '171', api_continent_list_fk: '4', name: 'Saint Kitts And Nevis', country_code: '+1869', iso_country_code: 'KN' },
    { origin: '172', api_continent_list_fk: '4', name: 'Saint Lucia', country_code: '+1758', iso_country_code: 'LC' },
    { origin: '173', api_continent_list_fk: '4', name: 'Saint Martin', country_code: '+1599', iso_country_code: '' },
    { origin: '174', api_continent_list_fk: '6', name: 'Samoa', country_code: '+685', iso_country_code: 'WS' },
    { origin: '175', api_continent_list_fk: '3', name: 'San Marino', country_code: '+378', iso_country_code: 'SM' },
    { origin: '176', api_continent_list_fk: '1', name: 'Sao Tome And Principe', country_code: '+239', iso_country_code: 'ST' },
    { origin: '177', api_continent_list_fk: '2', name: 'Saudi Arabia', country_code: '+966', iso_country_code: 'SA' },
    { origin: '178', api_continent_list_fk: '1', name: 'Senegal', country_code: '+221', iso_country_code: 'SN' },
    { origin: '179', api_continent_list_fk: '3', name: 'Serbia', country_code: '+381', iso_country_code: 'RS' },
    { origin: '180', api_continent_list_fk: '1', name: 'Seychelles', country_code: '+248', iso_country_code: 'SC' },
    { origin: '181', api_continent_list_fk: '1', name: 'Sierra Leone', country_code: '+232', iso_country_code: 'SL' },
    { origin: '182', api_continent_list_fk: '2', name: 'Singapore', country_code: '+65', iso_country_code: 'SG' },
    { origin: '183', api_continent_list_fk: '3', name: 'Slovakia (Slovak Republic)', country_code: '+421 ', iso_country_code: 'SK' },
    { origin: '184', api_continent_list_fk: '3', name: 'Slovenia', country_code: '+386', iso_country_code: 'SI' },
    { origin: '185', api_continent_list_fk: '6', name: 'Solomon Islands', country_code: '+677', iso_country_code: 'SB' },
    { origin: '186', api_continent_list_fk: '1', name: 'South Africa', country_code: '+27', iso_country_code: 'ZA' },
    { origin: '187', api_continent_list_fk: '3', name: 'Spain', country_code: '+34', iso_country_code: 'ES' },
    { origin: '188', api_continent_list_fk: '2', name: 'Sri Lanka', country_code: '+94', iso_country_code: 'LK' },
    { origin: '189', api_continent_list_fk: '4', name: 'St Vincent And The Grenadines', country_code: '+1784', iso_country_code: '' },
    { origin: '190', api_continent_list_fk: '1', name: 'St. Helena', country_code: '+290', iso_country_code: '' },
    { origin: '191', api_continent_list_fk: '4', name: 'St. Pierre And Miquelon', country_code: '+508', iso_country_code: 'PM' },
    { origin: '192', api_continent_list_fk: '1', name: 'Sudan', country_code: '+249', iso_country_code: 'SD' },
    { origin: '193', api_continent_list_fk: '5', name: 'Suriname', country_code: '+597', iso_country_code: 'SR' },
    { origin: '194', api_continent_list_fk: '3', name: 'Svalbard And Jan Mayen Islands', country_code: '+47 ', iso_country_code: 'SJ' },
    { origin: '195', api_continent_list_fk: '1', name: 'Swaziland', country_code: '+268', iso_country_code: 'SZ' },
    { origin: '196', api_continent_list_fk: '3', name: 'Sweden', country_code: '+46', iso_country_code: 'SE' },
    { origin: '197', api_continent_list_fk: '3', name: 'Switzerland', country_code: '+41', iso_country_code: 'CH' },
    { origin: '198', api_continent_list_fk: '2', name: 'Taiwan', country_code: '+886', iso_country_code: 'TW' },
    { origin: '199', api_continent_list_fk: '2', name: 'Tajikistan', country_code: '+992', iso_country_code: 'TJ' },
    { origin: '200', api_continent_list_fk: '1', name: 'Tanzania', country_code: '+255', iso_country_code: 'TZ' },
    { origin: '201', api_continent_list_fk: '2', name: 'Thailand', country_code: '+66', iso_country_code: 'TH' },
    { origin: '202', api_continent_list_fk: '1', name: 'Togo', country_code: '+228', iso_country_code: 'TG' },
    { origin: '203', api_continent_list_fk: '6', name: 'Tonga', country_code: '+676', iso_country_code: 'TO' },
    { origin: '204', api_continent_list_fk: '4', name: 'Trinidad And Tobago', country_code: '+1868', iso_country_code: 'TT' },
    { origin: '205', api_continent_list_fk: '1', name: 'Tunisia', country_code: '+216', iso_country_code: 'TN' },
    { origin: '206', api_continent_list_fk: '2', name: 'Turkey', country_code: '+90', iso_country_code: 'TR' },
    { origin: '207', api_continent_list_fk: '2', name: 'Turkmenistan', country_code: '+993', iso_country_code: 'TM' },
    { origin: '208', api_continent_list_fk: '4', name: 'Turks And Caicos Islands', country_code: '+1649', iso_country_code: 'TC' },
    { origin: '209', api_continent_list_fk: '6', name: 'Tuvalu', country_code: '+688', iso_country_code: 'TV' },
    { origin: '210', api_continent_list_fk: '1', name: 'Uganda', country_code: '+256', iso_country_code: 'UG' },
    { origin: '211', api_continent_list_fk: '3', name: 'Ukraine', country_code: '+380', iso_country_code: 'UA' },
    { origin: '212', api_continent_list_fk: '2', name: 'United Arab Emirates', country_code: '+971', iso_country_code: 'AE' },
    { origin: '213', api_continent_list_fk: '3', name: 'United Kingdom', country_code: '+44', iso_country_code: 'GB' },
    { origin: '214', api_continent_list_fk: '4', name: 'United States', country_code: '+1', iso_country_code: 'US' },
    { origin: '215', api_continent_list_fk: '5', name: 'Uruguay', country_code: '+598', iso_country_code: 'UY' },
    { origin: '216', api_continent_list_fk: '6', name: 'US Minor Outlying Islands', country_code: '+1 ', iso_country_code: '' },
    { origin: '217', api_continent_list_fk: '2', name: 'Uzbekistan', country_code: '+998', iso_country_code: 'UZ' },
    { origin: '218', api_continent_list_fk: '6', name: 'Vanuatu', country_code: '+678', iso_country_code: 'VU' },
    { origin: '219', api_continent_list_fk: '3', name: 'Vatican City State', country_code: '+379', iso_country_code: '' },
    { origin: '220', api_continent_list_fk: '5', name: 'Venezuela', country_code: '+58', iso_country_code: 'VE' },
    { origin: '221', api_continent_list_fk: '2', name: 'Viet Nam', country_code: '+84', iso_country_code: 'VN' },
    { origin: '222', api_continent_list_fk: '4', name: 'Virgin Islands - British', country_code: '+1284', iso_country_code: '' },
    { origin: '223', api_continent_list_fk: '4', name: 'Virgin Islands - U.S.', country_code: '+1340', iso_country_code: 'VI' },
    { origin: '224', api_continent_list_fk: '6', name: 'Wallis And Futuna Islands', country_code: '+681', iso_country_code: 'WF' },
    { origin: '225', api_continent_list_fk: '2', name: 'Yemen', country_code: '+967', iso_country_code: 'YE' },
    { origin: '226', api_continent_list_fk: '1', name: 'Zambia', country_code: '+260', iso_country_code: 'ZM' },
    { origin: '227', api_continent_list_fk: '1', name: 'Zimbabwe', country_code: '+263', iso_country_code: 'ZW' },
    { origin: '228', api_continent_list_fk: '2', name: 'Afghanistan', country_code: '+93', iso_country_code: 'AF' },
    { origin: '229', api_continent_list_fk: '3', name: 'Yugoslavia', country_code: '+381', iso_country_code: 'YO' }
  ]
  