import React,  { useState, useEffect } from "react";
import {Container, Card, Box, TextField, MenuItem, AppBar, Tabs,
         Tab, Icon, Select, FormHelperText, InputLabel, FormControl} from "@mui/material";
import { Button, Row, Col } from 'react-bootstrap';
import Grid from "@mui/material/Grid";
import "../../pages/account/account.css"

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import profileIcon from "assets/images/icons/test-account.png";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { nameValidator, emailValidator, phoneValidator, countryCodeValidator } from "Validation";
import API from "apiConfig";


function Profile() {    
    const [profileDetails, setProfileDetails] = useState({
        title: null, firstName: null,
        lastName: null, emailId: null,
        phoneNumber: null, countryCode: null,
    });
    const [errorDetails, setErrorDetails] = useState({
        title: null, firstName: null,
        lastName: null, emailId: null,
        phoneNumber: null, countryCode: null,
    });

    useEffect(() => {
       getProfileDetails();
      },[]);
    
    //function to get profile details
    const getProfileDetails = async () => {
        try {
            const response = await API.get('login/account');
            if (response.data.status) {
                // console.log("Response",response.data.data.form_data);
                let data = response.data.data.form_data;
                setProfileDetails({
                    ...profileDetails,
                    title: data.title,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    emailId: data.email,
                    countryCode: data.country_code,
                    phoneNumber: data.phone
                })
            }
            else {
                console.log("Error")
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    } 

    const handleInputChange = (e) => {
        setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
    }

    const checkValidation = () => {
        let titleError = nameValidator(profileDetails.title);
        let firstNameError = nameValidator(profileDetails.firstName);
        let lastNameError = nameValidator(profileDetails.lastName);
        let phoneNumberError = phoneValidator(profileDetails.phoneNumber);
        let countryCode = countryCodeValidator(profileDetails.countryCode);
        if (titleError || firstNameError || lastNameError || phoneNumberError || countryCode ) {
            setErrorDetails({
                ...errorDetails,
                title: titleError,
                firstName: firstNameError,
                lastName: lastNameError,
                phoneNumber: phoneNumberError,
                countryCode: countryCode,
              
            })
            return;
        }
        else {
            updateProfileDetails();
        }
    }

     //function to update profile details
    const updateProfileDetails = async() => {
        let dataToSend = {
            "first_name": profileDetails.firstName,
            "last_name": profileDetails.lastName,
            "title": profileDetails.title,
            "country_code": profileDetails.countryCode,
            "phone": profileDetails.phoneNumber
        }
        console.log("dataToSend", dataToSend);
        const response = await API.post('login/update_account', dataToSend);
        if (response.data.status) {
             alert(`Profile Updated Successfully`);
             window.location.reload();
        }
        else {
            console.log("Error")
        }
      }

    const handlePickNumeric = (e) => {
        setErrorDetails({ ...errorDetails, phoneNumber: null });
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      setProfileDetails({ ...userDetails, phoneNumber: onlyNums })
    } else if (onlyNums.length === 10) {
      const number = onlyNums;
      setProfileDetails({ ...userDetails, phoneNumber: number })
    }
    }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mb={2} />
      <Box position="relative" mb={5}>
        <Box
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="18.75rem"
            borderRadius="xl"
            sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
                )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
            }}
        />
        <Card
            sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
            }}
        >
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <img className="profile-img" src={profileIcon} alt="profile-image" size="xl" shadow="sm" />
                </Grid>
                <Grid item>
                    <Box height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        AdWeb Admin
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                        Admin
                    </MDTypography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                    <AppBar position="static">
                    <Tabs >
                        <Tab
                        label="App"
                        icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            home
                            </Icon>
                        }
                        />
                        <Tab
                        label="Message"
                        icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            email
                            </Icon>
                        }
                        />
                        <Tab
                        label="Settings"
                        icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            settings
                            </Icon>
                        }
                        />
                    </Tabs>
                    </AppBar>
                </Grid>
                
                <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
                <hr className="my-0" />
                    <Card sx={{ boxShadow: "none" }}>
                        <Box p={2}>
                            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                Profile Details
                            </MDTypography>
                        </Box>
                        <Box px={2} >
                            <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                Personal Information
                            </MDTypography>
                        </Box>

                        <Box className="box-container pt-1">
                            <Box component="form" noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={2} >
                                        <label className="text-xs">Select Title</label>
                                        <FormControl fullWidth>
                                            <InputLabel className="overflow-visible mt-1" id="demo-simple-select-label">Select Title</InputLabel>
                                            <Select
                                                className="mt-1"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Select Title"
                                                name="title"
                                                onChange={handleInputChange}
                                                error={errorDetails.title}
                                                value={profileDetails.title}
                                            >
                                                <MenuItem value="Miss">Miss</MenuItem>
                                                <MenuItem value="Mr">Mr</MenuItem>
                                                <MenuItem value="Mrs">Mrs</MenuItem>
                                            </Select>
                                            <FormHelperText error={errorDetails.title}>{errorDetails.title}</FormHelperText>
                                        </FormControl>

                                    </Col>
                                    <Col item xs={12} md={5} >
                                        <label className="text-xs">First Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="First Name"
                                            variant="outlined"
                                            name="firstName"
                                            onChange={handleInputChange}
                                            value={profileDetails.firstName}
                                            error={errorDetails.firstName}
                                            helperText={errorDetails.firstName}
                                        />
                                    </Col>

                                    <Col item xs={12} md={5}>
                                        <label className="text-xs">Last Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Last Name"
                                            variant="outlined"
                                            name="lastName"
                                            onChange={handleInputChange}
                                            value={profileDetails.lastName}
                                            error={errorDetails.lastName}
                                            helperText={errorDetails.lastName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col item xs={12} md={6} >
                                        <label className="text-xs">Email ID</label>
                                        <TextField
                                            type="email"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Email ID"
                                            variant="outlined"
                                            name="emailId"
                                            disabled
                                            onChange={handleInputChange}
                                            value={profileDetails.emailId}
                                            error={errorDetails.emailId}
                                            helperText={errorDetails.emailId}
                                        />
                                    </Col>
                                    <Col item xs={12} md={2} >
                                        <label className="text-xs">Country Code</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Country Code"
                                            variant="outlined"
                                            name="countryCode"
                                            onChange={handleInputChange}
                                            value={profileDetails.countryCode}
                                            error={errorDetails.countryCode}
                                            helperText={errorDetails.countryCode}
                                        />
                                    </Col>
                                    <Col item xs={12} md={4}>
                                        <label className="text-xs">Phone Number</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Phone Number"
                                            variant="outlined"
                                            name="phoneNumber"
                                            onChange={handlePickNumeric}
                                            value={profileDetails.phoneNumber}
                                            error={errorDetails.phoneNumber}
                                            helperText={errorDetails.phoneNumber}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3 pt-2 text-right">
                                    <Box>
                                        <Button className="btn btn-primary" onClick={() => checkValidation()}>Update</Button>
                                    </Box>
                                </Row>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
           
        </Card>

        </Box>
        <Box>
       
        </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
