import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Grid, Box, Card, LinearProgress, Menu, FormControl, TextField, MenuItem, Button, Autocomplete } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

// Data
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import Modal from 'react-bootstrap/Modal';

function Tournament() {
  const resultPerPage = 20;
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBox, setSearchBox] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [selectedRef, setSelectedRef] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [searchOrders, setSearchOrders] = useState({
    tournamentName: "", startDate: "", endDate: ""
  });
  const [orders, setOrders] = useState({
    tournamentName: "", startDate: "", endDate: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    tournamentName: null, startDate: null, endDate: null
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [show, setShow] = useState(false);

  const handleCloseModal = () => {
      setShow(false); 
      setIsUpdate(false);
      setSelectedOrigin(null);
      setOrders({stadium: "", city: "", category: ""});
    };
  const handleShowModal = () => 
  {
    setShow(true);
    setAnchorEl(null);
  }

  const handleClick = (event, origin, invoice_no) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrigin(origin);
    setInvoiceNumber(invoice_no);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setSearchBox(!searchBox)
  }

  const handleSearchInputChange = (e) => {
    setSearchOrders({ ...searchOrders, [e.target.name]: e.target.value });
  }

  const handleInputChange = (e) => {
    setOrders({ ...orders, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  useEffect(() => {
    getTournamentDetails();
    // getMatchList();
    // getTicketCategoryList();
  }, []);
  //console.log("ordersDetails",ordersDetails)
  //function to get brand details
  const getTournamentDetails = async (pageNo, origin) => {
    setIsLoading(true);
    let tournamentName = searchOrders.tournamentName;
    let startDate = searchOrders.startDate;
    let endDate = searchOrders.endDate;
    let eachOrigin = origin ? origin : "";

    const response = await API.get(`inventory/get_tournaments?name=${tournamentName}&start_date=${startDate}&end_date=${endDate}&origin`)
     console.log("Response",response?.data?.data?.data)
    if (response?.data?.status && response?.data?.data?.data) {
     // let total_records = response.data.data.total_records;
     // setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
    // setCurrentPage(activePageIndex);
      setOrdersDetails(response.data.data.data);
      if (isInitial) {
        setInitialData(response.data.data.data);
        setIsInitial(false);
      }
      setIsLoading(false);
      setErrorMsg("")
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
      setErrorMsg(response.data.message);
    }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  const getSingleTournamentDetails = async (pageNo, origin) => {
    let tournamentName = searchOrders.tournamentName;
    let startDate = searchOrders.startDate;
    let endDate = searchOrders.endDate;
    let eachOrigin = origin ? origin : "";

    const response = await API.get(`inventory/get_tournaments?name=${tournamentName}&start_date=${startDate}&end_date=${endDate}&origin=${eachOrigin}`)
    if (response?.data?.status && response?.data?.data?.data) {
      console.log("Response",response.data)
      let item = response.data.data.data[0];
      setOrders({
        ...orders,
        tournamentName: item.name,
        startDate: item.start_date,
        endDate: item.end_date
      })
      setIsLoading(false);
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
      setErrorMsg(response.data.message);
    }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${ye}-${mo}-${da}`
    return newDate;
  }

  function formatDate2(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

const handleEachTournament = (item) => {
  getSingleTournamentDetails(0, selectedOrigin);
  setSelectedOrigin(item.origin);
  handleShowModal();
  setIsUpdate(true);
}

  const checkValidation = () => {

    let tournamentErr = orders.tournamentName === "" ? "Please enter Tournament" : null;
    let startDateErr = orders.startDate === "" ? "Please select start date" : null;
    let endDateErr = orders.endDate === "" ? "Please select end date" : null;
   // let emailErr = emailValidator(email);
    if (tournamentErr || startDateErr || endDateErr) {
        setErrorDetails({...errorDetails,
                          tournamentName: tournamentErr,
                          startDate: startDateErr,
                          endDate: endDateErr});
        return;
    }
    else {
     // console.log("Tournament Details", orders)
      if(isUpdate){
        onUpdateTournament();
      }
      else{
        onCreateTournament();
      } 
    }
  }

  const onCreateTournament = () => {
    let dataToSend = {
      "name": orders.tournamentName,
      "start_date": orders.startDate,
      "end_date": orders.endDate
    }
    console.log("dataToSend",dataToSend)
    setIsLoading(true);
    API.post('inventory/create_tournament', dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                window.location.reload();
                setIsLoading(false);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
  }

  const onUpdateTournament = () => {
    let dataToSend = {
      "name": orders.tournamentName,
      "start_date": orders.startDate,
      "end_date": orders.endDate
    }
    // console.log("dataToSend",dataToSend);
    // console.log("selectedOrigin",selectedOrigin)
    setIsLoading(true);
    API.post(`inventory/update_tournament/${selectedOrigin}`, dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                window.location.reload();
                setIsLoading(false);
                setIsUpdate(false);
                setSelectedOrigin(null);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
  }

  const onClearFilter = () => {
    setOrdersDetails(initialData);
    setSearchOrders({
      ...searchOrders,
      tournamentName: "",
      startDate: "",
      endDate: ""
    })
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
          <Box>
            <Box className="text-right" px={2}>
              <Button variant="contained" color="primary" onClick={toggleSearch}>Advanced Search</Button>&nbsp; 
              <Button  variant="contained" color="secondary" onClick={handleShowModal}>Add Tournament</Button>
            </Box>
            {searchBox ? (
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="mt-3"><h5>Search By</h5></Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Tournament Name </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Select Tournament Name"
                    variant="outlined"
                    name="tournamentName"
                    value={searchOrders.tournamentName}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Start Date </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Select Start Date"
                    variant="outlined"
                    name="startDate"
                    type={'date'}
                    value={searchOrders.startDate}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">End Date </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="End Date"
                    variant="outlined"
                    name="endDate"
                    type={'date'}
                    value={searchOrders.endDate}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} className='filter-search'>
                  <Button variant="contained" color="primary" onClick={() => getTournamentDetails()}>Search</Button>
                  <Button variant="contained" color="secondary" onClick={() => onClearFilter()} className="clear-btn">Clear Filter</Button>
                </Grid>
              </Grid>
            ) : " "}
          </Box>
          <Box pt={6} pb={3} className="order-table">

            <Grid container spacing={6}>
              <Grid item xs={12}>

                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Tournament List
                    </MDTypography>
                  </MDBox>
                  {(!isLoading && ordersDetails.length > 0) ?
                    <Box>
                      <DataTable
                        entriesPerPage={false}
                        pagination={false}
                        showTotalEntries={false}
                        table={{
                          columns: [
                            { Header: "action", accessor: "action", align: "center" },
                            { Header: "Tournament Name", accessor: "t_name" },
                            { Header: "Start Date", accessor: "start_date" },
                            { Header: "End Date", accessor: "end_date" },
                          ],
                          rows:
                            ordersDetails.map(item => (
                              {

                                action: (
                                  <>
                                    {/* <Button
                                      className="text-primary"
                                      id="demo-positioned-button"
                                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                      onClick={()=> handleEachTicketCategory(item)}
                                    >
                                      <MoreVertIcon />
                                    </Button> */}
                                    <Button
                                      className="text-primary"
                                      id="demo-positioned-button"
                                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                      onClick={(e) => handleClick(e, item.origin, item.invoice_no)}
                                    >
                                      <MoreVertIcon />
                                    </Button>
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    >
                                       <a onClick={() => handleEachTournament(item)}><MenuItem>
                                       Edit
                                      </MenuItem></a>
                                      <Link to={'/view-tournament?'+ selectedOrigin} target="_blank"><MenuItem>View</MenuItem></Link>
                                    
                                    </Menu>
                                  </>
                                  //   <Link to={'/view-orders?'+ item.order_ref_no}>
                                  //     <Tooltip title="Edit" arrow>
                                  //         <Button size="small" variant="contained" color="primary">
                                  //             <ModeOutlinedIcon />
                                  //         </Button>
                                  //     </Tooltip>
                                  // </Link>
                                ),
                                t_name: item.name,
                                start_date: item.start_date,
                                end_date: item.end_date,
                                
                              }
                            ))
                        }}
                      />

                      {totalRecords > 1 && (
                        <Box className="py-3 mb-3" >
                          <Stack spacing={2} alignItems="end">
                            <Pagination className="pagination" page={currentPage} count={totalRecords} onChange={(e, index) => getTournamentDetails(index)} color="primary" />
                          </Stack>
                        </Box>
                      )}
                    </Box>
                    : !isLoading && ordersDetails.length === 0 ?
                      (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <h5>{errorMsg ? errorMsg : "No orders found..."}</h5>
                      </Stack>)
                      :
                      <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress color="success" />
                      </Stack>
                  }
                </Card>

              </Grid>

            </Grid>
          </Box>
        </Box>
      </Card>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? "Update" : "Add"} Tournament</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Grid spacing={1} container>
                <Grid item xs={12} md={12} >
                  <label className="text-xs">Tournament Name <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Tournament Name"
                    variant="outlined"
                    name="tournamentName"
                    value={orders.tournamentName}
                    onChange={handleInputChange}
                    error={errorDetails.tournamentName}
                    helperText={errorDetails.tournamentName}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                  <label className="text-xs">Start Date <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Select Start Date"
                    variant="outlined"
                    name="startDate"
                    type={'date'}
                    value={orders.startDate}
                    onChange={handleInputChange}
                    error={errorDetails.startDate}
                    helperText={errorDetails.startDate}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                  <label className="text-xs">End Date <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="End Date"
                    variant="outlined"
                    name="endDate"
                    type={'date'}
                    value={orders.endDate}
                    onChange={handleInputChange}
                    error={errorDetails.endDate}
                    helperText={errorDetails.endDate}
                  />
                </Grid>
            </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="primary" className="btn-danger mx-3" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="contained" color="secondary"  onClick={checkValidation}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default Tournament;
