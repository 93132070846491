import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom";
import {Box, Card} from "@mui/material";

import './invoice.css'
import 'pages/common.css'
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import Footer from "common/Footer";
import stampSign from "../../assets/images/stampSign.png";
import API from "apiConfig";
import qr_code from '../../assets/images/qr_code.png'
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import parse from 'html-react-parser';

function Invoice3() {
    let currentUrl = window.location.href.split("?");
    let orderRefNo = currentUrl[currentUrl.length - 1].split("/")[0]; 
    let isProforma = currentUrl[currentUrl.length - 1].split("/")[1] === "p" ? true : false; 
    const qrCodeImgUrl = "https://mis.itwgo.in/extras/custom/TMX1512291534825461/qrcodes/"

    const [isLoading, setIsLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState([]);
    const [totalServiceCharge, setTotalServiceCharge] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [CGST, setCGST] = useState(0);
    const [SGST, setSGST] = useState(0);
    const [IGST, setIGST] = useState(0);
    const [discount, setDiscount] = useState(0);
    
    useEffect(() => {
        getOrdersDetails();
    }, []); 

    const getOrdersDetails = async (pageNo) => {
        setIsLoading(true);       
        try {
            await API.get(`orders/get_orders_details_list/0?order_ref_no=${orderRefNo}&tournament&matches=&client=&sales_poc&ticket_category&type_of_requirement=&origin=&invoice=true`)
                .then(async (response) => {
                   //  console.log("Response", response.data.data.data[0])
                    if (response.data.status && response.data.data.data) {        
                        setOrderDetails(response.data.data.data);
                        let total_service_charge = response.data.data.data.map(x=> Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0);
                        let total = response.data.data.data.map(x=>(Number(x.count) * Number(x.purchase_price))).reduce((partialSum, a) => partialSum + a, 0);
                        let sub_total = total_service_charge + total;
                        let total_discount = response.data.data.data.map(x=> Number(x?.discount)).reduce((partialSum, a) => partialSum + a, 0);
                        setSubTotal(sub_total)
                        setTotalServiceCharge(total_service_charge);
                        setDiscount(total_discount);
                        if(response.data.data.data[0].registration_type === "SEZ"){
                            setCGST(0);
                            setSGST(0);
                            setIGST((total_service_charge * 18/100));
                            setTotalPrice(sub_total + (total_service_charge * 18/100)); 
                        }
                        else{
                        if(response.data.data.data[0].state === "Karnataka" || response.data.data.data[0].state === null ||
                            response.data.data.data[0].state === undefined || response.data.data.data[0].state === ""){
                            setCGST((total_service_charge * 9/100));
                            setSGST((total_service_charge * 9/100));
                            setIGST(0);
                            setTotalPrice(sub_total + (total_service_charge * 9/100) + (total_service_charge * 9/100))
                        }
                        else if(response.data.data.data[0].state !== "Karnataka"){
                            setCGST(0);
                            setSGST(0);
                            setIGST((total_service_charge * 18/100));
                            setTotalPrice(sub_total + (total_service_charge * 18/100))
                        }
                        }
                        setIsLoading(false);
                        }
                    else {
                        setIsLoading(false);
                        alert("Something went wrong! Please try again");
                       // window.location.reload();
                    }
                })
                .catch((error) => {
                    alert("Something went wrong! Please try again");
                   // window.location.reload();
                })
        } catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

    function inWords (number) {
        var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
        let num = Math.ceil(number)
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; 
        var str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : 'only';
        return str;
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    function formatDate1(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

  return ( 
    
    <DashboardLayout>
        <DashboardNavbar />
        <Box>
            {!isLoading && orderDetails?.length > 0 && (
            <Box px={3} pt={3} sx={{ width: '100%' }}>
                <div className="invoice-container">
                    <div className="row invoice3-row">
                        <div className="col-6 mb-4">
                            {!isProforma && orderDetails[0].irn_generation_logs.status === 1 && (
                            <div className="d-flex">
                                <div className="qr-table">
                                    <img src={qrCodeImgUrl + orderDetails[0].irn_generation_logs.data[0].app_reference + ".png"} />
                                </div>
                                <div className="qr-side-table">
                                    <ul>
                                        <li><b>IRN </b>:&nbsp; <span>{orderDetails[0].irn_generation_logs.data[0].irn }</span></li>
                                        <li><b>Ack No. </b>:&nbsp; <span>{orderDetails[0].irn_generation_logs.data[0].ackno } </span></li>
                                        <li><b>Ack Date </b>:&nbsp; <span>{formatDate1(orderDetails[0].irn_generation_logs.data[0].ackdt) } </span></li>
                                    </ul>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className="col-6 mb-4">
                            <table width="width-100" border='0' className="float-right">
                               <img className="img-logo" src="https://agents.itwgo.in/static/media/itwlogo.46ecd7b9.webp" />
                            </table>
                        </div>
                        <div className="col-12">
                            <table className="width-100 text-center" >
                                <tr><td>{isProforma ? "PROFORMA" : "TAX"} INVOICE </td></tr>
                            </table>
                        </div>

                        <div className="col-12 mt-4">
                            <div className="d-flex">
                                <table className="width-60" >
                                          <tr>
                                              <td>To, <br></br><b>{orderDetails[0].company_name}</b>  <br></br>
                                                  {orderDetails[0].address}
                                                  <br></br>
                                                  {orderDetails[0].gst_number && (
                                                      <><b>GST NO.</b> - {orderDetails[0].gst_number} {orderDetails[0]?.registration_type === "SEZ" ? "(SEZ)" : ""}
                                                      </>)}
                                              </td>

                                          </tr>
                                </table>
                                <table className="width-40 table-details-right" >
                                    <tr>
                                        <td>{isProforma ? "PROFORMA" : ""} INVOICE NO </td>
                                        <td>{isProforma ? orderDetails[0].profoma_invoice_no : orderDetails[0].invoice_no} </td>
                                    </tr>
                                    <tr>
                                        <td>DATE </td>
                                        <td>{formatDate(orderDetails[0]?.invoice_date)} </td>
                                    </tr>
                                    {orderDetails[0]?.po_number && orderDetails[0]?.po_number !== null && (
                                    <tr>
                                        <td>PO Number </td>
                                        <td>{orderDetails[0]?.po_number}</td>
                                    </tr>
                                    )}
                                    <tr>
                                        <td>PAN NO </td>
                                        <td>AAECI4067L</td>
                                    </tr>
                                    <tr>
                                        <td>GST No </td>
                                        <td>29AAECI4067L1Z4</td>
                                    </tr>
                                    <tr>
                                        <td>CIN </td>
                                        <td>U74999KA2017PTC099626</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <table className="width-100" >
                                <tr>
                                    <td>S No.</td>
                                    <td align="center">Details</td>
                                    <td align="right">Amount In INR</td>
                                </tr>
                                <tr className="row-height hide-border-bottom">
                                    <td className="width-8">
                                        <tr className="no-border">
                                         &nbsp;   
                                        </tr>
                                        {orderDetails?.map((x, index)=> {return (
                                        <tr className="no-border">
                                            <div>{index + 1}</div>
                                        </tr>
                                        )})}
                                    </td>
                                    <td align="" className="width-75">
                                        <tr className="no-border">
                                            <b>Reimbursement towards Match Tickets:</b>
                                        </tr>
                                        {orderDetails?.map(x=> {return (
                                        <tr className="no-border">
                                            <div>{x.teams + ", " + formatDate(x.match_date) + ", " + x.venue + " - " + x.category_name + " - " + x.count + "*" + x.purchase_price}</div>
                                        </tr>
                                         )})}
                                    </td>
                                    <td align="right" className="width-15">
                                        <tr className="no-border">
                                         &nbsp;   
                                        </tr>
                                        {orderDetails?.map(x=> {return (
                                        <tr className="no-border">
                                            <div>{(Number(x.count) * Number(x.purchase_price)).toFixed(2)}</div>
                                        </tr>
                                        )})}
                                    </td>
                                </tr>

                                <tr className="row-height hide-border">
                                    <td className="width-8">
                                        <tr className="no-border">
                                            <div>{orderDetails.length + 1}</div>
                                        </tr>
                                    </td>
                                    <td align="" className="width-75">
                                         <tr className="no-border">
                                            <b>Processing Fee for procuring Match tickets:</b>
                                        </tr>
                                    </td>
                                    <td align="right" className="width-15">
                                         <tr className="no-border">
                                            <div>{totalServiceCharge.toFixed(2)}</div>
                                        </tr>
                                    </td>
                                </tr>

                                <tr className="row-height hide-border-top">
                                    <td className="width-8">
                                        <tr className="no-border">
                                           
                                        </tr>
                                    </td>
                                    <td align="" className="width-75">
                                        {orderDetails[0]?.remarks && (
                                        <tr className="no-border">
                                            <div className="mt-3">
                                            <b>NOTE: </b>{orderDetails[0]?.remarks}
                                            </div>
                                        </tr>
                                        )}
                                        {isProforma && (
                                        <tr className="no-border">
                                            <div className="mt-3">
                                            <b>NOTE: </b>
                                            <ol>
                                                <li>In case of price fluctuation due to any reason including but not limited to dynamic pricing, the Client shall bear such extra cost over and above the amount upon issuance of the new invoice by ITW.</li>
                                                <li>Please note that the deliverables are always subject to the allocation of the relevant ticketing authorities. If there is a shortage of the number of tickets mentioned in this proforma invoice which is beyond the reasonable control of ITW, and the fee for such tickets has already been paid in advance, in such situation ITW will refund the advance fee for such shortfall of tickets. To clarify, ITW will only charge the client for the actual number of tickets that have been delivered.</li>
                                            </ol>
                                            </div>
                                        </tr>
                                        )}
                                    </td>
                                    <td align="right" className="width-15">
                                         <tr className="no-border">
                                            
                                        </tr>
                                       
                                    </td>
                                </tr>


                                <tr className="row-height-small">
                                    <td></td>
                                    <td align="right"><b>Sub Total</b></td>
                                    <td align="right">{subTotal.toFixed(2)}</td>
                                </tr>
                                {discount > 0 && (
                                <tr className="row-height-small">
                                    <td></td>
                                    <td align="right"><b>Discount</b></td>
                                    <td align="right">{discount.toFixed(2)}</td>
                                </tr>
                                )}
                                      {IGST !== 0 ? (
                                          <tr className="row-height-small">
                                              <td></td>
                                              <td align="right"><b>18% IGST on Processing Fee</b></td>
                                              <td align="right">{IGST.toFixed(2)}</td>
                                          </tr>
                                      ) : (
                                          <>
                                              <tr className="row-height-small">
                                                  <td></td>
                                                  <td align="right"><b>9% SGST on Processing Fee</b></td>
                                                  <td align="right">{SGST.toFixed(2)}</td>
                                              </tr>
                                              <tr className="row-height-small">
                                                  <td></td>
                                                  <td align="right"><b>9% CGST on Processing Fee</b></td>
                                                  <td align="right">{CGST.toFixed(2)}</td>
                                              </tr>
                                          </>
                                      )}
                                <tr className="row-height-small">
                                    <td></td>
                                    <td align="right"><b>Round Off</b></td>
                                    <td align="right">{(Math.round(Number(totalPrice).toFixed(2)) - Number(totalPrice).toFixed(2)).toFixed(2)}</td>
                                </tr>
                                <tr className="row-height-small">
                                    <td></td>
                                    <td align="right"><b>Total</b></td>
                                    <td align="right">{(Math.round(Number(totalPrice - discount).toFixed(2)).toFixed(2))}</td>
                                </tr>
                                <tr className="row-height-small">
                                    <td colSpan='3' align="left"><b>HSN/SAC CODE:</b> 998554</td>
                                </tr>
                                <tr className="row-height-small">
                                    <td colSpan='3' align="left"><b><span>Rupees in words: {inWords(Math.round(Number(totalPrice - discount).toFixed(2)).toFixed(2))}</span></b> </td>
                                </tr>
                                <tr className="row-height-small">
                                    <td colSpan='3' align="left">Payments to be made in favor of “ITW TRAVEL AND LEISURE PRIVATE LIMITED” </td>
                                </tr>
                                <tr className="row-height-small">
                                    <td colSpan='3' align="left">Mode of payment: Immediate Transfer </td>
                                </tr>
                                {orderDetails[0]?.other_terms_conditions && orderDetails[0]?.other_terms_conditions !== null && (
                                <tr className="row-height-small">
                                    <td colSpan='3' align="left">Terms & Conditions: {parse(orderDetails[0]?.other_terms_conditions)}</td>
                                </tr>
                                )}
                            </table>
                        </div>

                        <div className="col-12">
                            <div className="d-flex">
                                <table className="width-50" >
                                    <tr className="row-height-small">
                                        <td align="left">Bank Details for money transfer as follows</td>
                                    </tr>
                                    <tr className="row-height-small">
                                        <td align="left">Beneficiary Name: ITW TRAVEL AND LEISURE PRIVATE LIMITED </td>
                                    </tr>
                                    <tr className="row-height-small">
                                        <td align="left">Bank Name: ICICI BANK</td>
                                    </tr>
                                    <tr className="row-height-small">
                                        <td align="left">Account Number: 000205026741</td>
                                    </tr>
                                    <tr className="row-height-small">
                                        <td align="left">IFSC Code: ICIC0000002 </td>
                                    </tr>
                                    <tr className="row-height-small">
                                        <td align="left">SWIFT CODE: ICICINBB002 </td>
                                    </tr>
                                    <tr>
                                        <td align="left">Country: India </td>
                                    </tr>
                                </table>

                                <table className="width-50 signature-table" >
                                    <tr className="row-autho-top">
                                        <td align="center">For ITW TRAVEL AND LEISURE PRIVATE LIMITED</td>
                                    </tr>
                                    <tr className="row-autho">
                                        <td  align="center">
                                        <img className="img-stamp" src={stampSign} />
                                        </td>
                                    </tr>
                                
                                    <tr className="row-autho-top">
                                        <td align="center">Authorized Signatory </td>
                                    </tr>
                                </table>
                            </div>
                        
                        </div>

                        <div className="invoice-dis">
                            <span>This is a computer generated document.</span>
                        </div>
                    </div>
                    <footer className="footer-invoice footer-invoice-3">
                        <div className="row">
                            <div className="col-4 ">
                                <img className="width-footer-logo" src="https://agents.itwgo.in/static/media/IATA.3430c731.png" />
                            </div> 
                            <div className="col-8">
                                <p><b>ITW Travel and Leisure Private Limited</b></p>
                                <p>NO. 16/1, 1st Floor, AVS Compound, Koramangala 80 Feet Road,<br></br>4th Block, Koremangala Bengaluru - 560034</p>
                                <p><i className="fa fa-phone"></i>080 - 412 &nbsp;77&nbsp; 577 | www.itwgo.in | www.groupfares.in</p>
                                <p>CIN: U7499KA2017PTC099626</p>
                            </div> 
                        </div>
                    </footer>
                   
                </div>
            </Box>
            )}
        </Box>
        <Footer />
    </DashboardLayout>
  );
}

export default Invoice3;