// Material Dashboard 2 React layouts
import Dashboard from "pages/Dashboard";
import Sources from "pages/Sources";
// import ResetPassword from "pages/authentication/ResetPassword"
import ChangePassword from "pages/account/ChangePassword"
import Profile from "pages/account/Profile"
import { Context as AuthContext } from "./context/AuthContext";


// @mui icons
import Icon from "@mui/material/Icon";
import PageNotFound from "pages/NotFoundPage";
import { useContext } from "react";
import { Source } from "@mui/icons-material";
import Orders from "pages/Orders";
import ViewOrders from "pages/ViewOrders";
import Invoice from "pages/invoice/Invoice";
import Invoice2 from "pages/invoice/Invoice2";
import CreateMember from "pages/members/CreateMember";
import Members from "pages/members/Members";
import Invoice3 from "pages/invoice/invoice3";
import ViewSources from "pages/ViewSources";
import Sales from "pages/Sales";
import TicketCategory from "pages/TicketCategory";
import Tournament from "pages/tournament/Tournament";
import ViewTournament from "pages/tournament/ViewTournament";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    route: "/profile",
    key: "profile",
    component: <Profile />,
  },
  {
   
    route: "/sources",
    key: "sources",
    component: <Sources />,
  },
  {
    type: "collapse",
    name: "Sources",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/view-sources",
    key: "view-sources",
    component: <ViewSources />,
  },
  {
    type: "collapse",
    name: "Orders",
    route: "/orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    key: "orders",
    component: <Orders />,
  },
 
  // {
  //   type: "collapse",
  //   name: "Members",
  //   route: "/members",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   key: "member",
  //   component: <Members />,
  // },
  {
    type: "collapse",
    name: "Sales",
    route: "/sales",
    icon: <Icon fontSize="small">bar_chart</Icon>,
    key: "sales",
    component: <Sales />,
  },
  {
    type: "collapse",
    name: "Ticket Category",
    route: "/ticket-category",
    icon: <Icon fontSize="small">confirmation_number</Icon>,
    key: "ticketCategory",
    component: <TicketCategory />,
  },
  {
    type: "collapse",
    name: "Tournament",
    route: "/tournament",
    icon: <Icon fontSize="small">games</Icon>,
    key: "tournament",
    component: <Tournament />,
  },
  {
    route: "/view-tournament",
    key: "view-tournament",
    component: <ViewTournament />,
  },
  {
    route: "/add-members",
    key: "add_member",
    component: <CreateMember />,
  },
  {
    route: "/view-orders",
    key: "view-orders",
    component: <ViewOrders />,
  },
  {
    route: "/invoice",
    key: "invoice",
    component: <Invoice />,
  },
  {
    route: "/invoice2",
    key: "invoice2",
    component: <Invoice2 />,
  },
  {
    route: "/invoice3",
    key: "invoice3",
    component: <Invoice3 />,
  },
  // {
  //   route: "/sign-up",
  //   key: "sign-up",

  //   component: <SignUp />,
  // },
  // {
  //   route: "/reset-password",
  //   key: "reset-password",

  //   component: <ResetPassword />,
  // },
  {
    route: "/change-password",
    key: "change-password",

    component: <ChangePassword />,
  },
  // {
  //   route:"/",
  //   key: "/",

  //   component: <SignUp />
  // },
  // {
  //   route:'*',
  //   key: "*",
  //   component: <PageNotFound />
  // },
]

export default routes;
