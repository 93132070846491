import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <Box key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </Box>
    ));

  return (
    <>
    </>
    // <Box
    //   width="100%"
    //   display="flex"
    //   flexDirection={{ xs: "column", lg: "row" }}
    //   justifyContent="space-between"
    //   alignItems="center"
    //   px={1.5}
    //   py={3}
    // >
    //   <Box
    //     display="flex"
    //     justifyContent="center"
    //     alignItems="center"
    //     flexWrap="wrap"
    //     color="text"
    //     fontSize={size.sm}
    //     px={1.5}
    //   >
    //     &copy; {new Date().getFullYear()}, by
    //     <Link href={href} target="_blank">
    //       <MDTypography variant="button" fontWeight="medium">
    //         &nbsp; ITW AdWeb&nbsp;
    //       </MDTypography>
    //     </Link>
    //   </Box>
    //   <Box
    //     component="ul"
    //     sx={({ breakpoints }) => ({
    //       display: "flex",
    //       flexWrap: "wrap",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       listStyle: "none",
    //       mt: 3,
    //       mb: 0,
    //       p: 0,

    //       [breakpoints.up("lg")]: {
    //         mt: 0,
    //       },
    //     })}
    //   >
    //     {renderLinks()}
    //   </Box>
    // </Box>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  links: [
    { href: "#", name: "About Us" },
    { href: "#", name: "Contact" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
