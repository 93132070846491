import React, { useLayoutEffect, useState } from 'react'
import 'react-multi-carousel/lib/styles.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Grid, Box, Card, LinearProgress,Typography, Menu, FormControl, TextField, MenuItem, Button, FormHelperText, Autocomplete } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { alphaNumericValidator } from '../Validation';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import RichTextEditor from 'react-rte';
import API from 'apiConfig';

const Sales = () => {

    const [tournament, setTournament] = useState("");
    const [tournamentError, setTournamentError] = useState("");
    const [tournamentList, setTournamentList] = useState([]);
    const [requirement, setRequirement] = useState("");
    const [matchList, setMatchList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [customerName, setCustomerName] = useState(null);
    const [customerError, setCustomerError] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyError, setCompanyError] = useState(null);
    const [salesPoc, setSalesPoc] = useState(null);
    const [poNumber, setPoNumber] = useState(null);
    const [salesPocError, setSalesPocError] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [customerOrigin, setCustomerOrigin] = useState(null);
    const [initialCustomerList, setInitialCustomerList] = useState([]);
    const [ticketCategoryList, setTicketCategoryList] = useState([]);
    const [initialTicketCategoryList, setInitialTicketCategoryList] = useState([]);
    const [isEditStarts, setIsEditStarts] = useState(false);
    const [forms, setForms] = useState([{ id: 0, name: "", count: "", ticketCategory: "", requirement: "", origin: "", matchOrigin: "" }]);
    const [formsError, setFormsError] = useState([{ id: 0, name: null, count: null, ticketCategory: null, requirement: null }]);
    const [orders, setOrders] = useState({
        invoiceStatus: "invoice", invoiceNo: "", proformaInvoiceNo: "", invoiceDate: new Date(), status: "", payment_status: "", remarks: "", advance_received: "", payment_details: ""
    });
    const [CGST, setCGST] = useState(0);
    const [SGST, setSGST] = useState(0);
    const [IGST, setIGST] = useState(0);
    const [ordersDetails, setOrdersDetails] = useState([{
        id: 0,
        matches: { "match_date": "", "name": "", "origin": "", "teams": "", "tournament": "", "venue": "" },
        origin: "",
        matches_origin: "",
        selected_match: "",
        ticket_category: { "category_name": "", "city": "", "origin": "" },
        ticket_category_origin: "",
        ticket_category_list: [],
        purchase_price: "0.00",
        count: "0",
        first_total: "0.00",
        service_charge: "0.00",
        gst: "0.00",
        second_total: "0.00",
        inventory_data: [],
        selectedInventoryOrigin: "",
        discount: "0.00"
    }]);
    const [customerDetails, setCustomerDetails] = useState({
        companyName: "", title: "Mr", firstName: "", lastName: "",
        email: "", phone: "", address: "", state: "", city: [], country: [], pin: ""
    });
    const [customerDetailsError, setCustomerDetailsError] = useState({
        companyName: null, title: null, firstName: null, lastName: null,
        email: null, phone: null, address: null, state: null, city: null, country: null, pin: null
    });
    const [supportFiles, setSupportFiles] = useState([{id: 0, name: '', size: '', docType: '', file: ''}]);
    const [paymentText, setPaymentText] = useState(RichTextEditor.createEmptyValue());

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
        getTournamentDetails();
        getMatchList(0);
        getCustomerList();
    }, [])

    const getTournamentDetails = async () => {
        setIsLoading(true);

        const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
        if (response?.data?.status && response?.data?.data?.data) {
            setTournamentList(response.data.data.data);
            setIsLoading(false);
        }
        else if (response?.data?.message === 'No orders found') {
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
            alert("Something went wrong! Please try again");
        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    function formatDate1(date) {
        let newDate;
        var inputDateVal = date;
        if (inputDateVal != null && inputDateVal != '') {
            var dateArr = inputDateVal.split("-");
            var inputDate = new Date('"' + dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0] + '"').setHours(0, 0, 0, 0);

            var toDay = new Date().setHours(0, 0, 0, 0);

            if (inputDate > toDay) {
                // console.log("Date is a future date");
                newDate = true;
            } else if (inputDate == toDay) {
                //  console.log("Date is equal to today");
                newDate = true;
            } else {
                //  console.log("Date is a past date");
                newDate = false;
            }
        }
        return newDate;
    }

    const getCustomerList = async (venue) => {
        setIsLoading(true);
        API.get(`inventory/get_vendors/customer`)
            .then(response => {
                // const response = await API.get(`inventory/get_ticket_category_list/${origin}`);
                if (response.data.status) {
                    let newData = response.data.data.map(x => { return ({ ...x, label: `${x.first_name} ${x.last_name === null ? "" : x.last_name} ${x.description !== null ? " (" + x.description + ")" : ""}` }) })
                    let newCompanyData = response.data.data.map(x => { return ({ ...x, label: x.company_name + `${x.description !== null ? " (" + x.description + ")" : ""}` }) })
                    let newEmailData = response.data.data.map(x => { return ({ ...x, label: x.email }) })
                    setCustomerList(newData);
                    setCompanyList(newCompanyData);
                    setEmailList(newEmailData);
                    setInitialCustomerList(newData);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            })
    }

    const getMatchList = async (origin) => {
        setIsLoading(true);
        const response = await API.get(`inventory/get_match_list/${origin}`);
        if (response.data.status) {
            //   let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
            setMatchList(response.data.data);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }

    const getTicketCategoryList = async (venue) => {
        if (venue !== undefined) {
            setIsLoading(true);
            API.get(`inventory/get_ticket_category_list/${venue}`)
                .then(response => {
                    // const response = await API.get(`inventory/get_ticket_category_list/${origin}`);
                    if (response.data.status) {
                        let newData = response.data.data.map(x => { return ({ ...x, label: x.category_name }) })
                        setTicketCategoryList(newData);
                        setInitialTicketCategoryList(newData);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    alert("Something went wrong. Please try again later.");
                    setIsLoading(false);
                })
        }
    }

    const checkValidation = () => {
        let tournamentErr = tournament === "" ? "Please select tournament" : null;
        let customerErr = customerOrigin === null ? "Please select customer" : null;
        let companyErr = companyName === null ? "Please select company" : null;
        let salesPocErr = alphaNumericValidator(salesPoc, "SalesPOC");
        // let emailErr = emailValidator(email);
        if (tournamentErr || customerErr || companyErr || salesPocErr) {
            setTournamentError(tournamentErr);
            setCustomerError(customerErr);
            setCompanyError(companyErr);
            setSalesPocError(salesPocErr);
            //setEmailError(emailErr);
            return;
        }
        else {
            onCreateOrders();
            // console.log("MatchList",matchList)
        }
    }

    const onCreateOrders = () => {
        // let dataToSend = {
        //     "tournament": tournament,
        //     "client": customerOrigin,
        //     "sales_poc": salesPoc,
        //     "email": email,
        //     "matches": {
        //         ...forms.map(y => {
        //             return ({
        //                 origin: y.matchOrigin,
        //                 ticket_category: y.origin,
        //                 count: y.count,
        //                 type_of_requirement: y.requirement
        //             })
        //         })
        //     }
        // }
        let dataToSend = {
            "invoice_status": orders.invoiceStatus,
            "invoice_no": orders.invoiceNo,
            "invoice_date": orders.invoiceDate,
            "profoma_invoice_no": orders.proformaInvoiceNo,
            "tournament": tournament,
            "advance_received": orders.advance_received,
            "payment_details": orders.payment_details,
            "doc": supportFiles.map(item=> {return({ name: item.name, file: item.file, docType: item.docType})}),
            "other_terms_conditions": paymentText.toString('html'),
            "po_number": poNumber,
            "matches": ordersDetails.map(x => {
                return (
                    {
                        "origin": x.origin,
                        "matches": x.matches_origin,
                        "client": customerOrigin,
                        "sales_poc": salesPoc,
                        "email": selectedEmail[0]?.email,
                        "ticket_category": x.ticket_category_origin,
                        "count": x.count,
                        "type_of_requirement": requirement,
                        "status": orders.status,
                        "payment_status": orders.payment_status,
                        "sale_price": x.first_total,
                        "purchase_price": x.purchase_price,
                        "service_charge": x.service_charge,
                        "service_charge_total": x.second_total,
                        "gst": x.gst,
                        "invoice": "0",
                        "remarks": orders.remarks,
                        "match_date": x.matches.match_date,
                        "teams": x.matches.teams,
                        "venue": x.matches.venue,
                        "inventory_origin": x.selectedInventoryOrigin,
                        "discount": x.discount
                    }
                )
            })
        }
        setIsLoading(true);
        API.post('orders/new_create_orders', dataToSend)
            .then(resp => {
                if (resp.data.status) {
                    alert(`${resp.data.message}`);
                    window.location.reload();
                    setIsLoading(false);
                }
                else {
                    alert("Something went wrong. Please try again later.");
                    setIsLoading(false);
                }
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            })
       // console.log("dataToSend", dataToSend)
    }

    const getTicketCategoryList1 = async (venue, index, newValue) => {
        const newArray = [...ordersDetails];
        //const newArrayError = [...formsError];

        let new_dat;
        // setIsLoading(true);
        const response = await API.get(`inventory/get_ticket_category_list/${venue}`);
        if (response.data.status) {
            // setTicketCategoryList(response.data.data);
            new_dat = response.data.data
            // setIsLoading(false);
        }
        // else {
        //     setIsLoading(false);
        // }
        // console.log("newVal", newValue)
        // console.log("new_data", new_dat)
        newArray[index] = {
            ...newArray[index],
            selected_match: `${newValue.teams}, ${newValue.match_date}, ${newValue.venue}`,
            ticket_category_list: new_dat,
            matches: newValue,
            matches_origin: newValue.origin
        }

        // Call setData to update.
        setOrdersDetails(newArray);
        // setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const onTicketcategoryChange = (newValue, index) => {
        const newArray = [...ordersDetails];

        // newArray[index] = {
        //     ...newArray[index],
        //     ticket_category: newValue,
        //     ticket_category_origin: newValue.origin
        // }

        // Call setData to update.
        // setOrdersDetails(newArray);
        // setFormsError(newArrayError);
        setIsEditStarts(true);
        getInventoryData(newValue, index)
    }

    const getInventoryData = async (newValue, index) => {
        let matches = ordersDetails[index]?.matches_origin;
        let ticket_category = newValue?.origin;
        const newArray = [...ordersDetails];

        const response = await API.get(`orders/get_inventory_data/${matches}/${ticket_category}`)
        if (response?.data?.status && response?.data?.data) {
            newArray[index] = {
                ...newArray[index],
                inventory_data: response.data.data,
                ticket_category: newValue,
                ticket_category_origin: newValue.origin
            }
        }
        else {
            newArray[index] = {
                ...newArray[index],
                inventory_data: [],
                ticket_category: newValue,
                ticket_category_origin: newValue.origin
            }
        }
        setOrdersDetails(newArray);
    }

    const handleInventory = (event, index, invData, prevObj) => {
        // console.log("Value",event.target.value, "name", event.target.name)
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        let required_data = invData?.filter(x => x.origin === event.target.value)[0]
        let count = Number(prevObj.count) <= Number(required_data.stock_left) ? Number(prevObj.count) : Number(required_data.stock_left)
        newArray[index] = {
            ...newArray[index], [event.target.name]: event.target.value,
            purchase_price: Number(required_data.purchase_price),
            count: Number(required_data.stock_left),
            first_total: (Number(required_data.purchase_price) * Number(required_data.stock_left)).toFixed(2)
        }

        // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const handleChange = (event, index) => {
        // console.log("Value",event.target.value, "name", event.target.name)
        const newArray = [...ordersDetails];
        const newArrayError = [...formsError];

        if (event.target.name === "purchase_price") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].count)).toFixed(2)
            }
        }
        else if (event.target.name === "count") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                first_total: (Number(event.target.value) * Number(newArray[index].purchase_price)).toFixed(2)
            }
        }
        else if (event.target.name === "service_charge") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: (Number(event.target.value) * 0.18).toFixed(2),
                second_total: (Number(event.target.value) + Number(event.target.value) * 0.18).toFixed(2)
            }
            if (IGST !== 0) {
                setIGST(Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 18 / 100);
            }
            else {
                setCGST(Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9 / 100);
                setSGST(Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) * 9 / 100);
            }
        }
        else if (event.target.name === "second_total") {
            newArray[index] = {
                ...newArray[index], [event.target.name]: event.target.value,
                gst: ((Number(event.target.value) / 118) * 18).toFixed(2),
                service_charge: ((Number(event.target.value) / 118) * 100).toFixed(2)
            }
            if (IGST !== 0) {
                setIGST((Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 18 / 100);
            }
            else {
                setCGST((Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9 / 100);
                setSGST((Number(newArray.map(x => Number(x.service_charge)).reduce((partialSum, a) => partialSum + a, 0)) / 118) * 100 * 9 / 100);
            }
        }

        newArray[index] = { ...newArray[index], [event.target.name]: event.target.value }
        newArrayError[index] = { ...newArrayError[index], [event.target.name]: null }

        // Call setData to update.
        setOrdersDetails(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const handleMatchChange = (value, origin, index) => {
        if (value !== undefined) {
            const newArray = [...forms];
            const newArrayError = [...formsError];

            newArray[index] = { ...newArray[index], name: value, matchOrigin: origin }
            newArrayError[index] = { ...newArrayError[index], name: null }

            // Call setData to update.
            setForms(newArray);
            setFormsError(newArrayError);
            setIsEditStarts(true);
        }
    }

    const handleTicketCategoryChange = (value, origin, index) => {
        if (value !== undefined) {
            const newArray = [...forms];
            const newArrayError = [...formsError];

            newArray[index] = { ...newArray[index], ticketCategory: value, origin: origin }
            newArrayError[index] = { ...newArrayError[index], ticketCategory: null }

            setForms(newArray);
            setFormsError(newArrayError);
            setIsEditStarts(true);
        }
    }

    const handleCountChange = (e, index) => {
        const newArray = [...forms];
        const newArrayError = [...formsError];

        newArrayError[index] = { ...newArrayError[index], count: null }
        setFormsError(newArrayError);
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 5) {
            newArray[index] = { ...newArray[index], count: onlyNums }
            setForms(newArray);
        } else if (onlyNums.length === 5) {
            newArray[index] = { ...newArray[index], count: onlyNums }
            setForms(newArray);
        }
        setIsEditStarts(true);
    }

    const handleRequirementChange = (e, index) => {
        const newArray = [...forms];
        const newArrayError = [...formsError];

        newArray[index] = { ...newArray[index], requirement: e.target.value }
        newArrayError[index] = { ...newArrayError[index], requirement: null }

        setForms(newArray);
        setFormsError(newArrayError);
        setIsEditStarts(true);
    }

    const onFormAddition = () => {
        //setForms([...forms, { id: forms.length, name: "", count: "", ticketCategory: "" }]);
        setOrdersDetails([...ordersDetails, {
            id: ordersDetails.length,
            matches: { "match_date": "", "name": "", "origin": "", "teams": "", "tournament": "", "venue": "" },
            origin: "",
            matches_origin: "",
            selected_match: "",
            ticket_category: { "category_name": "", "city": "", "origin": "" },
            ticket_category_origin: "",
            ticket_category_list: [],
            purchase_price: "0.00",
            count: "0",
            first_total: "0.00",
            service_charge: "0.00",
            gst: "0.00",
            second_total: "0.00",
            inventory_data: [],
            selectedInventoryOrigin: "0",
            discount: "0.00"
        }])
        // setFormsError([...formsError, { id: forms.length, name: null, count: null, ticketCategory: null }])
    }

    const onFormDelete = (index) => {
        //  let form = forms;
        // let newForm = forms.filter(item => item.id !== index);
        let newForm = ordersDetails.filter(item => item.id !== index);
        let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
        // console.log(oneMore,"oneMore")
        // setForms(oneMore);
        setOrdersDetails(oneMore);
        // let newFormError = formsError.filter(item => item.id !== index);
        // let oneMoreError = newFormError.map((x, index) => { return ({ ...x, id: index }) })
        // console.log(oneMore,"oneMore")
        // setFormsError(oneMoreError);
    }

    // console.log("editedList", editedList)
    // console.log("MyArr",
    //     matchList.filter(x=> editedList.includes(x.origin))
    //     .map(y=>{return({origin: y.origin, 
    //                     ticket_category: [y.general,y.premium,y.pavilion, y.platinum, y.theGrandLounge, y.skyBox, y.vipSuite],
    //                     count: [y.general,y.premium,y.pavilion, y.platinum, y.theGrandLounge, y.skyBox, y.vipSuite],
    //                     type_of_requirement: Array(editedList.length).fill(y.requirement)
    //                 })}))

    const handleCustomerDetails = (e) => {
        setCustomerDetails({ ...customerDetails, [e.target.name]: e.target.value });
        setCustomerDetailsError({ ...customerDetailsError, [e.target.name]: null });
    }

    const handlePickNumeric = (e) => {
        setCustomerDetailsError({ ...customerDetailsError, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setCustomerDetails({ ...customerDetails, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            setCustomerDetails({ ...customerDetails, phone: onlyNums })
        }
    }

    function dateFormat(date) {
        let formattedDate = moment(date).format('Do MMMM');
        return formattedDate;
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getCountries();
        getCities("92");
        getStates();
        setCustomerDetails({
            ...customerDetails, country: [{
                "origin": "92",
                "api_continent_list_fk": "2",
                "name": "India",
                "country_code": "+91",
                "iso_country_code": "IN",
                "label": "India"
            }]
        })
    };

    const getCountries = async () => {
        API.get(`data/country_details`)
            .then(response => {
                if (response.data.status) {
                    setCountryList(response.data.data.data.map(x => { return ({ ...x, label: x.name }) }));
                }
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
            })
    }
    const getCities = async (origin) => {
        API.get(`data/get_cities/${origin}`)
            .then(response => {
                if (response.data.status) {
                    setCityList(response.data.data.data.map(x => { return ({ ...x, label: x.destination }) }));
                }
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
            })
    }
    const getStates = async () => {
        API.get(`data/india_state`)
            .then(response => {
                if (response.data.status) {
                    setStateList(response.data.data.data);
                }
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
            })
    }

    const validateCustomerDetails = () => {
        let company_err = customerDetails.companyName === "" ? "Please enter company name" : null;
        let title_err = customerDetails.title === "" ? "Please select title" : null;
        let firstName_err = customerDetails.firstName === "" ? "Please enter first name" : null;
        let lastName_err = customerDetails.lastName === "" ? "Please enter last name" : null;
        let state_err = (customerDetails.country[0]?.origin === "92" && customerDetails.state === "") ? "Please select state" : null;
        let address_err = customerDetails.address === "" ? "Please enter address" : null;

        if (company_err || title_err || firstName_err || lastName_err || address_err || state_err) {
            setCustomerDetailsError({
                ...customerDetailsError,
                companyName: company_err,
                title: title_err,
                firstName: firstName_err,
                lastName: lastName_err,
                state: state_err,
                address: address_err
            });
            return
        }
        else {
            onSaveCustomerDetails()
        }
    }

    const onSaveCustomerDetails = () => {
        let dataToSend = {
            company_name: customerDetails.companyName,
            title: customerDetails.title,
            first_name: customerDetails.firstName,
            last_name: customerDetails.lastName,
            email: customerDetails.email,
            phone: customerDetails.phone,
            country: customerDetails.country.length ? customerDetails.country[0].origin : "",
            city: customerDetails.companyName.length ? customerDetails.city[0].origin : "",
            address: customerDetails.address,
            pin: customerDetails.pin,
            state: customerDetails.state
        }
        console.log(dataToSend)
        API.post('orders/create_debtor', dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                setShow(false);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setShow(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setShow(false);
        })
    }

    const uploadFile = async (event, type) => {
        const file = event.target.files[0]
    
        console.log(event.target.files[0]);
        var fileSize = parseFloat(file.size / 1000).toFixed(2);
        console.log("Size", fileSize);
        console.log("file.type", file.type)
        console.log("file.name", file)
        if (fileSize > 2028) {
         alert("Please upload a file smaller than 2mb" );
          return false;
        }
    
        //  if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "application/pdf" && file.type != "image/png") {
        //  // setErrorDetails({ ...errorDetails, gstFile: "Please select a JPG/JPEG/PNG/PDF file for upload" });
        //   return;
        // }
        // else {
          let img = new Image()
          img.src = window.URL.createObjectURL(file)
          var reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = function () {
            let covertedData = btoa(reader.result);
            console.log("base64", `data:${file.type};base64,${covertedData}`);
            const newArray = [...supportFiles];
            let arrLength = supportFiles.filter(item=> item.name !== '' && item.file !== '').length
            newArray[arrLength] = { ...newArray[arrLength], id: arrLength, docType: '', name: file.name, size: fileSize, file: `data:${file.type};base64,${covertedData}` }
            setSupportFiles(newArray);
            // setMembers({...members, gstFile: `data:${file.type};base64,${covertedData}`});
            //   setErrorDetails({ ...errorDetails, gstFile: null});
          };
          reader.onerror = function () {
            console.log('there are some problems');
          };
        // }
      }

      const handleSupportFilesDocType= (e, index) => {
        const newArray = [...supportFiles];
            newArray[index] = { ...newArray[index], docType: e.target.value }
            setSupportFiles(newArray);
      }

      const onSupportFileDelete = (index) => {
        if(supportFiles.length > 1){
        let newForm = supportFiles.filter(item => item.id !== index);
        let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
        setSupportFiles(oneMore);
        }
        else{
            setSupportFiles([{id: 0, name: '', size: '', docType: '', file: ''}]);   
        }
    }

    const onEditorChange = (value) => {
        setPaymentText(value);
    };

   //  console.log("supportFiles", supportFiles)
    //  console.log("selectedEmail", selectedEmail)
    //  console.log("[...forms[index]?.name]", Array(forms[0]?.name))
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card className='sales-container'>
                <Box px={3} pt={3} sx={{ width: '100%' }}>
                    <h5>Create New Invoice</h5>

                    <Form>
                        <Box className="box-container my-3">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Tournament</Form.Label>
                                        <TextField
                                            // className="form-control mt-1"
                                            id="basic-example"
                                            placeholder="Tournament"
                                            variant="outlined"
                                            name="tournament"
                                            select
                                            value={tournament}
                                            onChange={(e) => { setTournament(e.target.value); setTournamentError(""); getMatchList(e.target.value); }}
                                        >
                                            {tournamentList.map((item, index) =>
                                                <MenuItem key={index} value={item.origin}>{item.name}</MenuItem>
                                            )}
                                        </TextField>
                                        {tournamentError !== "" && (
                                            <FormHelperText className="text-error" error={tournamentError}>
                                                {tournamentError}
                                            </FormHelperText>)}
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Name <a className='modal-link' onClick={handleShow}>+ Add customer</a></Form.Label>
                                        <Typeahead
                                            id="basic-example"
                                            options={customerList}
                                            placeholder="Choose a customer..."
                                            selected={selectedCustomer}
                                            onInputChange={(text) => {
                                                const newArray = [...selectedCustomer];
                                                newArray[0] = { ...newArray[0], label: text }
                                                setSelectedCustomer(newArray);
                                                setCustomerOrigin(0);
                                            }}
                                            onChange={(selected) => {
                                                if (selected[0] !== undefined) {
                                                    //console.log("selected value", selected[0]?.first_name);
                                                    setCustomerOrigin(selected[0]?.origin);
                                                    setCompanyName(selected[0]?.company_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}`);
                                                    setEmail(selected[0]?.email);
                                                    setSelectedCustomer(selected)
                                                    const companyArray = [...selectedCompany];
                                                    companyArray[0] = { ...selected[0], label: selected[0].company_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}` };
                                                    const emailArray = [...selectedEmail];
                                                    emailArray[0] = { ...selected[0], label: selected[0]?.email ? selected[0].email : "" };
                                                    setSelectedCompany(companyArray);
                                                    setSelectedEmail(emailArray)
                                                    //setCustomerName(selected[0]?.first_name + " " + selected[0]?.last_name);
                                                }
                                            }}
                                        />
                                        {customerError !== null && (
                                            <FormHelperText className="text-error" error={customerError}>
                                                {customerError}
                                            </FormHelperText>)}
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Company Name</Form.Label>
                                        {/* <Form.Control
                                            type="text"
                                            placeholder="Enter Client"
                                            name="client"
                                            value={companyName}
                                            onChange={(e) => { setCompanyName(e.target.value)}}
                                        /> */}
                                        <Typeahead
                                            id="basic-example"
                                            options={companyList}
                                            placeholder="Choose a company..."
                                            selected={selectedCompany}
                                            onInputChange={(text) => {
                                                const newArray = [...selectedCompany];
                                                newArray[0] = { ...newArray[0], label: text };
                                                setSelectedCompany(newArray);
                                                setCompanyName(text);
                                            }}
                                            onChange={(selected) => {
                                                if (selected[0] !== undefined) {
                                                    //  console.log("selected value", selected[0]);
                                                    setCustomerOrigin(selected[0]?.origin);
                                                    setCompanyName(selected[0]?.company_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}`);
                                                    setEmail(selected[0]?.email);
                                                    setSelectedCompany(selected);
                                                    const customerArray = [...selectedCustomer];
                                                    customerArray[0] = { ...selected[0], label: `${selected[0].first_name} ${selected[0].last_name === null ? "" : selected[0]?.last_name}` + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}` }
                                                    const emailArray = [...selectedEmail];
                                                    emailArray[0] = { ...selected[0], label: selected[0].email === null ? "" : selected[0].email };
                                                    setSelectedCustomer(customerArray);
                                                    setSelectedEmail(emailArray)
                                                }
                                            }}
                                        />
                                        {companyError !== null && (
                                            <FormHelperText className="text-error" error={companyError}>
                                                {companyError}
                                            </FormHelperText>)}
                                    </Form.Group>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Typeahead
                                            id="basic-example"
                                            options={emailList}
                                            placeholder="Choose an email..."
                                            selected={selectedEmail}
                                            onInputChange={(text) => {
                                                const newArray = [...selectedEmail];
                                                newArray[0] = { ...newArray[0], label: text };
                                                setSelectedEmail(newArray);
                                                setEmail(text);
                                            }}
                                            onChange={(selected) => {
                                                if (selected[0] !== undefined) {
                                                    // console.log("selected value", selected[0]);
                                                    setCustomerOrigin(selected[0]?.origin);
                                                    setCompanyName(selected[0]?.company_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}`);
                                                    setEmail(selected[0]?.email);
                                                    setCustomerName(selected[0]?.first_name + " " + selected[0]?.last_name === null ? "" : selected[0]?.last_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}`);
                                                    setSelectedEmail(selected);
                                                    const customerArray = [...selectedCustomer];
                                                    customerArray[0] = { ...selected[0], label: `${selected[0].first_name} ${selected[0].last_name === null ? "" : selected[0]?.last_name}` + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}` }
                                                    const companyArray = [...selectedCompany];
                                                    companyArray[0] = { ...selected[0], label: selected[0].company_name + `${selected[0]?.description !== null ? " (" + selected[0]?.description + ")" : ""}` };
                                                    setSelectedCustomer(customerArray);
                                                    setSelectedCompany(companyArray);
                                                }
                                            }}
                                        />
                                        {emailError !== null && (
                                            <FormHelperText className="text-error" error={emailError}>
                                                {emailError}
                                            </FormHelperText>)}
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>PO Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter PO Number"
                                            name="poNumber"
                                            value={poNumber}
                                            onChange={(e) =>setPoNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Sales POC</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter POC"
                                            name="salesPoc"
                                            value={salesPoc}
                                            onChange={(e) => { setSalesPoc(e.target.value); setSalesPocError(null) }}
                                        />
                                        {salesPocError !== null && (
                                            <FormHelperText className="text-error" error={salesPocError}>
                                                {salesPocError}
                                            </FormHelperText>)}
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Type of Requirement</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name='requirement'
                                            // ref='requirement'
                                            value={requirement}
                                            onChange={(e) => setRequirement(e.target.value)}
                                        >
                                            <option>Select</option>
                                            <option value="sale">Sale</option>
                                            <option value="complimentary">Complimentary</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} >
                                    <label>Invoice Status </label>
                                    <TextField
                                        className="form-control mt-1"
                                        id="outlined-basic"
                                        placeholder="Invoice Status"
                                        variant="outlined"
                                        name="invoiceStatus"
                                        select
                                        value={orders.invoiceStatus}
                                        onChange={(e) => setOrders({ ...orders, invoiceStatus: e.target.value })}
                                    >
                                        <MenuItem value="proforma">Proforma</MenuItem>
                                        <MenuItem value="invoice">Invoice</MenuItem>
                                    </TextField>
                                </Grid>
                                {orders.invoiceStatus === "invoice" ? (
                                    <Grid item xs={12} md={4} >
                                        <label>Invoice No. </label>
                                        <TextField
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            //type="number"
                                            placeholder="Invoice No."
                                            variant="outlined"
                                            name="invoiceNo"
                                            value={orders.invoiceNo}
                                            onChange={(e) => setOrders({ ...orders, invoiceNo: e.target.value })}
                                        // inputProps={{ min: 0 }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} md={4} >
                                        <label>Proforma Invoice No.</label>
                                        <TextField
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            //type="number"
                                            placeholder="Proforma Invoice No."
                                            variant="outlined"
                                            name="proformaInvoiceNo"
                                            value={orders.proformaInvoiceNo}
                                            onChange={(e) => setOrders({ ...orders, proformaInvoiceNo: e.target.value })}
                                        // inputProps={{ min: 0 }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={4} >
                                    <label>Invoice Date</label>
                                    <TextField
                                        className="form-control mt-1"
                                        id="outlined-basic"
                                        type={"date"}
                                        placeholder="Sales POC"
                                        variant="outlined"
                                        name="invoiceDate"
                                        value={orders.invoiceDate}
                                        onChange={(e) => setOrders({ ...orders, invoiceDate: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {ordersDetails.map((item, index) =>
                            <>
                                <Box className="box-container my-3">
                                    {/* <h3>Category</h3> */}
                                    <Box className="mb-3">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={3} >
                                                <label className="text-xs">Matches </label>
                                                <Autocomplete
                                                    className="inputFieldLocation"
                                                    size="small"
                                                    id="outlined-basic"
                                                    freeSolo
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    options={matchList}
                                                    value={item.matches}
                                                    getOptionLabel={(option) => option.match_date !== "" ? option.teams + ", " + formatDate(option.match_date) + ", " + option.venue : ""}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.origin}>
                                                            {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                                                        </li>
                                                    )}
                                                    renderInput={params => (
                                                        <TextField
                                                            id="outlined-basic"
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            className="form-control mt-1"
                                                            placeholder="Matches"
                                                        />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        getTicketCategoryList1(newValue.venue, index, newValue);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} >
                                                <label className="text-xs">Ticket Category</label>
                                                <Autocomplete
                                                    className="inputFieldLocation mt-1"
                                                    size="small"
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    value={item.ticket_category}
                                                    options={item.ticket_category_list}
                                                    getOptionLabel={(option) => option.category_name}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.origin}>
                                                            {option.category_name}
                                                        </li>
                                                    )}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            size="small"
                                                            className="inputboxremark"
                                                            placeholder="Choose a category..."
                                                        />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        onTicketcategoryChange(newValue, index)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} >
                                                <Form.Group className="mb-2" controlId="formBasicEmail">
                                                    <Form.Label>Inventory</Form.Label>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            type="text"
                                                            className="form-control mt-1"
                                                            id="demo-simple-select"
                                                            placeholder="select Inventory"
                                                            variant="outlined"
                                                            name="selectedInventoryOrigin"
                                                            select
                                                            value={item.selectedInventoryOrigin}
                                                            onChange={(e) => handleInventory(e, index, item.inventory_data, item)}
                                                        >
                                                            {item.inventory_data?.map((x, i) =>
                                                                <MenuItem value={x.origin}>{"INR " + x.purchase_price + " (" + x.stock_left + ")"}</MenuItem>
                                                            )}
                                                        </TextField>
                                                    </FormControl>
                                                </Form.Group>
                                            </Grid>
                                            <Grid item xs={12} md={2} className='btn-add m-auto'>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    className=''
                                                    // disabled={forms.length === 10 ? true : false} 
                                                    onClick={() => onFormAddition()}
                                                >Add</Button>
                                                <Button
                                                    size="small"
                                                    className='btn-danger mx-3'
                                                    variant="contained"
                                                    color="error"
                                                    disabled={ordersDetails.length === 1 ? true : false}
                                                    onClick={() => onFormDelete(index)}
                                                >Delete</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} >
                                            <Grid item xs={6} md={1.7}  >
                                                <label className="text-xs">Purchase price </label>
                                                <FormControl fullWidth >
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="Purchase price"
                                                        variant="outlined"
                                                        name="purchase_price"
                                                        inputProps={{ min: 0 }}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={item.purchase_price}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={1.7} >
                                                <label className="text-xs">Count</label>
                                                <TextField
                                                    className="form-control mt-1"
                                                    id="outlined-basic"
                                                    placeholder="Count"
                                                    variant="outlined"
                                                    name="count"
                                                    value={item.count}
                                                    onChange={(e) => handleChange(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={1.7}>
                                                <label className="text-xs">Total </label>
                                                <FormControl fullWidth >
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="Sale price"
                                                        variant="outlined"
                                                        name="first_total"
                                                        onWheel={(e) => e.target.blur()}
                                                        value={item.first_total}
                                                        onChange={(e) => handleChange(e, index)}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6} md={1.7}>
                                                <label className="text-xs">Service Charge </label>
                                                <FormControl fullWidth mt={1}>
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="Service Charge"
                                                        variant="outlined"
                                                        name="service_charge"
                                                        inputProps={{ min: 0 }}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={item.service_charge}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={1.7} >
                                                <label className="text-xs">GST </label>
                                                <FormControl fullWidth mt={1}>
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="GST"
                                                        variant="outlined"
                                                        name="gst"
                                                        inputProps={{ min: 0 }}
                                                        value={item.gst}
                                                        onChange={(e) => handleChange(e, index)}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={1.7} >
                                                <label className="text-xs">Total </label>
                                                <FormControl fullWidth mt={1}>
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="Sale price"
                                                        variant="outlined"
                                                        name="second_total"
                                                        value={item.second_total}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} md={1.8} >
                                                <label className="text-xs">Discount </label>
                                                <FormControl fullWidth mt={1}>
                                                    <TextField
                                                        type="number"
                                                        className="form-control mt-1"
                                                        id="demo-simple-select"
                                                        placeholder="Discount"
                                                        variant="outlined"
                                                        name="discount"
                                                        inputProps={{ min: 0 }}
                                                        value={item.discount}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Box>

                            </>
                        )}
                        <Box className="box-container my-3">
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3} >
                                    <label className="text-xs">Status </label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Choose Status"
                                            variant="outlined"
                                            name="status"
                                            select
                                            value={orders.status}
                                            onChange={(e) => setOrders({ ...orders, status: e.target.value })}
                                        >
                                            <MenuItem value="new">New</MenuItem>
                                            <MenuItem value="delivered">delivered</MenuItem>
                                            <MenuItem value="rejected">rejected</MenuItem>
                                            <MenuItem value="aborted">aborted</MenuItem>
                                            <MenuItem value="need_clarity">need clarity</MenuItem>
                                            <MenuItem value="duplicate">duplicate</MenuItem>
                                            <MenuItem value="on_hold">On hold</MenuItem>

                                        </TextField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} md={3} >
                                    <label className="text-xs">Payment Status </label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Choose Payment Status"
                                            variant="outlined"
                                            name="payment_status"
                                            select
                                            value={orders.payment_status}
                                            onChange={(e) => setOrders({ ...orders, payment_status: e.target.value })}
                                        >
                                            <MenuItem value="paid">Paid</MenuItem>
                                            <MenuItem value="given_credit">Given credit</MenuItem>
                                            <MenuItem value="pending">Pending</MenuItem>

                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3} >
                                    <label className="text-xs">Advance Received</label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="number"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Advance Received"
                                            variant="outlined"
                                            name="advance_received"
                                            InputProps={{ inputProps: { min: 0} }}
                                            value={orders.advance_received}
                                            onChange={(e) => setOrders({ ...orders, advance_received: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3} >
                                    <label className="text-xs">Payment Details</label>
                                    <FormControl fullWidth mt={1}>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="demo-simple-select"
                                            placeholder="Payment details"
                                            variant="outlined"
                                            name="payment_details"
                                            value={orders.payment_details}
                                            inputProps={{ maxLength: 200 }}
                                            onChange={(e) => setOrders({ ...orders, payment_details: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6} >
                                    <label className="text-xs">Remarks</label>
                                    <textarea
                                        className="form-control mt-1"
                                        id="outlined-basic"
                                        placeholder="Remarks"
                                        variant="outlined"
                                        name="remarks"
                                        multiline
                                        rows={4}
                                        value={orders.remarks}
                                        onChange={(e) => setOrders({ ...orders, remarks: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                <label className="text-xs">Payment and other T&C's</label>
                                <RichTextEditor 
                                    value={paymentText}
                                    onChange={onEditorChange} 
                                  />
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} md={12} ><Typography variant="h5" component="div" className="primary-text order-heading mb-2 mt-2">
                                                        Upload support file</Typography></Grid>
                            <Grid container mt={1}>
                                <Grid item xs={12} md={4} >
                                <FormControl fullWidth >
                                    <TextField
                                        type="file"
                                        className="form-control mt-1"
                                        id="demo-simple-select"
                                        placeholder="Upload file"
                                        variant="outlined"
                                        name="upload_file"
                                        onChange={uploadFile}
                                       // accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        inputProps={{ accept: "image/png, image/jpeg, image/jpeg, application/pdf, .doc, .docx" }}
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} mt={1} mx={2}>
                                    <Button variant="contained" color="error" size="small" >Reset</Button>
                                </Grid>
                                <Grid item xs={12} md={8} mt={2}>
                                    <table border="1" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Size</th>
                                            <th>Doc Type</th>
                                            <th>Action</th>
                                        </tr>
                                        {supportFiles?.map((item,index)=>
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.size}{item.size !== '' && 'Kb'}</td>
                                            <td>
                                                <FormControl fullWidth className='table-dropdown'>
                                                    <TextField
                                                        type="text"
                                                        className="form-control"
                                                        id="demo-simple-select"
                                                        placeholder="Select"
                                                        variant="outlined"
                                                        name="doc_type"
                                                        select
                                                        value={item.docType}
                                                        onChange={(e)=> handleSupportFilesDocType(e,index)}
                                                        // error={customerDetailsError.state}
                                                        // helperText={customerDetailsError.state}
                                                    >
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value="invoice">Invoice</MenuItem>
                                                        <MenuItem value="receipt">Receipt</MenuItem>
                                                        <MenuItem value="agreement">Agreement</MenuItem>
                                                        <MenuItem value="credit_note">Creditnote</MenuItem>
                                                    </TextField>
                                                </FormControl>
                                            </td>
                                            <td>
                                            <ButtonGroup aria-label="Basic example">
                                                <Button variant="contained" color="error" size="small" onClick={()=> onSupportFileDelete(index)}><DeleteIcon /></Button>
                                            </ButtonGroup>
                                            </td>
                                        </tr>
                                        )}
                                    </thead>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} className='mt-4 mb-5'>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isLoading ? true : false} onClick={() => checkValidation()}>
                                    {isLoading ? <Spinner animation="border" variant="light" /> : " Submit"}
                                </Button>
                            </Grid>
                        </Box>
                        
                    </Form>
                </Box>
            </Card>
            <Modal show={show} onHide={handleClose} className="add-customer-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={1} className='px-3 pb-3'>
                        <Grid item xs={12} md={12}>
                            <label className="text-xs">Company name</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Company name"
                                    variant="outlined"
                                    name="companyName"
                                    value={customerDetails.companyName}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.companyName}
                                    helperText={customerDetailsError.companyName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <label className="text-xs">Title</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Choose Title"
                                    variant="outlined"
                                    name="title"
                                    select
                                    value={customerDetails.title}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.title}
                                    helperText={customerDetailsError.title}
                                >
                                    <MenuItem value="Miss">Miss</MenuItem>
                                    <MenuItem value="Mr">Mr</MenuItem>
                                    <MenuItem value="Mrs">Mrs</MenuItem>

                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <label className="text-xs">First Name</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="First Name"
                                    variant="outlined"
                                    name="firstName"
                                    value={customerDetails.firstName}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.firstName}
                                    helperText={customerDetailsError.firstName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <label className="text-xs">Last Name</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Last Name"
                                    variant="outlined"
                                    name="lastName"
                                    value={customerDetails.lastName}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.lastName}
                                    helperText={customerDetailsError.lastName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label className="text-xs">Email ID</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="email"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Email ID"
                                    variant="outlined"
                                    name="email"
                                    value={customerDetails.email}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.email}
                                    helperText={customerDetailsError.email}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label className="text-xs">Phone Number</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Phone Number"
                                    variant="outlined"
                                    name="phone"
                                    value={customerDetails.phone}
                                    onChange={handlePickNumeric}
                                    error={customerDetailsError.phone}
                                    helperText={customerDetailsError.phone}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label className="text-xs">Country</label>
                            <FormControl fullWidth>
                                <Typeahead
                                    id="basic-example"
                                    options={countryList}
                                    placeholder="Choose a country..."
                                    selected={customerDetails.country}
                                    onInputChange={(text) => {
                                        const newArray = [...customerDetails.country];
                                        newArray[0] = { ...newArray[0], label: text };
                                        setCustomerDetails({ ...customerDetails, country: newArray });
                                    }}
                                    onChange={(selected) => {
                                        if (selected[0] !== undefined) {
                                            setCustomerDetails({ ...customerDetails, country: selected });
                                            getCities(selected[0]?.origin);
                                            if(selected[0].origin !== "92"){
                                                setCustomerDetails({ ...customerDetails, country: selected, state: "" });
                                            }
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {customerDetails.country[0]?.origin === "92" && (
                            <Grid item xs={12} md={6}>
                                <label className="text-xs">State</label>
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        id="demo-simple-select"
                                        placeholder="Choose City"
                                        variant="outlined"
                                        name="state"
                                        select
                                        value={customerDetails.state}
                                        onChange={handleCustomerDetails}
                                        error={customerDetailsError.state}
                                        helperText={customerDetailsError.state}
                                    >
                                        {stateList?.map(x =>
                                            <MenuItem value={x?.origin} key={x?.origin}>{x?.en_name}</MenuItem>
                                        )}

                                    </TextField>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <label className="text-xs">City</label>
                            <FormControl fullWidth>
                                <Typeahead
                                    id="basic-example"
                                    options={cityList}
                                    placeholder="Choose a city..."
                                    selected={customerDetails.city}
                                    onInputChange={(text) => {
                                        const newArray = [...customerDetails.city];
                                        newArray[0] = { ...newArray[0], label: text };
                                        setCustomerDetails({ ...customerDetails, city: newArray });
                                    }}
                                    onChange={(selected) => {
                                        console.log(selected)
                                        if (selected[0] !== undefined) {
                                            setCustomerDetails({ ...customerDetails, city: selected });
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label className="text-xs">Address</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Address"
                                    variant="outlined"
                                    name="address"
                                    value={customerDetails.address}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.address}
                                    helperText={customerDetailsError.address}
                                />
                            </FormControl>
                        </Grid>



                        <Grid item xs={12} md={6}>
                            <label className="text-xs">Pin Code</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    className="form-control"
                                    id="demo-simple-select"
                                    placeholder="Pin Code"
                                    variant="outlined"
                                    name="pin"
                                    value={customerDetails.pin}
                                    onChange={handleCustomerDetails}
                                    error={customerDetailsError.pin}
                                    helperText={customerDetailsError.pin}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" className="btn-danger mx-3" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => { validateCustomerDetails() }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </DashboardLayout>
    );
}


export default Sales;
