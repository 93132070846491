const INVALID_TOKEN = "Signature verification failed";

const TOKEN_EXPIRED = "Token Expired";

const APPLICATION_JSON = "application/json";

const PROD_ENV_STRING = "production";


export {
  INVALID_TOKEN,
  TOKEN_EXPIRED,
  APPLICATION_JSON,
  PROD_ENV_STRING,
};
