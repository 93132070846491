import React, {useEffect, useState} from "react"
import { Link, NavLink } from "react-router-dom";
import {Grid, Box, Card, LinearProgress, Menu, FormControl, TextField, MenuItem, Button, Autocomplete, CircularProgress} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';


// Data
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import axios from "axios";
import exportFromJSON from "export-from-json";

function ViewSources() {
  const resultPerPage = 20;
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorOrigin, setSelectedVendorOrigin] = useState("");
  const [selectedVendorError, setSelectedVendorError] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [tournamentList, setTournamentList] = useState([]);
  const [orders, setOrders] = useState({
    orderRef: "", tournament: "", matches: "", client: "", salesPoc: "", ticketCategory: "", 
    requirement: "", status: "", inventoryId: "", sources: "", invoiceNumber: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    orderRef: null, tournament: null, matches: null, client: null, salesPoc: null, 
    ticketCategory: null, requirement: null, status: null, inventoryId: null, sources: null, invoiceNumber: null
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, origin) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrigin(origin);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setSearchBox(!searchBox)
  }

  const handleInputChange = (e) => {
    setOrders({ ...orders, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  useEffect(() => {
    getTournamentDetails();
    getSourcesDetails();
    getMatchList();
    getTicketCategoryList();
    getVendorsList();
   },[]);
 //console.log("ordersDetails",ordersDetails)
 //function to get brand details

  const getTournamentDetails = async () => {
    setIsLoading(true);

    const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
    if (response?.data?.status && response?.data?.data?.data) {
      setTournamentList(response.data.data.data);
      setIsLoading(false);
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      alert("Something went wrong! Please try again");
    }
  }

 const getVendorsList = async () => {
  setIsLoading(true);
  const response = await API.get(`inventory/get_vendors/`);
  if (response.data.status) {
    setVendorsList(response.data.data !== null ? response.data.data : []);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}

 const getSourcesDetails = async (pageNo) => {
  setIsLoading(true);
   let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0 ;
   let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
   let orderRef = orders.orderRef;
   let tournament = orders.tournament;
   let matches = orders.matches;
   let client = orders.client;
   let salesPoc = orders.salesPoc;
   let ticketCategory = orders.ticketCategory;
   let status= orders.status;
   let requirement= orders.requirement;
   let inventoryId = orders.inventoryId;
   let vendor_origin = selectedVendorOrigin;
   let sources = "";
   let invoiceNumber = orders.invoiceNumber;
 
     const response = await API.get(`inventory/view_inventory/${pageCount}?inventory_id=${inventoryId}&tournament=${tournament}&sources=${sources}&matches=${matches}&ticket_category=${ticketCategory}&invoice_status=${status}&supplier_origin=${selectedVendorOrigin}&invoice_number=${invoiceNumber}&origin=`)
       // console.log("Response",response.data.data)
          if (response.data.status && response.data.data) {
            let total_records = response.data.data.total_records;
            setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
           setCurrentPage(activePageIndex);
           setOrdersDetails(response.data.data.data);
           if(isInitial){
             setInitialData(response.data.data);
             setIsInitial(false);
           }
           setIsLoading(false);
           setErrorMsg("")
        }
        else {
            setIsLoading(false);
            setCurrentPage(1);
            setErrorMsg(response.data.message)
            alert("Something went wrong! Please try again");
           // window.location.reload();
        }
 }

 function formatDate(date) {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  let newDate = `${da}-${mo}-${ye}`
  return newDate;
}

 function formatDate1(date) {
  let newDate;
  var inputDateVal = date;
    if (inputDateVal != null && inputDateVal != '') {
      var dateArr = inputDateVal.split("-");
      var inputDate = new Date('"' + dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0] + '"').setHours(0, 0, 0, 0);

      var toDay = new Date().setHours(0, 0, 0, 0);

      if (inputDate > toDay) {
       // console.log("Date is a future date");
        newDate=true;
      } else if (inputDate == toDay) {
      //  console.log("Date is equal to today");
        newDate=true;
      } else {
      //  console.log("Date is a past date");
        newDate=false;
      }
    }
  return newDate;
}

const getMatchList = async () => {
  setIsLoading(true);
  const response = await API.get('inventory/get_match_list');
  if (response.data.status) {
    // let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
    setMatchList(response.data.data);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}

const getTicketCategoryList = async () => {
  setIsLoading(true);
  const response = await API.get('inventory/get_ticket_category_list');
  if (response.data.status) {
    setTicketCategoryList(response.data.data);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}

const handleVendorChange = (newValue, index) => {
  setSelectedVendor(newValue);
  setSelectedVendorOrigin(newValue.origin);
  setSelectedVendorError(null);
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  const exportDetails = async () => {
    setIsExportLoading(true);
    const reportData = []
    let newOrderDetails;
    let tournament = orders.tournament;
   let matches = orders.matches;
   let ticketCategory = orders.ticketCategory;
   let status= orders.status;
   let inventoryId = orders.inventoryId;
   let sources = "";
   let invoiceNumber = orders.invoiceNumber;
 
     const response = await API.get(`inventory/view_inventory/0?inventory_id=${inventoryId}&tournament=${tournament}&sources=${sources}&matches=${matches}&ticket_category=${ticketCategory}&invoice_status=${status}&supplier_origin=${selectedVendorOrigin}&invoice_number=${invoiceNumber}&origin=&export=1`)
       if (response.data.status && response.data.data) {
           newOrderDetails = response.data.data.data;
           setIsExportLoading(false);
           setErrorMsg("")
        }
        else {
          setIsExportLoading(false);
            alert("Something went wrong! Please try again");
        }
          newOrderDetails?.map((s, i) => {
              const reportDataObj = {}
              reportDataObj['Inventory Ref No'] = s.inventory_id
              reportDataObj['Tournament'] = s.name
              reportDataObj['Vendor'] = s.source_company_name
              reportDataObj['Invoice Status'] = s.invoice_status
              reportDataObj['Invoice No'] = s.invoice_number
              reportDataObj['Invoice Date'] = s.invoice_date
              reportDataObj['Match'] = s.teams+ ', ' +formatDate(s.match_date) + ', ' +s.city 
              reportDataObj['Stand'] = s.category_name
              reportDataObj['Count'] = s.count
              reportDataObj['Purchase Price'] = s.purchase_price
              reportDataObj['Service Charge'] = s.service_charge
              reportDataObj['GST'] = s.service_charge * 0.18
              reportDataObj['Is B2C'] = s.is_b2c == "0" ? "No" : "Yes"
              reportDataObj['Courier Charges'] = s.courier_charge
              reportData.push(reportDataObj)
            })

            const data = reportData
            const fileName = "Sources"
            const exportType = 'xls'

            if (data) {
              exportFromJSON({ data, fileName, exportType })
            }
  }

//console.log("totalRecords", totalRecords)
console.log("tournament List", tournamentList)
  return ( 
    
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
            <Box>
              <Box className="text-right" px={2}>
                <Button mx={2} variant="contained" color="primary" disabled={isExportLoading ? true : false} onClick={()=> exportDetails()}>{isExportLoading ? <CircularProgress color="inherit"/> : "Export to excel"}</Button> &nbsp; 
                <Button mx={2} variant="contained" color="primary" onClick={toggleSearch}>Advanced Search</Button> &nbsp; 
                <NavLink to="/sources"><Button  variant="contained" color="secondary">Add Inventory</Button></NavLink>
              </Box>
              {searchBox ? (
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="mt-3"><h5>Search By</h5></Grid>
                <Grid item xs={12} md={2.5} >
                    <label className="text-xs">Inventory Ref No </label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Inventory Ref No"
                        variant="outlined"
                        name="inventoryId"
                        value={orders.inventoryId}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={2.5} >
                    <label className="text-xs">Tournament </label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Tournament"
                        variant="outlined"
                        name="tournament"
                        select
                        value={orders.tournament}
                        onChange={handleInputChange}
                    >
                      {tournamentList.map((item,index)=>
                      <MenuItem key={index} value={item.origin}>{item.name}</MenuItem>
                      )}
                      </TextField>
                </Grid>
                <Grid item xs={12} md={2.5} >
                  <label className="text-xs">Matches </label>
                 <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={matchList}
                   // value={orders.matches}
                    getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control mt-1"
                        placeholder="Matches"
                      />
                    )}
                    onChange={(event, newValue) => {
                       setOrders({...orders, matches: newValue?.origin ? newValue.origin : ""});
                     // getTicketCategoryList1(newValue.venue, index, newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.5} >
                    <label className="text-xs">Ticket category </label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Ticket category"
                        variant="outlined"
                        name="ticketCategory"
                        value={orders.ticketCategory}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} md={2} >
                    <label className="text-xs">Invoice Status</label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Invoice Status"
                        variant="outlined"
                        name="status"
                        select
                        value={orders.status}
                        onChange={handleInputChange}
                    >
                      <MenuItem value={"proforma"}>Proforma</MenuItem>
                    <MenuItem value={"invoice"}>Invoice</MenuItem>
                    </TextField>
                </Grid>
                {/* <Grid container spacing={2}> */}
                <Grid item xs={12} md={3} className="mt-3">
                <label>Select vendor <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                <FormControl fullWidth>
                   <Autocomplete
                        className={selectedVendorError !== null ? "inputFieldLocation mui-error mt-1" : "inputFieldLocation mt-1"}
                        size="small"
                        id="free-solo-demo"
                        freeSolo
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={vendorsList}
                        value={selectedVendor}
                        getOptionLabel={(option) => option.company_name}
                        renderOption={(props, option) => (
                          <li {...props} key={option.origin}>
                            {option.company_name}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            className="inputboxremark"
                            placeholder="Choose a vendor..."
                          />
                        )}
                        onChange={(event, newValue) => {
                          let obj = {origin: "", company_name: ""}
                          console.log("newValue", newValue)
                          if(newValue === null){
                            setSelectedVendor(null);
                            setSelectedVendorOrigin("");
                          }
                          else{
                            handleVendorChange(newValue);
                          }
                        }}
                      />
                  {selectedVendorError !== null && <FormHelperText style={{ color: "red" }}>{selectedVendorError}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="mt-3">
                    <label className="text-xs">Invoice No </label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Invoice No"
                        variant="outlined"
                        name="invoiceNumber"
                        value={orders.invoiceNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
              {/* </Grid> */}
                
                <Grid item xs={12} md={12} className='mt-3 text-right'>
                  <Button  variant="contained" color="primary" onClick={()=> getSourcesDetails()}>Search</Button>
                  <Button  variant="contained" color="error" 
                    onClick={()=> {setOrdersDetails(initialData); setOrders({...orders, inventoryId: "", tournament: 1,
                                    matches: "", ticketCategory: "", status: ""})}} className="clear-btn">
                      Clear Filter</Button>
                </Grid>
              </Grid>
              ): " "}
            </Box>
            <Box pt={6} pb={3} className="order-table">
                
              <Grid container spacing={6}>
                <Grid item xs={12}>
                
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Inventory
                      </MDTypography>
                    </MDBox>
                    {(!isLoading && ordersDetails.length > 0) ?
                    <Box>
                    <DataTable
                     entriesPerPage={false}
                     pagination={false}
                     showTotalEntries={false}
                          table={{
                              columns: [
                              { Header: "action", accessor: "action", align: "center" },
                              { Header: "Inventory Ref No", accessor: "inventory_ref_no"},
                              { Header: "Invoice No", accessor: "invoice_no"},
                              { Header: "Tournament", accessor: "tournament"},
                              { Header: "Vendor", accessor: "vendor"},
                              { Header: "Matches", accessor: "matches"},
                              { Header: "Invoice Status", accessor: "status"},
                              { Header: "Invoice Stock", accessor: "stock"},
                              { Header: "Stand", accessor: "stand"},
                              ],
                              rows: 
                              ordersDetails.map(item => (
                                {
                                  
                                  action:(
                                    <>
                                    <Button
                                      className="text-primary"
                                      id="demo-positioned-button"
                                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                      onClick={(e) => handleClick(e, item.origin)}
                                    >
                                    <MoreVertIcon />
                                    </Button>
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <Link to={'/sources?'+ selectedOrigin} target="_blank"><MenuItem>Edit</MenuItem></Link>
                                    </Menu>
                                    </>
                                  //   <Link to={'/view-orders?'+ item.order_ref_no}>
                                  //     <Tooltip title="Edit" arrow>
                                  //         <Button size="small" variant="contained" color="primary">
                                  //             <ModeOutlinedIcon />
                                  //         </Button>
                                  //     </Tooltip>
                                  // </Link>
                                  ),
                                  inventory_ref_no: item?.inventory_id,
                                  invoice_no: item?.invoice_number,
                                  tournament: item.name,
                                  vendor: item.source_company_name,
                                  matches: item.teams+', '+ formatDate(item.match_date),
                                  status: capitalizeFirstLetter(item?.invoice_status),
                                  stock: item?.count,
                                  stand: item?.category_name,
                                } 
                              ))
                          }}
                          />
                      
                    {totalRecords > 1 && (
                      <Box className="py-3 mb-3" >
                        <Stack spacing={2} alignItems="end">
                          <Pagination className="pagination" page={currentPage} count={totalRecords} onChange={(e, index) => getSourcesDetails(index)} color="primary" />
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  : !isLoading && ordersDetails.length === 0 ? 
                  (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                  <h5>{errorMsg ? errorMsg : "No orders found..."}</h5>
                  </Stack>)
                  :
                  <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="success" />
                  </Stack>
                  }
                </Card>
                
              </Grid>
              
              </Grid>
            </Box>
          </Box>
        </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewSources;
