import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import Card from "@mui/material/Card";

// Dashboard components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import API from 'apiConfig';
import { FormHelperText, LinearProgress, Stack, Autocomplete, Checkbox, Typography } from '@mui/material';
import DashboardNavbar from "common/Navbars/DashboardNavbar";

function Sources() {
    let currentUrl = window.location.href.split("?");
    let origin = currentUrl[1];

  const [age, setAge] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditStarts, setIsEditStarts] = useState(false);
  const [matchList, setMatchList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorOrigin, setSelectedVendorOrigin] = useState(null);
  const [selectedVendorError, setSelectedVendorError] = useState(null);
  const [proformaOrInvoice, setProformaOrInvoice] = useState("Select");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tournament, setTournament] = useState("");
  const [tournamentList, setTournamentList] = useState([]);
  const [isB2C, setIsB2C] = useState(false);
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [IGST, setIGST] = useState(0);
  const [forms, setForms] = useState([{ id: 0, name: "", count: "", purchasePrice: "", serviceCharge: 0, gst: 0,
                                        courierCharge: 0, ticketCategoryList: [], selectedMatch : "", 
                                        selectedMatchOrigin: "", selectedDate: "", selectedTicketCategory: "", 
                                        ticketCategory: "", vendor: "" }]);
  const [formsError, setFormsError] = useState([{ id: 0, name: null, count: null, purchasePrice: null, ticketCategoryList: null,
                                                  serviceCharge: null, gst: null, courierCharge: null, 
                                                  selectedMatch : null, selectedMatchOrigin: null, selectedDate: null, 
                                                  selectedTicketCategory: null, ticketCategory: null, vendor: null }]);

  useEffect(() => {
    getTournamentDetails();
    getMatchList(0);
    getVendorsList();
    getSourcesDetails();
    // getTicketCategoryList();
  }, [])

  const getTournamentDetails = async () => {
    setIsLoading(true);

    const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
    if (response?.data?.status && response?.data?.data?.data) {
      setTournamentList(response.data.data.data);
      setIsLoading(false);
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      alert("Something went wrong! Please try again");
    }
  }

  function formatDate1(date) {
    let newDate;
    var inputDateVal = date;
    if (inputDateVal != null && inputDateVal != '') {
      var dateArr = inputDateVal.split("-");
      var inputDate = new Date('"' + dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0] + '"').setHours(0, 0, 0, 0);

      var toDay = new Date().setHours(0, 0, 0, 0);

      if (inputDate > toDay) {
        // console.log("Date is a future date");
        newDate = true;
      } else if (inputDate == toDay) {
        //  console.log("Date is equal to today");
        newDate = true;
      } else {
        //  console.log("Date is a past date");
        newDate = false;
      }
    }
    return newDate;
  }

  const getSourcesDetails = async (pageNo) => {
    setIsLoading(true);
   
       const response = await API.get(`inventory/view_inventory/?inventory_id=&tournament=&sources=&matches=&ticket_category=&invoice_status=&origin=${origin}`)
         // console.log("Response",response.data.data)
            if (response.data.status && response.data.data) {
              let item = response.data.data.data[0]
              setSelectedVendor({origin: item.sources, company_name: item.source_company_name});
              setSelectedVendorOrigin(item.sources);
              setProformaOrInvoice(item.invoice_status);
              setInvoiceNumber(item.invoice_number);
              setSelectedDate(item.invoice_date);
              setTournament(item.match_tournament);
              let matchObj= {
                "origin": item.match_origin,
                "tournament": item.match_tournament,
                "match_date": item.match_date,
                "teams": item.teams,
                "venue": item.match_venue,
                "name": item.name
            }
              // let onlyForm = [{ id: 0, name: "", count: item.count, purchasePrice: item.purchase_price, 
              // ticketCategoryList: [], selectedMatch : matchObj, selectedMatchOrigin: item.match_origin, 
              // selectedDate: "", selectedTicketCategory: "", ticketCategory: "", vendor: "" }];
              // setForms(onlyForm)

              getInitialTicketCategoryList(item.match_venue, 0, matchObj, item)
            //   //let total_records = response.data.data.total_records;
            //   //setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
            //  // setCurrentPage(activePageIndex);
            //  setOrdersDetails(response.data.data);
            //  if(isInitial){
            //    setInitialData(response.data.data);
            //    setIsInitial(false);
            //  }
            //  setIsLoading(false);
            //  setErrorMsg("")
          }
          else {
              // setIsLoading(false);
              // setCurrentPage(1);
              // setErrorMsg(response.data.message)
              // alert("Something went wrong1! Please try again");
             // window.location.reload();
          }
   }
  // console.log(forms)
  const getMatchList = async (origin) => {
   // setIsLoading(true);
    const response = await API.get(`inventory/get_match_list/${origin}`);
    if (response.data.status) {
      //let data = response.data.data.filter(x => formatDate1(formatDate(x.match_date)) === true);
      let data = response.data.data;
      setMatchList(data);
     // setIsLoading(false);
    }
  }

  const getInitialTicketCategoryList = async (venue, index, newValue, item) => {
    setIsLoading(true);
    const newArray = [...forms];
    const newArrayError = [...formsError];
     // let onlyForm = [{ id: 0, name: "", count: item.count, purchasePrice: item.purchase_price, 
              // ticketCategoryList: [], selectedMatch : matchObj, selectedMatchOrigin: item.match_origin, 
              // selectedDate: "", selectedTicketCategory: "", ticketCategory: "", vendor: "" }];
              // setForms(onlyForm)
    let ticketCategoryObj = {
      "origin": item.ticket_category_origin,
      "stadium": item.stadium,
      "city": item.city,
      "category_name": item.category_name
  }

    newArray[index] = { ...newArray[index], count: item.count, purchasePrice: item.purchase_price,
                      serviceCharge: item.service_charge, gst: item.is_b2c === "1" ? Number(0).toFixed(2) : (Number(item.service_charge) * 18/100).toFixed(2),
                      courierCharge: item.courier_charge, 
                      selectedMatch: newValue, selectedMatchOrigin: newValue.origin,
                      selectedTicketCategory: ticketCategoryObj, ticketCategory: item.ticket_category_origin }
    newArrayError[index] = { ...newArrayError[index], count: null, purchasePrice: null, serviceCharge: null, 
                            gst: null, courierCharge: null, selectedMatch: null, selectedMatchOrigin: null,
                            selectedTicketCategory: null, ticketCategory: null }

    const response = await API.get(`inventory/get_ticket_category_list/${venue}`);
    if (response.data.status) {
      //setTicketCategoryList(response.data.data);
      newArray[index] = { ...newArray[index], ticketCategoryList: response.data.data }
      newArrayError[index] = { ...newArrayError[index], ticketCategoryList: null }
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
    setIsB2C(item.is_b2c === "0" ? false : true);
    setForms(newArray);
    setFormsError(newArrayError);
    setIsEditStarts(true);
  }

  const getTicketCategoryList = async (venue, index, newValue) => {
    setIsLoading(true);
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArray[index] = { ...newArray[index], selectedMatch: newValue, selectedMatchOrigin: newValue.origin }
    newArrayError[index] = { ...newArrayError[index], selectedMatch: null, selectedMatchOrigin: null }

    const response = await API.get(`inventory/get_ticket_category_list/${venue}`);
    if (response.data.status) {
      //setTicketCategoryList(response.data.data);
      newArray[index] = { ...newArray[index], ticketCategoryList: response.data.data }
      newArrayError[index] = { ...newArrayError[index], ticketCategoryList: null }
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }

    setForms(newArray);
    setFormsError(newArrayError);
    setIsEditStarts(true);
  }

  const getVendorsList = async () => {
    setIsLoading(true);
    const response = await API.get(`inventory/get_vendors/`);
    if (response.data.status) {
      setVendorsList(response.data.data !== null ? response.data.data : []);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  const handleChange = (event) => {
    setAge(event.target.value);
    setIsEditStarts(true);
  };

  const handleTicketCategoryChange = (newValue, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArray[index] = { ...newArray[index], selectedTicketCategory: newValue, ticketCategory: newValue.origin }
    newArrayError[index] = { ...newArrayError[index], selectedTicketCategory: null, ticketCategory: null }

    setForms(newArray);
    setFormsError(newArrayError);
    setIsEditStarts(true);
  }

  const handleVendorChange = (newValue, index) => {
    setSelectedVendor(newValue);
    setSelectedVendorOrigin(newValue.origin);
    setSelectedVendorError(null);
  }

  const handleCountChange = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], count: null }
    setFormsError(newArrayError);
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 5) {
      newArray[index] = { ...newArray[index], count: onlyNums }
      setForms(newArray);
    } else if (onlyNums.length === 5) {
      newArray[index] = { ...newArray[index], count: onlyNums }
      setForms(newArray);
    }
    setIsEditStarts(true);
  }

  const handlePurchasePriceChange = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], purchasePrice: null }
    setFormsError(newArrayError);
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    const decimalVal = onlyNums.replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1'); //limit 2 numbers after decimal point
    if (onlyNums.length < 11) {
      newArray[index] = { ...newArray[index], purchasePrice: decimalVal }
      setForms(newArray);
    } else if (onlyNums.length === 11) {
      newArray[index] = { ...newArray[index], purchasePrice: decimalVal }
      setForms(newArray);
    }
    setIsEditStarts(true);
  }

  const handleServiceCharge = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], serviceCharge: null }
    setFormsError(newArrayError);
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    const decimalVal = onlyNums.replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1'); //limit 2 numbers after decimal point
    if (onlyNums.length < 11) {
      newArray[index] = { ...newArray[index], serviceCharge: decimalVal }
      setForms(newArray);
    } else if (onlyNums.length === 11) {
      newArray[index] = { ...newArray[index], serviceCharge: decimalVal }
      setForms(newArray);
    }
    setIsEditStarts(true);
  }

  const handleGst = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], gst: null }
    setFormsError(newArrayError);
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    const gstVal = onlyNums.replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1'); //limit 2 numbers after decimal point
   if (onlyNums.length < 11) {
      newArray[index] = { ...newArray[index], gst: gstVal }
      setForms(newArray);
    } else if (onlyNums.length === 11) {
      newArray[index] = { ...newArray[index], gst: gstVal }
      setForms(newArray);
    }
    setIsEditStarts(true);
  }

  const handleCourierCharge = (e, index) => {
    const newArray = [...forms];
    const newArrayError = [...formsError];

    newArrayError[index] = { ...newArrayError[index], courierCharge: null }
    setFormsError(newArrayError);
    const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
    const decimalVal = onlyNums.replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1'); //limit 2 numbers after decimal point
    if (onlyNums.length < 11) {
      newArray[index] = { ...newArray[index], courierCharge: decimalVal }
      setForms(newArray);
    } else if (onlyNums.length === 8) {
      newArray[index] = { ...newArray[index], courierCharge: decimalVal }
      setForms(newArray);
    }
    setIsEditStarts(true);
  }

  const onFormAddition = () => {
    setForms([...forms, { id: forms.length, name: "", count: "", purchasePrice: "", ticketCategoryList: [],
                          serviceCharge: 0, gst: 0, courierCharge: 0, 
                          selectedMatch : "", selectedMatchOrigin: "", selectedDate: "", 
                          selectedTicketCategory: "", ticketCategory: "", vendor: "" }]);
    setFormsError([...formsError, { id: forms.length, name: null, count: null, purchasePrice: null, ticketCategoryList: null,
                                    serviceCharge: null, gst: null, courierCharge: null, 
                                    selectedMatch : null, selectedMatchOrigin: null, selectedDate: null, 
                                    selectedTicketCategory: null, ticketCategory: null, vendor: null }])
  }
  const onFormDelete = (index) => {
    //  let form = forms;
    let newForm = forms.filter(item => item.id !== index);
    let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
    // console.log(oneMore,"oneMore")
    setForms(oneMore);

    let newFormError = formsError.filter(item => item.id !== index);
    let oneMoreError = newFormError.map((x, index) => { return ({ ...x, id: index }) })
    // console.log(oneMore,"oneMore")
    setFormsError(oneMoreError);
  }

  const checkValidation = () => {
    setIsEditStarts(true);

    let vendorErr = selectedVendor === null ? "Please select a vendor" : null;
    // if (vendorErr) {
    //   setSelectedVendorError(vendorErr);
    //   return;
    // }
    if (
      //forms.filter(x => x.name === "").length > 0 ||
      // forms.filter(x => alphaNumericValidator(x.name) !== null).length > 0 ||
      vendorErr ||
      forms.filter(x => x.selectedMatchOrigin === "").length > 0 ||
      forms.filter(x => x.count === "").length > 0 ||
      forms.filter(x => x.purchasePrice === "").length > 0 ||
      forms.filter(x => x.ticketCategory === "").length > 0) 
       {
        const newArrayError = [...formsError];
        let countErrors = forms.filter(x => x.count === "")
        let errors1 = countErrors?.map((item,index)=> 
        newArrayError[item.id] = { ...newArrayError[item.id], count: "Please enter count" }
        )
        let matchErrors = forms.filter(x => x.selectedMatchOrigin === "")
        let errors2 = matchErrors?.map((item,index)=> 
        newArrayError[item.id] = { ...newArrayError[item.id], selectedMatch: "Please select match" }
        )
        let ticketCategoryErrors = forms.filter(x => x.selectedTicketCategory === "")
        let errors3 = ticketCategoryErrors?.map((item,index)=> 
        newArrayError[item.id] = { ...newArrayError[item.id], selectedTicketCategory: "Please select ticketCategory" }
        )
        let purchasePriceErrors = forms.filter(x => x.purchasePrice === "")
        let errors4 = purchasePriceErrors?.map((item,index)=> 
        newArrayError[item.id] = { ...newArrayError[item.id], purchasePrice: "Please enter purchase price" }
        )
        setFormsError(newArrayError);
        setSelectedVendorError(vendorErr);
       // console.log("errors",errors1)
    //   alert("Please fill required details")
       return;
     }
    else {
      if(origin){
        onUpdateInventory();
      }
      else{
        onCreateInventory();
      }
      //console.log("Forms",forms)
    }
  }

  const onUpdateInventory = async () => {
    setIsLoading(true);
    let dataToSend = {
      "tournament": tournament,
      "sources": selectedVendorOrigin,
      "invoice_status": proformaOrInvoice === "Select" ? "" : proformaOrInvoice,
      "invoice_number": invoiceNumber,
      "invoice_date": selectedDate,
      "matches": forms.map(x => Number(x.selectedMatchOrigin)),
      "count": forms.map(x => Number(x.count)),
      "purchase_price": forms.map(x => Number(x.purchasePrice).toFixed(2)),
      "ticket_category": forms.map(x => Number(x.ticketCategory)),
      "service_charge": forms.map(x => Number(x.serviceCharge).toFixed(2)),
      "gst": forms.map(x => Number(x.gst).toFixed(2)),
      "courier_charge": forms.map(x => Number(x.courierCharge).toFixed(2)),
      "is_b2c": isB2C ? "1" : "0"
    }
    //  console.log("dataToSend",dataToSend)
    const response = await API.post(`inventory/update_inventory/${origin}`, dataToSend);
    if (response.data.status) {
      alert(`${response.data.message}`);
      window.location.reload();
      setIsLoading(false);
    }
    else if (!response.data.status) {
      alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      // console.log("Error")
    }
  }

  const onCreateInventory = async (item) => {
    setIsLoading(true);
    let dataToSend = {
      "tournament": tournament,
      "sources": selectedVendorOrigin,
      "invoice_status": proformaOrInvoice === "Select" ? "" : proformaOrInvoice,
      "invoice_number": invoiceNumber,
      "invoice_date": selectedDate,
      "matches": forms.map(x => Number(x.selectedMatchOrigin)),
      "count": forms.map(x => Number(x.count)),
      "purchase_price": forms.map(x => Number(x.purchasePrice).toFixed(2)),
      "ticket_category": forms.map(x => Number(x.ticketCategory)),
      "service_charge": forms.map(x => Number(x.serviceCharge).toFixed(2)),
      "gst": forms.map(x => Number(x.gst).toFixed(2)),
      "courier_charge": forms.map(x => Number(x.courierCharge).toFixed(2)),
      "is_b2c": isB2C ? "1" : "0"
    }
     // console.log("dataToSend",dataToSend)
    const response = await API.post('inventory/create_inventory', dataToSend);
    if (response.data.status) {
      alert(`${response.data.message}`);
      window.location.reload();
      setIsLoading(false);
    }
    else if (!response.data.status) {
      alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      // console.log("Error")
    }
  }

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }
    //  console.log("tournament",tournament)
      console.log("forms",forms)
    // console.log("selectedVendor",selectedVendor)
  return (
    <DashboardLayout>
       <DashboardNavbar />
      {isLoading ?
        (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <LinearProgress color="success" />
        </Stack>) : (
          <Card className='px-3 pb-5'>
            <Grid pt={2} container>
              <Grid item xs={12} md={12} className="px-1 mt-3">
                <h5>{origin ? "Update" : "Add"} Inventory</h5>
              </Grid>
              <Grid item xs={12} md={1.5} className="px-1 mt-3">
                <label>Select tournament</label>
                <span className="text-danger" style={{ fontSize: 15 }}> *</span>
              </Grid>
              <Grid item xs={12} md={4} className="px-1 mt-3">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="tournament"
                    value={tournament}
                    onChange={(e) => {setTournament(e.target.value); getMatchList(e.target.value)}}
                  >
                    {tournamentList.map((item, index) =>
                      <MenuItem key={index} value={item.origin}>{item.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={1.5} className="px-1 mt-3">
                <label>Select vendor</label>
                <span className="text-danger" style={{ fontSize: 15 }}> *</span>
              </Grid>
              <Grid item xs={12} md={4} className="px-1 mt-3">
                <FormControl fullWidth>
                   <Autocomplete
                        className={selectedVendorError !== null ? "inputFieldLocation mui-error" : "inputFieldLocation"}
                        size="small"
                        id="free-solo-demo"
                        freeSolo
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={vendorsList}
                        value={selectedVendor}
                        getOptionLabel={(option) => option.company_name}
                        renderOption={(props, option) => (
                          <li {...props} key={option.origin}>
                            {option.company_name}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            className="inputboxremark"
                            placeholder="Choose a vendor..."
                          />
                        )}
                        onChange={(event, newValue) => {
                          let obj = {origin: "", company_name: ""}
                          console.log("newValue", newValue)
                          if(newValue === null){
                            setSelectedVendor(null);
                          }
                          else{
                            handleVendorChange(newValue);
                          }
                        }}
                      />
                  {selectedVendorError !== null && <FormHelperText style={{ color: "red" }}>{selectedVendorError}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2} className="px-1 mt-3">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={proformaOrInvoice}
                    onChange={(e)=> setProformaOrInvoice(e.target.value)}
                  >
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"proforma"}>Proforma</MenuItem>
                    <MenuItem value={"invoice"}>Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2} className="px-1 mt-3">
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    className="inputboxremark"
                    placeholder="Ref Number"
                    value={invoiceNumber}
                    onChange={(e)=> setInvoiceNumber(e.target.value)}
                  />
              </Grid>

              <Grid item xs={12} md={2} className="px-1 mt-3">
                <TextField
                    id="outlined-basic"
                    type={'date'}
                    variant="outlined"
                    size="small"
                    className="inputboxremark"
                    placeholder="Select Date"
                    value={selectedDate}
                    onChange={(e)=> setSelectedDate(e.target.value)}
                  />
              </Grid>
            </Grid>

            <Grid container >
              {forms.map((item, index) =>
                <>
                  <Grid item xs={12} md={1} className={index === 0 ? "px-1 mt-5" : "px-1 mt-3"}>
                    <label>From {index + 1}</label>
                    <span className="text-danger" style={{ fontSize: 15 }}> *</span>
                  </Grid>
                  <Grid item xs={12} md={2} className="px-1 mt-3">
                   {index === 0 && (<label className='text-xs'>Select Match</label>)}
                   <Autocomplete
                    className={(isEditStarts && formsError[item.id].selectedMatch) ? "inputFieldLocation mui-error" : "inputFieldLocation"}
                    size="small"
                    id="free-solo-demo"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={matchList}
                    value={item.selectedMatch ? item.selectedMatch : null}
                    getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="inputboxremark"
                        placeholder="Choose a match..."
                      />
                    )}
                    onChange={(event, newValue) => {
                     // setSelectedMatch(newValue);
                    // setSelectedMatchOrigin(newValue.origin);
                    if(newValue === null){
                      const newArray = [...forms];
                      newArray[item.id] = { ...newArray[item.id], 
                                                selectedMatch: "",
                                                selectedMatchOrigin: "",
                                                ticketCategoryList: []
                                          }
                      setForms(newArray);
                    }
                    else{
                      getTicketCategoryList(newValue.venue, index, newValue);
                    }
                    }}
                  />
                   {(isEditStarts && formsError[item.id].selectedMatch) && <FormHelperText style={{ color: "red" }}>{formsError[item.id].selectedMatch}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12} md={1} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>Count</label>)}
                    <TextField
                      id="outlined-basic"
                      placeholder="Count"
                      name="count"
                      variant="outlined"
                      value={item.count}
                      onChange={(e) => handleCountChange(e, item.id)}
                      error={isEditStarts ? (formsError[item.id].count ? true : false) : false}
                      helperText={isEditStarts ? (formsError[item.id].count ? "Please enter count" : null) : null}
                    />
                  </Grid>
                  <Grid item xs={12} md={1} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>Purchase Price</label>)}
                    <TextField
                      id="outlined-basic"
                      placeholder="Purchase Price"
                      name="purchasePrice"
                      variant="outlined"
                      value={item.purchasePrice}
                      onChange={(e) => handlePurchasePriceChange(e, item.id)}
                      error={isEditStarts ? (formsError[item.id].purchasePrice ? true : false) : false}
                      helperText={isEditStarts ? (formsError[item.id].purchasePrice ? "Please enter purchase price" : null) : null}
                    />
                  </Grid>
                  <Grid item xs={12} md={1} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>Service Charge</label>)}
                    <TextField
                      id="outlined-basic"
                      placeholder="Service Charge"
                      name="serviceCharge"
                      variant="outlined"
                      value={item.serviceCharge}
                      onChange={(e) => handleServiceCharge(e, item.id)}
                      error={isEditStarts ? (item.serviceCharge === "" ? true : false) : false}
                      helperText={isEditStarts ? (item.serviceCharge === "" ? "Please enter service charge" : null) : null}
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>GST <Checkbox checked={isB2C} onChange={()=> setIsB2C(!isB2C)}/> B2C</label>)}
                    <TextField
                      id="outlined-basic"
                      placeholder="GST"
                      name="gst"
                      variant="outlined"
                      value={item.gst}
                      //disabled
                      onChange={(e) => handleGst(e, item.id)}
                      error={isEditStarts ? (item.gst === "" ? true : false) : false}
                      helperText={isEditStarts ? (item.gst === "" ? "Please enter gst" : null) : null}
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>Postage/Courier Charge</label>)}
                    <TextField
                      id="outlined-basic"
                      placeholder="Postage/Courier Charge"
                      name="courierCharge"
                      variant="outlined"
                      value={item.courierCharge}
                      onChange={(e) => handleCourierCharge(e, item.id)}
                      error={isEditStarts ? (item.courierCharge === "" ? true : false) : false}
                      helperText={isEditStarts ? (item.courierCharge === "" ? "Please enter Courier charge" : null) : null}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className="px-1 mt-3">
                  {index === 0 && (<label className='text-xs'>Select category</label>)}
                    <FormControl fullWidth>
                    <Autocomplete
                        className={(isEditStarts && formsError[item.id].selectedTicketCategory) ? "inputFieldLocation mui-error" : "inputFieldLocation"}
                        size="small"
                        id="free-solo-demo"
                        freeSolo
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={item.ticketCategoryList ? item.ticketCategoryList : []}
                        value={item.selectedTicketCategory ? item.selectedTicketCategory : null}
                        getOptionLabel={(option) => option?.category_name}
                        renderOption={(props, option) => (
                          <li {...props} key={option.origin}>
                            {option?.category_name}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            className="inputboxremark"
                            placeholder="Choose a category..."
                          />
                        )}
                        onChange={(event, newValue) => {
                        //  console.log(newValue)
                        if(newValue === null){
                          const newArray = [...forms];
                          newArray[item.id] = { ...newArray[item.id], 
                                                    selectedTicketCategory: "",
                                                    ticketCategory: ""
                                              }
                          setForms(newArray);
                        }
                        else{
                          handleTicketCategoryChange(newValue, index);
                        }
                        }}
                      />
                      {(isEditStarts && formsError[item.id].selectedTicketCategory) && <FormHelperText style={{ color: "red" }}>Please select ticketCategory</FormHelperText>}
                    </FormControl>
                  </Grid>
                  {(item.id !== 0 && origin === undefined) && (
                    <Grid item xs={12} md={1} className="px-1 mt-3">
                      <Button size="small" className='btn-danger' variant="contained" color="error" onClick={() => onFormDelete(index)}>Delete</Button>
                    </Grid>
                  )}
                  {(item.id === 0 && origin === undefined) && (
                    <Grid item xs={12} md={1} className="px-1 mt-5">
                      <Button size="small" variant="contained" color="primary"  onClick={() => onFormAddition()}>Add</Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            <Grid container >
              <Grid item xs={12} md={3} className="px-2 mt-1 text-right">
                <label><h6><b>Total</b></h6></label>
              </Grid>
              <Grid item xs={12} md={1} className="px-2 mt-1 text-right">
                <label><h6><b>{forms.map(x => Number(x.count)).reduce((a, b) => a + b, 0)}</b></h6></label>
              </Grid>

            </Grid>
            {origin && (
            <Grid item xs={12}>
              <div className="invoice3-row">
                {/* <h6>ACCOUNTING ENTRY</h6> */}
                <Typography variant="h5" component="div" className="primary-text order-heading mb-2 mt-2">
                  Accounting Entry</Typography>
                <div className="col-12">
                  <table className="width-100 account-table-details-right" >
                    <tr>
                      <td><b>Ledger Name</b></td>
                      <td align="right"><b>Debit </b></td>
                      <td align="right"> <b>Credit </b></td>
                    </tr>
                    <tr>
                      <td>{selectedVendor?.company_name} </td>
                      <td>&nbsp;</td>
                      <td align="right">
                      {Number(forms.map(item=> Number(item.serviceCharge)).reduce((partialSum, a) => partialSum + a, 0) + 
                      forms.map(item=> Number(item.gst)).reduce((partialSum, a) => partialSum + a, 0) +
                      forms.map(x=>(Number(x.count) * Number(x.purchasePrice))).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)}
                      </td>
                    </tr>
                    {forms.map((x, index) => {
                      return (
                        <tr className="no-border">
                          <td>{x.selectedMatch.teams + " (" + x.selectedTicketCategory?.category_name + ") " + " - " + x.selectedMatch.match_date}</td>
                          <td align="right">{(Number(x.count) * Number(x.purchasePrice)).toFixed(2)}</td>
                          <td>&nbsp;</td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>Service Charge </td>
                      <td align="right">{forms.map(item=> Number(item.serviceCharge)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                      <td>&nbsp;</td>
                    </tr>
                    {/* {IGST !== 0 ? (
                      <tr>
                        <td>IGST</td>
                        <td>&nbsp;</td>
                        <td align="right">{IGST.toFixed(2)}</td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td>SGST</td>
                          <td>&nbsp;</td>
                          <td align="right">{SGST.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>CGST</td>
                          <td>&nbsp;</td>
                          <td align="right">{CGST.toFixed(2)}</td>
                        </tr>
                      </>
                    )} */}
                     <tr>
                        <td>GST Input</td>
                        <td align="right">{forms.map(item=> Number(item.gst)).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}</td>
                        <td>&nbsp;</td>
                      </tr>
                    {/* Round off */}
                    
                        {/* <tr>
                          <td>Round Off </td>
                          <td>&nbsp;</td>
                          <td align="right">00
                          </td>
                        </tr> */}
                    <tr>
                      <td><b>Total </b></td>
                      <td align="right"><b>
                      {Number(forms.map(item=> Number(item.serviceCharge)).reduce((partialSum, a) => partialSum + a, 0) + 
                      forms.map(item=> Number(item.gst)).reduce((partialSum, a) => partialSum + a, 0) +
                      forms.map(x=>(Number(x.count) * Number(x.purchasePrice))).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)}
                      </b></td>
                      <td align="right"><b>
                      {Number(forms.map(item=> Number(item.serviceCharge)).reduce((partialSum, a) => partialSum + a, 0) + 
                      forms.map(item=> Number(item.gst)).reduce((partialSum, a) => partialSum + a, 0) +
                      forms.map(x=>(Number(x.count) * Number(x.purchasePrice))).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)}
                      </b></td>
                    </tr>
                  </table>
                </div>
              </div>
            </Grid>
            )}

            <Grid container >

              <Grid item xs={12} md={12} className="px-1 mb-3 mt-3">
                <Button variant="contained" color="secondary" onClick={() => checkValidation()}>{origin ? "Update" : "Submit"}</Button>
              </Grid>
            </Grid>

          </Card>
        )}
    </DashboardLayout>
  );
}

export default Sources;
