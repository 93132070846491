import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import { Button, Row, Stack } from 'react-bootstrap';
import DashboardNavbar from 'common/Navbars/DashboardNavbar';
import API from 'apiConfig';
import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { Typeahead } from 'react-bootstrap-typeahead';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [invList, setInvList] = useState([]);
  const [colHead, setColHead] = useState([]);
  const [colData, setColData] = useState([]);
  const [totalInHand, setTotalInHand] = useState([]);
  const [orderHead, setOrderHead] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [matchHeadings, setMatchHeadings] = useState([]);
  const [totalRequirement, setTotalRequirement] = useState([]);
  const [tournamentList, setTournamentList] = useState([]);
  const [tournament, setTournament] = useState([]);
  const [matchList, setMatchList] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState([]);

  useEffect(() => {
    getOrdersList(0,0);
    getTournamentDetails();
    getMatchList(0);
  }, [])

  const getOrdersList = async (tournament_origin, match_origin) => {
    setIsLoading(true);
    const response = await API.get(`inventory/get_orders_list/${tournament_origin}/${match_origin}`);
    if (response.data.status) {
      //setOrdersList(response.data.data);
      setMatchHeadings(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined)?.map(x => x.orders_inventory.map(y => y.category_name)))
      setOrdersList(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined));
      setColHead(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined).filter(x => x?.inventory !== undefined)
        ?.map(item => Object.keys(item?.inventory)))
      setInvList(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined).filter(x => x?.inventory !== undefined))
      //  .map(item => Object.keys(item)))
      setColData(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined).filter(x => x?.inventory !== undefined)
        ?.map((item, index) => { return ({ id: index, data: Object.keys(item?.inventory).map((key) => item?.inventory[key]) }) }))
      setTotalInHand(response.data.data?.matches?.filter(x => x?.category !== undefined).map((item, index) => { return ({ id: index, data: Object.keys(item?.category).map((key) => item?.category[key]).map(z => z.count) }) }))
      //  console.log("Hi", response.data.data?.matches?.filter(x=> x?.orders && x?.orders !== undefined)
      //  ?.map(item => Object.keys(item?.orders)))
      setOrderHead(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined).filter(x => x?.orders && x?.orders !== undefined)
        ?.map(item => Object.keys(item?.orders)))
      setOrderData(response.data.data?.matches?.filter(x => x?.orders_inventory !== undefined).filter(x => x?.orders !== undefined)
        .map((item, index) => { return ({ id: index, data: Object.keys(item?.orders).map((key) => item?.orders[key]) }) }))
      setTotalRequirement(response.data.data?.matches?.filter(x => x?.count_orders !== undefined).map((item, index) => { return ({ id: index, data: Object.keys(item?.count_orders).map((key) => item?.count_orders[key]).map(z => z.count) }) }))
      //  console.log("data",response.data.data?.matches?.filter(x=> x?.category !== undefined).map((item,index) => {return ({id: index, data: Object.keys(item?.category).map((key) => item?.category[key]).map(z=> z.count)})}))
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  const getTournamentDetails = async () => {
    const response = await API.get(`inventory/get_tournaments?name&start_date&end_date&origin`)
    if (response?.data?.status && response?.data?.data?.data) {
      setTournamentList(response.data.data.data.map(x => { return ({ ...x, label: x.name }) }));
      //setIsLoading(false);
    }
    else {
      //setIsLoading(false);
      alert("Something went wrong! Please try again");
    }
  }

  const getMatchList = async (origin) => {
    const response = await API.get(`inventory/get_match_list/${origin}`);
    if (response.data.status) {
        setMatchList(response.data.data.map(x => { return ({ ...x, label: x.teams + ", " + formatDate(x.match_date) + ", " + x.venue }) }));
    }
}

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

  const copyTableFormat = (index) => {
    var urlField = document.querySelector(`#tableDataRound${index}`);
    // create a Range object
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(urlField);
    selection.removeAllRanges();
    selection.addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand('copy');
  }

  const onSearch = () => {
    let tournament_origin = tournament.length > 0 ? tournament[0].origin : 0;
    let match_origin = selectedMatch.length > 0 ? selectedMatch[0].origin : 0;
    getOrdersList(tournament_origin, match_origin);
  }
  //  console.log("ordersList", ordersList)
  // console.log("orderData", orderData)
  // console.log("colData", colData)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <p>Dashboard Coming soon</p> */}
      {!isLoading ? (
        <Card className='px-3 pb-5'>
          <Grid container className='mt-3 px-3' spacing={2}>
            <Grid item xs={12} md={3} >
              <label className="text-xs">Tournament </label>
              <Typeahead
                id="basic-example"
                options={tournamentList}
                placeholder="Choose a tournament..."
                selected={tournament}
                onInputChange={(text) => {
                  const newArray = [...tournament];
                  newArray[0] = { ...newArray[0], label: text }
                  setTournament(newArray);
                  // setCustomerOrigin(0);
                }}
                onChange={(selected) => {
                  if (selected[0] !== undefined) {
                    setTournament(selected);
                    getMatchList(selected[0]?.origin);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} >
              <label className="text-xs">Matches </label>
              <Typeahead
                id="basic-example"
                options={matchList}
                placeholder="Choose a Match..."
                selected={selectedMatch}
                onInputChange={(text) => {
                  const newArray = [...selectedMatch];
                  newArray[0] = { ...newArray[0], label: text }
                  setSelectedMatch(newArray);
                  // setCustomerOrigin(0);
                }}
                onChange={(selected) => {
                  if (selected[0] !== undefined) {
                    setSelectedMatch(selected)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} className='mt-xs-0 mt-md-3'>
              <Button className='btn btn-sm mt-md-3 mt-xs-0 py-2' onClick={() => onSearch()}>Search</Button>
            </Grid>
          </Grid>
          <Grid container>
            {ordersList?.map((item, index) =>
              <Grid item xs={12} md={12} lg={12} className="px-3">
                <div className='row'>
                  <div className='col-md-12 text-right'>
                    <Button className='btn btn-sm' onClick={() => copyTableFormat(index)} >Copy</Button>
                  </div>
                </div>
                <div className='row' id={`tableDataRound${index}`}>
                  <div className='col-md-5'>
                    <h5>Match: {item.details.teams}</h5>
                  </div>
                  <div className='col-md-3'>
                    <h6>Date: {formatDate(item.details.match_date)}</h6>
                  </div>
                  <div className='col-md-3'>
                    <h6>Venue: {item.details.venue}</h6>
                  </div>
                  <div className='col-md-12'>
                    <table border="1" width="100%" >
                      <thead>
                        <tr>
                          <th>Stock</th>
                          {/* {matchHeadings[index].map(x=>
                        {return <th>{x}</th>}
                      )} */}
                          {item?.orders_inventory?.map(x => x.category_name).map(y => {
                            { return <th>{y}</th> }
                          })}
                        </tr>
                      </thead>
                      {/* <thead> */}

                      {/* </thead> */}
                      <tbody>
                        <tr className='purchase-price'>
                          <th>Purchase Price</th>
                          {item?.orders_inventory?.map(x => x.purchase_price).map(y => {
                            { return <th style={{ textAlign: "right" }}>{y}</th> }
                          })}
                        </tr>
                        <tr><td colSpan="12"><b>From</b></td></tr>
                        {colHead[index]?.map((x, ind) =>
                          <tr>
                            <td> {x}</td>
                            {Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]?.length > 0 &&
                              Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                ?.length <= 1 ?
                              Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                ?.map(data =>
                                  item?.orders_inventory?.map(x => { return ({ category_name: x.category_name, purchase_price: x.purchase_price }) }).map(y =>
                                    <td style={{ textAlign: "right" }}>{(y.category_name === data.ticket_category && y.purchase_price === data.purchase_price) ? data?.count : 0}</td>
                                  )) :
                              // Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                              // ?.map(data => 
                              //   item?.orders_inventory?.map(x=> x.category_name).map(y=> 
                              //     y === data.ticket_category ?
                              //     <td>{data?.count}</td>
                              //     :null
                              // ))
                              // Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                              // .sort((a, b) => item?.orders_inventory?.map(x=> x.category_name).indexOf(a) - item?.orders_inventory?.map(x=> x.category_name).indexOf(b))
                              // ?.map(data =>
                              //   <td>{data?.count}</td>
                              // )
                              item?.orders_inventory?.map(y => {
                                {
                                  return <td style={{ textAlign: "right" }}>{
                                    Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                      .filter(z => z.purchase_price === y.purchase_price && z.ticket_category === y.category_name)[0]?.count ?
                                      Array(colData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                        .filter(z => z.purchase_price === y.purchase_price && z.ticket_category === y.category_name)[0]?.count :
                                      0
                                  }
                                  </td>
                                }
                              })
                            }
                          </tr>
                        )}
                        <tr><td colSpan="12"></td></tr>
                        <tr>
                          <td><b>Total in Hand</b></td>

                          {/* {totalInHand[index]?.data?.map((item, i) => {
                        return <td><b>{item}</b> </td>
                      })} */}
                          {/* map(data => 
                        item?.orders_inventory?.map(x=> x.category_name).map(y=> 
                      <td>{y=== data.ticket_category_origin ? data?.count : 0}</td>
                      )) */}
                          {item?.orders_inventory?.map(x => x.total_inventory).map(y => {
                            { return <td style={{ textAlign: "right" }}><b>{y}</b></td> }
                          })}
                        </tr>
                        <tr><td colSpan="12"></td></tr>
                        {orderHead[index]?.map((x, ind) =>
                          <tr>
                            <td> {x}</td>
                            {
                              // Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]?.length > 0 &&
                              // Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]?.length <= 1 ?
                              // Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                              // ?.map(data => 
                              //   item?.orders_inventory?.map(x=> x.category_name).map(y=> 
                              // <td style={{textAlign: "right"}}>{y=== data.ticket_category_origin ? data?.count : 0}</td>
                              // )):
                              // Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                              // ?.map(data => 
                              //   item?.orders_inventory?.map(x=> x.category_name).map(y=> 
                              //     y=== data.ticket_category_origin ?
                              //     <td>{data?.count}</td>
                              //     :null
                              // ))
                              item?.orders_inventory?.map(x => x).map(y => {
                                {
                                  return <td style={{ textAlign: "right" }}>{
                                    Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                      .filter(z => z.purchase_price === y.purchase_price && z.ticket_category_origin === y.category_name)[0]?.count ?
                                      Array(orderData[index])?.filter(dat => dat?.id === index)[0]?.data[ind]
                                        .filter(z => z.purchase_price === y.purchase_price && z.ticket_category_origin === y.category_name).reduce((partialSum, a) => partialSum + Number(a?.count), 0) :
                                      0
                                  }
                                  </td>
                                }
                              })
                            }
                          </tr>
                        )}


                        <tr><td colSpan="12"></td></tr>
                        {/* {totalRequirement[index]?.data?.length > 0 &&( */}
                        <tr>
                          <td><b>Total Requirment</b></td>
                          {item?.orders_inventory?.map(x => x.total_orders).map(y => {
                            { return <td style={{ textAlign: "right" }}><b>{y}</b></td> }
                          })}
                        </tr>
                        {/* )} */}
                        <tr><td colSpan="12"></td></tr>
                        {/* {(totalInHand[index]?.data?.length > 0 || totalRequirement[index]?.data?.length > 0) && ( */}
                        <tr>
                          <td><b>Balance</b></td>
                          {item?.orders_inventory?.map(x => Number(x.total_inventory) - Number(x.total_orders)).map(y => {
                            { return <td style={{ textAlign: "right" }}><b>{y}</b></td> }
                          })}
                          {/* {(totalInHand[index]?.data?.length > 0 && totalRequirement[index]?.data?.length > 0) ?
                      ( totalInHand[index]?.data?.map(function (num, idx) {
                        return <td><b>{Number(num) - Number(totalRequirement[index]?.data[idx])}</b> </td>
                        })): (totalInHand[index]?.data?.length > 0 && (totalRequirement[index]?.data?.length === 0 || totalRequirement[index]?.data?.length === undefined)) ?
                        totalInHand[index]?.data?.map(function (num, idx) {
                        return <td><b>{Number(num)}</b> </td>
                        }): ((totalInHand[index]?.data?.length === 0 || totalInHand[index]?.data?.length === undefined) && totalRequirement[index]?.data?.length > 0) ?
                        totalRequirement[index]?.data?.map(function (num, idx) {
                          return <td><b>{Number(num)}</b> </td>
                          }): " "} */}
                          {/* {totalInHand1.map((item, i) => {
                        return <td><b>20</b> </td>
                      })} */}
                        </tr>
                        {/* )} */}
                      </tbody>
                    </table>
                  </div>

                </div>
              </Grid>
            )}
            {/* <Grid item xs={12} md={12} lg={12} className="px-3 mt-5">
            <div className='row'>
              <div className='col-md-3 m-auto'>
                <h5>Match: Pak vs Aus</h5>
              </div>
              <div className='col-md-3 m-auto'>
                <h6>Date: 23 Jan, 22</h6>
              </div>
              <div className='col-md-3 m-auto'>
                <h6>Venue: Dubai</h6>
              </div>
              <div className='col-md-3 text-right mb-3'>
                <Button className='btn btn-sm'>Copy</Button>
              </div>
            </div>
            <table border="1" width="100%">
              <thead>
                <tr>
                  <th>Stock</th>
                  {matchHeading.map((item, i) => {
                    return <th>{item}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                <tr><td colSpan="12"><b>From</b></td></tr>
                <tr>
                  {matchRow.map((item, i) => {
                    return <td>{item}</td>
                  })}
                </tr>
              
                <tr><td colSpan="12"></td></tr>
                <tr>
                  <td><b>Total in Hand</b></td>
                 
                  {totalInHand.map((item, i) => {
                    return <td><b>20</b> </td>
                  })}
                </tr>
                <tr><td colSpan="12"></td></tr>
                <tr>
                  <td><b>Client 1</b></td>
                  {totalInHand.map((item, i) => {
                    return <td>20 </td>
                  })}
                </tr>
                

                <tr><td colSpan="12"></td></tr>
                <tr>
                  <td><b>Total Requirment</b></td>
                  {totalInHand.map((item, i) => {
                    return <td><b>20</b> </td>
                  })}
                  
                </tr>

                <tr><td colSpan="12"></td></tr>
                <tr>
                  <td><b>Balance</b></td>
                  {totalInHand.map((item, i) => {
                    return <td><b>20</b> </td>
                  })}
                </tr>
              </tbody>
            </table>
          </Grid> */}

          </Grid>

        </Card>) :
        (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <LinearProgress color="success" />
        </Stack>)}
    </DashboardLayout>
  );
}

export default Dashboard;
