import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom";
import {Grid, Box, Card, LinearProgress, Tooltip,Select, FormControl, TextField, MenuItem, Button} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';


// Data
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import axios from "axios";
import { alphaNumericValidator, emailValidator, phoneValidator } from "Validation";

function Members() {
  const resultPerPage = 20;
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [membersDetails, setMembersDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBox, setSearchBox] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [isEditStarts, setIsEditStarts] = useState(false);
  const [orders, setOrders] = useState({
    orderRef: "", tournament: 1, matches: "", client: "", salesPoc: "", ticketCategory: "", requirement: "", status: ""
  });
  // const [errorDetails, setErrorDetails] = useState({
  //   orderRef: null, tournament: null, matches: null, client: null, salesPoc: null, ticketCategory: null, requirement: null, status: null
  // });
  const [members, setMembers] = useState({
    type: "", companyName: "", countryCode: 'India (+91)', phone: "", email: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    type: null, companyName: null, countryCode: null, phone: null, email: null, 
  });

  const toggleSearch = () => {
    setSearchBox(!searchBox)
  }

  useEffect(() => {
    getMembersDetails();
    // getMatchList();
    // getTicketCategoryList();
   },[]);
 //console.log("ordersDetails",ordersDetails)
 //function to get brand details
 const getMembersDetails = async (pageNo) => {
  setIsLoading(true);
   let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0 ;
   let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
   let company_name = members.companyName;
   let email = members.email;
   let phone = members.phone;
   let member_type = members.type;
  
     const response = await API.get(`orders/get_members_list/${pageCount}?origin=&company_name=${company_name}&email=${email}&phone=${phone}&member_type=${member_type}`)
       // console.log("Response",response.data)
       if(response.data.message !== "No members found"){
          if (response.data.status && response?.data?.data?.data) {
            let total_records = response.data.data.total_records;
            setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
            setCurrentPage(activePageIndex);
            setMembersDetails(response.data.data.data);
           if(isInitial){
             setInitialData(response.data.data.data);
             setIsInitial(false);
           }
           setIsLoading(false);
           setErrorMsg("")
        }
        else {
            setIsLoading(false);
            setCurrentPage(1);
            setErrorMsg(response.data.message)
            alert("Something went wrong! Please try again");
           // window.location.reload();
        }
      }
      else{
        setIsLoading(false);
        setCurrentPage(1);
        setMembersDetails([])
      }
 }

 function formatDate(date) {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  let newDate = `${da}-${mo}-${ye}`
  return newDate;
}

//  function formatDate1(date) {
//   let newDate;
//   var inputDateVal = date;
//     if (inputDateVal != null && inputDateVal != '') {
//       var dateArr = inputDateVal.split("-");
//       var inputDate = new Date('"' + dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0] + '"').setHours(0, 0, 0, 0);

//       var toDay = new Date().setHours(0, 0, 0, 0);

//       if (inputDate > toDay) {
//        // console.log("Date is a future date");
//         newDate=true;
//       } else if (inputDate == toDay) {
//       //  console.log("Date is equal to today");
//         newDate=true;
//       } else {
//       //  console.log("Date is a past date");
//         newDate=false;
//       }
//     }
//   return newDate;
// }

// const getMatchList = async () => {
//   setIsLoading(true);
//   const response = await API.get('inventory/get_match_list');
//   if (response.data.status) {
//     // let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
//     setMatchList(response.data.data);
//     setIsLoading(false);
//   }
//   else {
//     setIsLoading(false);
//   }
// }

// const getTicketCategoryList = async () => {
//   setIsLoading(true);
//   const response = await API.get('inventory/get_ticket_category_list');
//   if (response.data.status) {
//     setTicketCategoryList(response.data.data);
//     setIsLoading(false);
//   }
//   else {
//     setIsLoading(false);
//   }
// }

const handleInputChange = (e) => {
  setMembers({ ...members, [e.target.name]: e.target.value });
  setErrorDetails({ ...errorDetails, [e.target.name]: null });
  setIsEditStarts(true);
}

const checkValidation = () => {
  var number = members.phone;
  var country = members.countryCode;
  var phoneNumber = country + " " + number;

  let companyError = members.companyName !== "" ? alphaNumericValidator(members.companyName) : null;
  let emailError = members.email !== "" ? emailValidator(members.email) : null;
  let phoneError = members.phone !== "" ? members.phone.length > 10 ? "Please enter a valid phone number." : phoneValidator(members.phone) : null;
 // let countryCodeError = members.countryCode === null ? "Please enter country code" : null;
 // let phoneError = members.phone === "" ? "Please enter phone number" : number.length <= 6 ? "Too short" : number.length > 10 ? "Please enter a valid phone number." : isValidNumber(phoneNumber) ? null : "Please enter a valid phone number.";

  if (companyError || emailError || phoneError ) {
      setErrorDetails({
          ...errorDetails,
          companyName: companyError,
          email: emailError,
          phone: phoneError
      })
      return;
  }
  else {
    getMembersDetails()
    // console.log("Members", members)
  }
}

const onClearFilter = () => {
  setMembersDetails(initialData);
  setMembers({...members, type: "customer", companyName: "", countryCode: 'India (+91)', phone: "", email: ""})
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}


  return ( 
    
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
            <Box>
              <Box className="text-right" px={2} pt={2}>
                <Button className="mx-3" variant="contained" color="primary" onClick={toggleSearch}>Advanced Search</Button>
                <Link to={'/add-members?'+ "00"}><Button  variant="contained" color="secondary">Add Member</Button></Link>
              </Box>
              {searchBox ? (
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="mt-3"><h5>Search By</h5></Grid>
                <Grid item xs={12} md={3} >
                    <label className="text-xs">Company Name </label>
                    <TextField
                        className="form-control mt-1"
                        id="outlined-basic"
                        placeholder="Company Name"
                        variant="outlined"
                        name="companyName"
                        value={members.companyName}
                        onChange={handleInputChange}
                        error={errorDetails.companyName !== null ? true : false}
                        helperText={errorDetails.companyName !== null ? errorDetails.companyName : null}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <label className="text-xs">Phone Number </label>
                    <TextField
                        className="form-control mt-1"
                        type="number"
                        id="outlined-basic"
                        placeholder="Phone Number"
                        variant="outlined"
                        name="phone"
                        value={members.phone}
                        onChange={handleInputChange}
                        error={errorDetails.phone !== null ? true : false}
                        helperText={errorDetails.phone !== null ? errorDetails.phone : null}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <label className="text-xs">Email ID </label>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="email"
                        size="small" 
                        name="email"
                        className="form-control mt-1"
                        placeholder="Email ID"
                        value={members.email}
                        onChange={handleInputChange}
                        error={errorDetails.email !== null ? true : false}
                        helperText={errorDetails.email !== null ? errorDetails.email : null}
                        />
                </Grid>
                <Grid item xs={12} md={3} >
                    <label className="text-xs">Type</label>
                    <FormControl fullWidth>
                        <Select
                            className=" mt-1"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="type"
                            value={members.type}
                            onChange={handleInputChange}
                        >
                            <MenuItem value={"customer"}>Customer</MenuItem>
                            <MenuItem value={"vendor"}>Vendor</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} md={3} className='mt-4'>
                  <Button  variant="contained" color="secondary" size="small" onClick={() => checkValidation()}>Search</Button>
                  <Button  variant="contained" color="primary" size="small" onClick={()=> onClearFilter()} className="clear-btn">Clear Filter</Button>
                </Grid>
              </Grid>
              ): " "}
            </Box>
            <Box pt={6} pb={3} className="order-table">
                
              <Grid container spacing={6}>
                <Grid item xs={12}>
                
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Members
                      </MDTypography>
                    </MDBox>
                    {(!isLoading && membersDetails.length > 0) ?
                    <Box>
                    <DataTable
                     entriesPerPage={false}
                     pagination={false}
                     showTotalEntries={false}
                          table={{
                              columns: [
                              { Header: "Type", accessor: "member_type"},
                              { Header: "Name", accessor: "name"},
                              { Header: "Company Name", accessor: "company_name"},
                              { Header: "Email ID", accessor: "email_id"},
                              { Header: "Phone No", accessor: "phone_no"},
                              { Header: "action", accessor: "action", align: "center" },
                              ],
                              rows: 
                              membersDetails.map(item => (
                                {
                                    member_type: item.member_type,
                                    name: item.title + " " + item.first_name + " " + item.last_name,
                                    company_name: item.company_name,
                                    email_id: item.email,
                                    phone_no: item.country_code + item.phone,
                                    action:(
                                        <Link to={'/add-members?'+ item.origin}>
                                        <Tooltip title="Edit" arrow>
                                            <Button size="small" variant="contained" color="primary">
                                                <ModeOutlinedIcon />
                                            </Button>
                                        </Tooltip>
                                    </Link>
                                  ),
                                } 
                              ))
                          }}
                          />
                      
                    {totalRecords > 1 && (
                      <Box className="py-3 mb-3" >
                        <Stack spacing={2} alignItems="end">
                          <Pagination className="pagination" page={currentPage} count={totalRecords} onChange={(e, index) => getMembersDetails(index)} color="primary" />
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  : !isLoading && membersDetails.length === 0 ? 
                  (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                  <h5>{errorMsg ? errorMsg : "No members found..."}</h5>
                  </Stack>)
                  :
                  <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="success" />
                  </Stack>
                  }
                </Card>
                
              </Grid>
              
              </Grid>
            </Box>
          </Box>
        </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Members;
