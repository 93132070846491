import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Grid, Box, Card, LinearProgress, Menu, FormControl, TextField, MenuItem, Button, Autocomplete } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

// Data
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import Modal from 'react-bootstrap/Modal';

function TicketCategory() {
  const resultPerPage = 20;
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBox, setSearchBox] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [matchList, setMatchList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [selectedRef, setSelectedRef] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [orders, setOrders] = useState({
    stadium: "", city: "", category: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    stadium: null, city: null, category: null
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [show, setShow] = useState(false);

  const handleCloseModal = () => {
      setShow(false); 
      setIsUpdate(false);
      setSelectedOrigin(null);
      setOrders({stadium: "", city: "", category: ""});
    };
  const handleShowModal = () => setShow(true);

  const handleClick = (event, order_ref, invoice_no) => {
    setAnchorEl(event.currentTarget);
    setSelectedRef(order_ref);
    setInvoiceNumber(invoice_no);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setSearchBox(!searchBox)
  }

  const handleInputChange = (e) => {
    setOrders({ ...orders, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  useEffect(() => {
    getOrdersDetails();
    getMatchList();
    getTicketCategoryList();
  }, []);
  //console.log("ordersDetails",ordersDetails)
  //function to get brand details
  const getOrdersDetails = async (pageNo, origin) => {
    setIsLoading(true);
    let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
    let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
    let stadium = orders.stadium;
    let city = orders.city;
    let category_name = orders.category;
    let eachOrigin = origin ? origin : "";

    const response = await API.get(`inventory/ticket_category_list/${pageCount}?stadium=${stadium}&city=${city}&category_name=${category_name}&origin=${eachOrigin}`)
    // console.log("Response",response.data)
    if (response?.data?.status && response?.data?.data?.data) {
      let total_records = response.data.data.total_records;
      setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
      setCurrentPage(activePageIndex);
      setOrdersDetails(response.data.data.data);
      if (isInitial) {
        setInitialData(response.data.data.data);
        setIsInitial(false);
      }
      setIsLoading(false);
      setErrorMsg("")
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
      setErrorMsg(response.data.message);
    }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  const getSingleOrdersDetails = async (pageNo, origin) => {
    setIsLoading(true);
    let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
    let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
    let stadium = "";
    let city = "";
    let category_name = "";

    const response = await API.get(`inventory/ticket_category_list/${0}?stadium=${stadium}&city=${city}&category_name=${category_name}&origin=${origin}`)
    if (response?.data?.status && response?.data?.data?.data) {
      console.log("Response",response.data)
      let item = response.data.data.data[0];
      setOrders({
        ...orders,
        stadium: item.stadium,
        city: item.city,
        category: item.category_name
      })
      setIsLoading(false);
    }
    else if (response?.data?.message === 'No orders found') {
      setIsLoading(false);
      setErrorMsg(response.data.message);
    }
    else {
      setIsLoading(false);
      setCurrentPage(1);
      // setErrorMsg(response.data.message)
      alert("Something went wrong! Please try again");
      // window.location.reload();
    }
  }

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

  const getMatchList = async () => {
    setIsLoading(true);
    const response = await API.get('inventory/get_match_list');
    if (response.data.status) {
      // let data = response.data.data.filter(x=> formatDate1(formatDate(x.match_date)) === true);
      setMatchList(response.data.data);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  const getTicketCategoryList = async () => {
    setIsLoading(true);
    const response = await API.get('inventory/get_ticket_category_list');
    if (response.data.status) {
      setTicketCategoryList(response.data.data);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

const handleEachTicketCategory = (item) => {
  getSingleOrdersDetails(0, item.origin);
  setSelectedOrigin(item.origin);
  handleShowModal();
  setIsUpdate(true);
}

  const checkValidation = () => {

    let stadiumErr = orders.stadium === "" ? "Please select stadium" : null;
    let cityErr = orders.city === "" ? "Please select city" : null;
    let categoryErr = orders.category === "" ? "Please select category" : null;
   // let emailErr = emailValidator(email);
    if (stadiumErr || cityErr || categoryErr) {
        setErrorDetails({...errorDetails,
                          stadium: stadiumErr,
                          city: cityErr,
                          category: categoryErr});
        return;
    }
    else {
      if(isUpdate){
        onUpdateTicketCategory();
      }
      else{
        onCreateTicketCategory();
      } 
    }
  }

  const onCreateTicketCategory = () => {
    let dataToSend = {
      "stadium": orders.stadium,
      "city": orders.city,
      "category_name": orders.category
    }
    console.log("dataToSend",dataToSend)
    setIsLoading(true);
    API.post('inventory/create_ticket_category', dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                window.location.reload();
                setIsLoading(false);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
  }

  const onUpdateTicketCategory = () => {
    let dataToSend = {
      "stadium": orders.stadium,
      "city": orders.city,
      "category_name": orders.category
    }
    console.log("dataToSend",dataToSend);
    console.log("selectedOrigin",selectedOrigin)
    setIsLoading(true);
    API.post(`inventory/update_ticket_category/${selectedOrigin}`, dataToSend)
        .then(resp => {
            if (resp.data.status) {
                alert(`${resp.data.message}`);
                window.location.reload();
                setIsLoading(false);
                setIsUpdate(false);
                setSelectedOrigin(null);
            }
            else {
                alert("Something went wrong. Please try again later.");
                setIsLoading(false);
            }
        })
        .catch(error => {
            alert("Something went wrong. Please try again later.");
            setIsLoading(false);
        })
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
          <Box>
            <Box className="text-right" px={2}>
              <Button variant="contained" color="primary" onClick={toggleSearch}>Advanced Search</Button>&nbsp; 
              <Button  variant="contained" color="secondary" onClick={handleShowModal}>Add Ticket Category</Button>
            </Box>
            {searchBox ? (
              <Grid container spacing={2} className="filter-search-box">
                <Grid item xs={12} md={12} className="mt-3"><h5>Search By</h5></Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Stadium </label>
                  <Autocomplete
                    className="inputFieldLocation"
                    size="small"
                    id="outlined-basic"
                    freeSolo
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    options={matchList}
                   // value={orders.matches}
                    getOptionLabel={(option) => option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                    renderOption={(props, option) => (
                      <li {...props} key={option.origin}>
                        {option.teams + ", " + formatDate(option.match_date) + ", " + option.venue}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        id="outlined-basic"
                        {...params}
                        variant="outlined"
                        size="small"
                        className="form-control mt-1"
                        placeholder="Matches"
                      />
                    )}
                    onChange={(event, newValue) => {
                       setOrders({...orders, matches: newValue.origin});
                     // getTicketCategoryList1(newValue.venue, index, newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">City </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="City"
                    variant="outlined"
                    name="city"
                    value={orders.orderRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} >
                  <label className="text-xs">Category </label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Category"
                    variant="outlined"
                    name="category"
                    value={orders.orderRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} className='filter-search'>
                  <Button variant="contained" color="primary" onClick={() => getOrdersDetails()}>Search</Button>
                  <Button variant="contained" color="secondary" onClick={() => setOrdersDetails(initialData)} className="clear-btn">Clear Filter</Button>
                </Grid>
              </Grid>
            ) : " "}
          </Box>
          <Box pt={6} pb={3} className="order-table">

            <Grid container spacing={6}>
              <Grid item xs={12}>

                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Ticket Category
                    </MDTypography>
                  </MDBox>
                  {(!isLoading && ordersDetails.length > 0) ?
                    <Box>
                      <DataTable
                        entriesPerPage={false}
                        pagination={false}
                        showTotalEntries={false}
                        table={{
                          columns: [
                            { Header: "action", accessor: "action", align: "center" },
                            { Header: "Stadium", accessor: "stadium" },
                            { Header: "City", accessor: "city" },
                            { Header: "Category", accessor: "category" },
                          ],
                          rows:
                            ordersDetails.map(item => (
                              {

                                action: (
                                  <>
                                    <Button
                                      className="text-primary"
                                      id="demo-positioned-button"
                                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                      onClick={()=> handleEachTicketCategory(item)}
                                    >
                                      <MoreVertIcon />
                                    </Button>
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <Link to={'/view-orders?' + selectedRef}><MenuItem>Edit</MenuItem></Link>
                                    </Menu>
                                  </>
                                  //   <Link to={'/view-orders?'+ item.order_ref_no}>
                                  //     <Tooltip title="Edit" arrow>
                                  //         <Button size="small" variant="contained" color="primary">
                                  //             <ModeOutlinedIcon />
                                  //         </Button>
                                  //     </Tooltip>
                                  // </Link>
                                ),
                                stadium: item.stadium,
                                city: item.city,
                                category: item.category_name,
                                
                              }
                            ))
                        }}
                      />

                      {totalRecords > 1 && (
                        <Box className="py-3 mb-3" >
                          <Stack spacing={2} alignItems="end">
                            <Pagination className="pagination" page={currentPage} count={totalRecords} onChange={(e, index) => getOrdersDetails(index)} color="primary" />
                          </Stack>
                        </Box>
                      )}
                    </Box>
                    : !isLoading && ordersDetails.length === 0 ?
                      (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <h5>{errorMsg ? errorMsg : "No orders found..."}</h5>
                      </Stack>)
                      :
                      <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress color="success" />
                      </Stack>
                  }
                </Card>

              </Grid>

            </Grid>
          </Box>
        </Box>
      </Card>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Ticket Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Grid spacing={1} container>
                <Grid item xs={12} md={6} >
                  <label className="text-xs">Stadium <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Stadium"
                    variant="outlined"
                    name="stadium"
                    value={orders.stadium}
                    onChange={handleInputChange}
                    error={errorDetails.stadium}
                    helperText={errorDetails.stadium}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                  <label className="text-xs">City <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="City"
                    variant="outlined"
                    name="city"
                    value={orders.city}
                    onChange={handleInputChange}
                    error={errorDetails.city}
                    helperText={errorDetails.city}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                  <label className="text-xs">Category <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                  <TextField
                    className="form-control mt-1"
                    id="outlined-basic"
                    placeholder="Category"
                    variant="outlined"
                    name="category"
                    value={orders.category}
                    onChange={handleInputChange}
                    error={errorDetails.category}
                    helperText={errorDetails.category}
                  />
                </Grid>
            </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" color="primary" className="btn-danger mx-3" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="contained" color="secondary"  onClick={checkValidation}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default TicketCategory;
