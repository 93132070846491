import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";

// Dashboard components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import API from 'apiConfig';
import { FormHelperText, LinearProgress, Stack } from '@mui/material';
import { fnameVali, lnameVali } from 'Validation';
import { alphaNumericValidator } from 'Validation';
import { emailValidator } from 'Validation';
import { addrVali } from 'Validation';
import { panVali } from 'Validation';
import { gstJstNumVali } from 'Validation';
import { countrylist } from 'CountryList';
import { useLocation } from 'react-router-dom';

function CreateMember() {
  const location = useLocation();
  let currentUrl = window.location.href.split("?");
  let origin = currentUrl[currentUrl.length - 1]

  const [isLoading, setIsLoading] = useState(false);
  const [isEditStarts, setIsEditStarts] = useState(false);
  const [pageType, setPageType] = useState('add');
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedMatchError, setSelectedMatchError] = useState(null);
  const [forms, setForms] = useState([{ id: 0, name: "", count: "", ticketCategory: "" }]);
  const [formsError, setFormsError] = useState([{ id: 0, name: null, count: null, ticketCategory: null }]);
  const [members, setMembers] = useState({
    type: "customer", title: "Mr", firstName: "", lastName: "", companyName: "", countryCode: 'India (+91)',
    phone: "", email: "", address: "", pan: "", gst: "", panFile: "", gstFile: ""
  });
  const [errorDetails, setErrorDetails] = useState({
    type: null, title: null, firstName: null, lastName: null, companyName: null,
    countryCode: null, phone: null, email: null, address: null, pan: null, gst: null,
    panFile: null, gstFile: null
  });
  useEffect(() => {
    if(origin === "00"){
      setPageType("add")
    }
    else{
      setPageType("update");
      getMemberDetails();
    }
  }, [])

  const getMemberDetails = async (pageNo) => {
    setIsLoading(true);
    try {
        await API.get(`orders/get_members_list/0?origin=${origin}&company_name=&email=&phone=&member_type=`)
          .then((response) => {
            if (response.data.status && response.data.data.data) {
              let data = response.data.data.data[0];
              setMembers({...members, 
                            type: data.member_type, 
                            title: data.title,
                            firstName: data.first_name,
                            lastName: data.last_name, 
                            companyName: data.company_name, 
                            countryCode: data.country_code,
                            phone: data.phone,
                            email: data.email, 
                            address: data.address, 
                            pan: data.pan, 
                            gst: data.gst
                        })
             setIsLoading(false);
          }
          else {
              setIsLoading(false);
              alert("Something went wrong! Please try again");
              window.location.reload();
          }
          })
          .catch((error) => {
            setIsLoading(false);
            alert("Something went wrong! Please try again");
            window.location.reload();
          })
      } catch (error) {
        alert(error && error.response && error.response.data && error.response.data.message)
      }
   }

  useEffect(() => {
    let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
    var script = document.createElement("script");
    script.src = scripts
    script.async = true;
    document.body.appendChild(script);
}, [])

function isValidNumber(number) {
  return new window.libphonenumber.parsePhoneNumber(number).isValid()
}

  const handleInputChange = (e) => {
    setMembers({ ...members, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
    setIsEditStarts(true);
  }

  const checkValidation = () => {
    var number = members.phone;
    var country = members.countryCode;
    var phoneNumber = country + " " + number;

    let firstNameError = members.title === "Messrs" ? null : (members.title === "Messrs" && members.firstName !== "") ? fnameVali(members.firstName) : fnameVali(members.firstName);
    let lastNameError = members.title === "Messrs" ? null : (members.title === "Messrs" && members.lastName !== "") ? lnameVali(members.lastName) :lnameVali(members.lastName);
    let companyError = members.title !== "Messrs" ? null : (members.title !== "Messrs" && members.companyName !== "") ? alphaNumericValidator(members.companyName) : alphaNumericValidator(members.companyName);
   // let emailError = emailValidator(members.email);
   // let addressError = addrVali(members.address);
    let panError = members.pan !== "" ? panVali(members.pan) : null;
    let panFileError = members.pan === "" ? null : (members.pan !== "" && members.panFile === "") ? "Upload Pan file" : null;
    let gstError = members.gst !== "" ? gstJstNumVali(members.gst) : null;
    let gstFileError = members.gst === "" ? null : (members.gst !== "" && members.gstFile === "") ? "Upload GST file" : null;
    let countryCodeError = members.countryCode === null ? "Please enter country code" : null;
    let phoneError = members.phone === "" ? "Please enter phone number" : number.length <= 6 ? "Too short" : number.length > 10 ? "Please enter a valid phone number." : isValidNumber(phoneNumber) ? null : "Please enter a valid phone number.";

    if (firstNameError || lastNameError || companyError || panError || gstError) {
        setErrorDetails({
            ...errorDetails,
            firstName: firstNameError,
            lastName: lastNameError,
            companyName: companyError,
            // email: emailError,
            // address: addressError,
            pan: panError,
            gst: gstError
        })
        return;
    }
    else {
        onCreateMember(members);
      // console.log("Members", members)
    }
}

  const onCreateMember = async (item) => {
    setIsLoading(true);
    let dataToSend = {
      "member_type": item.type,
      "title": item.title,
      "first_name": item.firstName,
      "last_name": item.lastName,
      "company_name": item.companyName,
      "country_code": item.countryCode,
      "phone": item.phone,
      "email": item.email,
      "address": item.address,
      "pan": item.pan,
      "gst": item.gst,
      "pan_file": item.panFile,
      "gst_file": item.gstFile
    }
    // console.log("dataToSend",dataToSend)
    const response = await API.post('orders/create_members', dataToSend);
    if (response.data.status) {
      alert(`${response.data.message}`);
      window.location.reload();
      setIsLoading(false);
    }
    else if (!response.data.status) {
      alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      // console.log("Error")
    }
  }

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
}

  const uploadFile = async (event, type) => {
    const file = event.target.files[0]

    console.log(event.target.files[0]);
    var fileSize = parseFloat(file.size / 1000).toFixed(2);
    console.log("Size", fileSize);
    console.log("file.type", file.type)
    if (fileSize > 2028) {
      if (type === "pan") {
        setErrorDetails({ ...errorDetails, panFile: "Please upload a file smaller than 2mb" });
      }
      else {
        setErrorDetails({ ...errorDetails, gstFile: "Please upload a file smaller than 2mb" });
      }
      return false;
    }

    else if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "application/pdf" && file.type != "image/png") {
      if (type === "pan") {
        setErrorDetails({ ...errorDetails, panFile: "Please select a JPG/JPEG/PNG/PDF file for upload" });
      }
      else {
        setErrorDetails({ ...errorDetails, gstFile: "Please select a JPG/JPEG/PNG/PDF file for upload" });
      }
      return;
    }
    else {
      let img = new Image()
      img.src = window.URL.createObjectURL(file)
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function () {
        let covertedData = btoa(reader.result);
       // console.log("base64", `data:${file.type};base64,${covertedData}`);
        if (type === "pan") {
          setMembers({...members, panFile: `data:${file.type};base64,${covertedData}`});
          setErrorDetails({ ...errorDetails, panFile: null});
        }
        else{
          setMembers({...members, gstFile: `data:${file.type};base64,${covertedData}`});
          setErrorDetails({ ...errorDetails, gstFile: null});
        }
      };
      reader.onerror = function () {
        console.log('there are some problems');
      };
    }
  }

   // console.log("errors",errorDetails)
  return (
    <DashboardLayout>
      {isLoading ?
        (<Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <LinearProgress color="success" />
        </Stack>) : (
          <Box py={3} className="dashboard-container">
            <h5>{pageType === "add" ? "Add" : "Update"} Members</h5>
            {/* <hr></hr> */}
            <Grid container>
              <Grid item xs={12} md={1} className="px-3 mt-3">
                <label>Type</label>
                <span className="text-danger" style={{ fontSize: 15 }}> *</span>
              </Grid>
              <Grid item xs={12} md={3} className="px-3 mt-3">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="type"
                    value={members.type}
                    onChange={handleInputChange}
                  >
                    {/* <MenuItem value={1}>Asia Cup 2022</MenuItem> */}
                    <MenuItem value={"customer"}>Customer</MenuItem>
                    <MenuItem value={"vendor"}>Vendor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12} md={2} className="px-3 mt-3">
                <label className='mb-2'>Title  <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="title"
                    value={members.title}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Messrs"}>Messrs</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4} className="px-3 mt-3">
                <label className='mb-2'>First Name {members.title !== "Messrs" && (<span className="text-danger" style={{ fontSize: 15 }}> *</span>)}</label>
                <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined" 
                      size="small" 
                      name="firstName"
                      value={members.firstName}
                      className="inputboxremark"
                      placeholder="First Name"
                      onChange={handleInputChange}
                      error={errorDetails.firstName !== null ? true : false}
                      helperText={errorDetails.firstName !== null ? errorDetails.firstName : null}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className="px-3 mt-3">
                <label className='mb-2'>Last Name  {members.title !== "Messrs" && (<span className="text-danger" style={{ fontSize: 15 }}> *</span>)}</label>
                <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined" 
                      size="small" 
                      name="lastName"
                      className="inputboxremark"
                      placeholder="Last Name"
                      value={members.lastName}
                      onChange={handleInputChange}
                      error={errorDetails.lastName !== null ? true : false}
                      helperText={errorDetails.lastName !== null ? errorDetails.lastName : null}
                      />
                  </FormControl>
              </Grid>

              <Grid item xs={12} md={5} className="px-3 mt-3">
                <label className='mb-2'>Company Name  {members.title === "Messrs" && (<span className="text-danger" style={{ fontSize: 15 }}> *</span>)}</label>
                <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined" 
                      size="small" 
                      name="companyName"
                      className="inputboxremark"
                      placeholder="Company Name"
                      value={members.companyName}
                      onChange={handleInputChange}
                      error={errorDetails.companyName !== null ? true : false}
                      helperText={errorDetails.companyName !== null ? errorDetails.companyName : null}
                      />
                  </FormControl>
              </Grid>
            </Grid>

            <Grid container>
                
                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>Phone Number  <span className="text-danger" style={{ fontSize: 15 }}> *</span></label>
                    <div fullWidth className='d-flex'>
                    <Select
                        className='country-code-select'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="countryCode"
                        value={members.countryCode}
                        onChange={handleInputChange}
                        error={errorDetails.countryCode !== null ? true : false}
                        helperText={errorDetails.countryCode !== null ? errorDetails.countryCode : null}
                    >
                         <MenuItem value={"India (+91)"}>India (+91)</MenuItem>
                                                    {countrylist.map(option => (
                                                        <MenuItem key={option.origin} value={option.country_code}>
                                                            {option.name + " (" + option.country_code + ")"}
                                                        </MenuItem>
                                                    ))}
                    </Select>
                    {errorDetails.countryCode !== null && (<FormHelperText style={{ color: "red" }}>{errorDetails.countryCode}</FormHelperText>)}
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="number"
                        size="small" 
                        name="phone"
                        className="inputboxremark"
                        placeholder="Phone Number"
                        value={members.phone}
                        onChange={handleInputChange}
                        error={errorDetails.phone !== null ? true : false}
                        helperText={errorDetails.phone !== null ? errorDetails.phone : null}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>Email ID  </label>
                    <FormControl fullWidth>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="email"
                        size="small" 
                        name="email"
                        className="inputboxremark"
                        placeholder="Email ID"
                        value={members.email}
                        onChange={handleInputChange}
                        error={errorDetails.email !== null ? true : false}
                        helperText={errorDetails.email !== null ? errorDetails.email : null}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container>
                 <Grid item xs={12} md={10} className="px-3 mt-3">
                    <label className='mb-2'>Address </label>
                    <FormControl fullWidth>
                    <textarea
                        id="outlined-basic"
                        variant="outlined" 
                        type="number"
                        size="small" 
                        name="address"
                        className="inputboxremark"
                        placeholder="Type here..."
                        value={members.address}
                        onChange={handleInputChange}
                        error={errorDetails.address !== null ? true : false}
                        helperText={errorDetails.address !== null ? errorDetails.address : null}
                        style={{borderColor: errorDetails.address !== null ? "red" : "#d2d6da"}}
                        />
                    </FormControl>
                    {errorDetails.address !== null && (<FormHelperText style={{ color: "red" }}>{errorDetails.address}</FormHelperText>)}
                </Grid>
                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>PAN Card Number</label>
                    <FormControl fullWidth>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="text"
                        size="small" 
                        name="pan"
                        className="inputboxremark"
                        placeholder="PAN card number"
                        value={members.pan}
                        onChange={handleInputChange}
                        error={errorDetails.pan !== null ? true : false}
                        helperText={errorDetails.pan !== null ? errorDetails.pan : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>Upload PAN Card  {members.pan !== "" && (<span className="text-danger" style={{ fontSize: 15 }}> *</span>)}</label>
                    <FormControl fullWidth>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="file"
                        size="small" 
                        name="pan"
                        className="inputboxremark"
                        placeholder="PAN card number"
                        // value={members.pan}
                        onChange={(e)=> uploadFile(e, "pan")}
                        error={errorDetails.panFile !== null ? true : false}
                        helperText={errorDetails.panFile !== null ? errorDetails.panFile : null}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>GST Number  </label>
                    <FormControl fullWidth>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="text"
                        size="small" 
                        name="gst"
                        className="inputboxremark"
                        placeholder="GST Number"
                        value={members.gst}
                        onChange={handleInputChange}
                        error={errorDetails.gst !== null ? true : false}
                        helperText={errorDetails.gst !== null ? errorDetails.gst : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} className="px-3 mt-3">
                    <label className='mb-2'>Upload GST Document {members.gst !== "" && (<span className="text-danger" style={{ fontSize: 15 }}> *</span>)} </label>
                    <FormControl fullWidth>
                    <TextField
                        id="outlined-basic"
                        variant="outlined" 
                        type="file"
                        size="small" 
                        name="gst"
                        className="inputboxremark"
                        // value={members.gst}
                         onChange={(e)=> uploadFile(e, "gst")}
                        error={errorDetails.gstFile !== null ? true : false}
                        helperText={errorDetails.gstFile !== null ? errorDetails.gstFile : null}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container >
              <Grid item xs={12} md={12} className="px-3 mt-3">
                <Button variant="contained" color="secondary" onClick={() => checkValidation()}>{pageType === "add" ? "Submit" : "Update"}</Button>
              </Grid>
            </Grid>

          </Box>
        )}
    </DashboardLayout>
  );
}

export default CreateMember;
